import { queryOwnListings, findListingTransaction } from '../ManagementPage/ManagementPage.duck';

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch(queryOwnListings()).then(response => {
    const listings = response.data.data;
    const selectedListingId = params.id || listings[0]?.id?.uuid;

    const currentUser = getState().user.currentUser;

    return dispatch(findListingTransaction(selectedListingId, currentUser?.id?.uuid));
  });
};
