/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    hideFilter: true,
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    hideFilter: true,
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },

  {
    id: 'operator',
    label: 'Owner operator',
    type: 'SelectButtonFilter',
    group: 'primary',
    filterFor: 'professional',
    queryParamNames: ['pub_operator'],
    config: {
      options: [{ key: 'operator', label: 'Owner Operator' }],
    },
  },
  {
    id: 'project-preference',
    label: 'Preference',
    type: 'SelectSingleFilter',
    group: 'secondary',
    filterFor: 'project',
    hideFilter: true,
    queryParamNames: ['pub_preference'],
    config: {
      options: [
        { key: 'public', label: 'Public (seen by all professionals)' },
        { key: 'private', label: 'Private (seen by professionals who are invited only)' },
      ],
    },
  },
  {
    id: 'project-type',
    label: 'Project type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    filterFor: 'project',
    hideFilter: true,
    queryParamNames: ['pub_type'],
    config: {
      options: [
        { key: 'residential-home', label: 'Residential home' },
        { key: 'commercial-facility', label: 'Commercial Facility' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'project-timeframe',
    label: 'Time frame',
    type: 'SelectSingleFilter',
    group: 'secondary',
    filterFor: 'project',
    hideFilter: true,
    queryParamNames: ['pub_timeframe'],
    config: {
      options: [
        { key: 'flexible', label: 'Flexible' },
        { key: '1-3-days', label: 'Within 1-3 days' },
        { key: '1-week', label: 'Within 1 week' },
        { key: '2-3-weeks', label: 'Within 2-3 weeks' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'project-skills',
    label: 'Skill sets',
    type: 'SelectMultipleFilter',
    group: 'primary',
    filterFor: 'mutal',
    queryParamNames: ['pub_skills'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'interior-painting',
          label: 'Interior painting',
        },
        {
          key: 'drywall-repair',
          label: 'Drywall repair',
        },
        {
          key: 'wallpaper-removal',
          label: 'Wallpaper removal',
        },
        {
          key: 'pressure-washing',
          label: 'Pressure washing',
        },

        {
          key: 'exterior-painting',
          label: 'Exterior painting',
        },
        {
          key: 'handyman-work',
          label: 'Handyman work',
        },
      ],
    },
  },
  {
    id: 'project-occupation',
    label: 'Occupation',
    type: 'SelectSingleFilter',
    group: 'secondary',
    filterFor: 'project',
    hideFilter: true,
    queryParamNames: ['pub_occupation'],
    config: {
      options: [
        { key: 'occupied', label: 'Occupied' },
        { key: 'not-occupied', label: 'Empty Space' },
      ],
    },
  },
  {
    id: 'verifications',
    label: 'Verifications',
    type: 'SelectMultipleFilter',
    group: 'primary',
    filterFor: 'professional',
    queryParamNames: ['pub_verifications'],
    config: {
      searchMode: 'has_any',
      options: [
        {
          key: 'insured',
          label: 'Insured',
        },
        {
          key: 'licenced',
          label: 'Licenced',
        },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    filterFor: 'professional',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'community-categories',
    label: 'Categories',
    type: 'SelectSingleFilter',
    group: 'primary',
    hideFilter: true,
    queryParamNames: ['pub_category'],
    config: {
      options: [
        { key: 'faq', label: 'FAQ', color: '#2ecc71' },
        { key: 'announcements', label: 'Announcements', color: '#ffaa00' },
        { key: 'member-spotlight', label: 'Member Spotlight', color: '#AED6F1' },
        { key: 'job-highlights', label: 'Job Highlights', color: '#C39BD3' },
        { key: 'introductions', label: 'Introductions', color: '#AF601A' },
        {
          key: 'project-help',
          label: 'Project Help',
          color: '#B03A2E',
        },
        { key: 'business-help', label: 'Business Help', color: '#808000' },
        {
          key: 'resolving-client-issues',
          label: 'Resolving Client Issues',
          color: '#DE3163',
        },
        { key: 'product-questions', label: 'Product Questions', color: '#40E0D0' },
        { key: 'off-topic-chatter', label: 'Off Topic Chatter', color: '#B2BABB' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    // { key: '-price', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
  transactionOptions: [
    { key: 'not-started', label: 'Not started' },
    { key: 'in-progress', label: 'In progress' },
    { key: 'completed', label: 'Completed' },
    { key: 'all-transactions', label: 'All transactions' },
  ],
};

// Sign up user types
export const userTypes = [
  {
    key: 'client',
    label: 'Client',
    text: 'Find Professionals',
    tooltip: 'Be able to Post Projects',
  },
  {
    key: 'professional',
    label: 'Professional',
    text: 'Find Projects',
    tooltip: 'Be able to Publish Profile and Receive Quotes',
  },
  {
    key: 'mutal',
    label: 'Mutal',
    text: 'Find Pros and Projects',
    tooltip: 'Be able to Post Projects, Publish Profile, and Receive Quotes',
  },
];

// Client custom configs
export const accountTypes = [
  { key: 'speciality-contractor', label: 'Speciality contractor', userType: 'professional' },
  { key: 'general-contractor', label: 'General contractor', userType: 'professional' },
  { key: 'homeowner', label: 'Homeowner', userType: 'client' },
  { key: 'property-manager', label: 'Property manager', userType: 'client' },
  { key: 'realtor', label: 'Realtor', userType: 'client' },
];

// Account category
export const accountCategories = [
  {
    key: 'individual',
    label: 'Individual',
  },
  {
    key: 'company',
    label: 'Company',
  },
];

// Professional user verifications
export const professionalVerifications = [
  { key: 'business-licence', label: 'Business Licence' },
  { key: 'w9-form', label: 'W9 Form' },
  { key: 'certificate-of-insurance', label: 'Certificate of Insurance' },
];

export const staff = [
  { key: 'sales-manager', label: 'Sales manager' },
  { key: 'project-manager', label: 'Project manager' },
  { key: 'crew-leader', label: 'Crew leader' },
  { key: 'lead-painter', label: 'Lead painter' },
];

export const marketplacePlans = {
  client: [
    {
      key: 'basic',
      label: 'Core',
      features: [
        'Find and hire professionals',
        'Engage in community page',
        'In app messaging',
        '2 free project postings per month',
      ],
    },
    {
      key: 'pro',
      label: 'Premium',
      features: [
        'All core features',
        'Public phone # Displayed on projects',
        'Unlimited project postings',
        'Inbox dashboard',
      ],
      price: { monthly: 7500, annual: 72000, saving: 90000 },
      lookupKeys: { monthly: 'scout-month', annual: 'scout-year' },
      highlighted: true,
    },
  ],
  professional: [
    {
      key: 'basic',
      label: 'Core',
      features: [
        'Find and hire professionals',
        'Find work and stay busy',
        'Public profile',
        'Engage in communtiy page',
        'In app messaging',
        '2 free project quotes per month',
        '2 free project postings per month',
      ],
    },
    {
      key: 'pro',
      label: 'Premium',
      features: [
        'All core features',
        'Unlimited quoting on projects',
        'Verification badge',
        'Profile is prioritized in search',
        'Receive Project Bid Requests',
        'Public Phone # displayed on profile',
        'Inbox dashboard',
      ],
      price: { monthly: 12500, annual: 108000, saving: 150000 },
      lookupKeys: { monthly: 'pro-month', annual: 'pro-year' },
      highlighted: true,
    },
  ],
};
