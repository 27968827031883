import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPhoto.module.css';

const IconPhoto = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        stroke="#B7B7B7"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.373 21.214H19.66c.868 0 1.571-.703 1.571-1.571V2.357c0-.868-.703-1.571-1.571-1.571H2.373c-.868 0-1.571.703-1.571 1.571v17.286c0 .868.703 1.571 1.571 1.571Z" />
        <path d="M14.536 10.214a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5ZM14.772 21.214a11.565 11.565 0 0 0-11.629-9.428c-.79-.002-1.58.071-2.357.22" />
        <path d="M21.215 15.573a12.256 12.256 0 0 0-3.93-.644 12.397 12.397 0 0 0-4.918 1.005" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconPhoto.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPhoto.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPhoto;
