import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
  validLink,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  FieldRadioButtonGroup,
  FieldSelect,
  NamedLink,
  IconHelp,
} from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import ReactTooltip from 'react-tooltip';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;
const OTHER_PROJECT_TYPE_KEY = 'other';

const phoneNumberOptions = [
  { key: 'private', label: 'Private' },
  { key: 'public', label: 'Public' },
];

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        values,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        projectTypes,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.title',
      });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const workOrderLinkMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.workOrderLink',
      });
      const workOrderLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.workOrderLinkPlaceholder',
      });
      const workOrderValidLinkMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.workOrderValidLink',
      });

      const purchaseOrderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.purchaseOrder',
      });
      const purchaseOrderPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.purchaseOrderPlaceholder',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const projectTypeLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryLabel',
      });

      const otherProjectTypePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherProjectTypePlaceholder',
      });
      const otherProjectTypeRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherProjectTypeRequiredMessage',
      });

      const specialNotesMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.specialNotes',
      });
      const specialNotesPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.specialNotesPlaceholder',
      });

      // Skils
      const skillsLabel = intl.formatMessage({
        id: 'EditListingFeaturesForm.skillsLabel',
      });
      const skillsRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.skillsRequiredMessage',
      });
      const skillsRequired = requiredFieldArrayCheckbox(skillsRequiredMessage);

      const preferencesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.preferencesLabel',
      });
      const preferencesRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.preferencesRequiredMessage',
      });
      const preferencesRequired = requiredFieldArrayCheckbox(preferencesRequiredMessage);

      const phoneNumberMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.phoneNumber',
      });
      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.phoneNumberPlaceholder',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.phoneNumberRequired',
      });
      const phoneNumberRequired = required(phoneNumberRequiredMessage);

      const occupationMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.occupation' });
      const occupationRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.occupationRequiredMessage',
      });
      const occupationRequired = requiredFieldArrayCheckbox(occupationRequiredMessage);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const filterConfig = config.custom.filters;
      const options = findOptionsForSelectFilter('project-skills', filterConfig);
      const preferences = findOptionsForSelectFilter('project-preference', filterConfig);
      const occupation = findOptionsForSelectFilter('project-occupation', filterConfig);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />
          <FieldTextInput
            id="workOrderLink"
            name="workOrderLink"
            className={css.description}
            label={workOrderLinkMessage}
            placeholder={workOrderLinkPlaceholderMessage}
            validate={validLink(workOrderValidLinkMessage)}
          />
          <FieldTextInput
            id="purchaseOrder"
            name="purchaseOrder"
            className={css.description}
            label={purchaseOrderMessage}
            placeholder={purchaseOrderPlaceholderMessage}
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <CustomCategorySelectFieldMaybe
            id="projectType"
            name="projectType"
            projectTypes={projectTypes}
            intl={intl}
          />
          {values.projectType === OTHER_PROJECT_TYPE_KEY ? (
            <div className={css.otherOptionContainer}>
              <FieldTextInput
                id="otherProjectType"
                name="otherProjectType"
                type="text"
                placeholder={otherProjectTypePlaceholder}
                validate={required(otherProjectTypeRequiredMessage)}
              />
              <span className={css.otherOptionBadge}>{projectTypeLabel}</span>
            </div>
          ) : null}
          <div className={css.phoneNumber}>
            <div className={css.phoneNumberHeader}>
              <label>{phoneNumberMessage}</label>
              <NamedLink className={css.updatePhoneLink} name="ContactDetailsPage">
                <FormattedMessage id="EditListingDescriptionForm.updatePhone" />
              </NamedLink>
            </div>
            <FieldSelect name="phoneNumber" id="phoneNumber" validate={phoneNumberRequired}>
              <option disabled value="">
                {phoneNumberPlaceholder}
              </option>
              {phoneNumberOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>
          </div>

          <div className={css.specialNotes}>
            <label>
              {specialNotesMessage}
              <span
                className={css.tooltip}
                data-for="Tooltip.specialNotes"
                data-tip={intl.formatMessage({ id: 'EditProfessionalDescriptionForm.toolTip' })}
              >
                <IconHelp className={css.tooltipIcon} />
                <ReactTooltip id="Tooltip.specialNotes" className={css.tip} />
              </span>
            </label>
            <FieldTextInput
              id="specialNotes"
              name="specialNotes"
              type="text"
              placeholder={specialNotesPlaceholder}
            />
          </div>

          <FieldRadioButtonGroup
            className={css.preferences}
            textClassName={css.preferencesTextClass}
            id="occupation"
            name="occupation"
            label={occupationMessage}
            options={occupation}
            validate={occupationRequired}
          />
          <FieldRadioButtonGroup
            className={css.preferences}
            textClassName={css.preferencesTextClass}
            id="preference"
            name="preference"
            label={preferencesLabel}
            options={preferences}
            validate={preferencesRequired}
          />
          <FieldCheckboxGroup
            className={css.features}
            textClassName={css.featuresTextClass}
            id="skills"
            name="skills"
            label={skillsLabel}
            options={options}
            validate={skillsRequired}
            twoColumns={true}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  projectTypes: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
