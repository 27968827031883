import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing, ensureCurrentUser, isUserCompany } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ProfileLink } from '..';
import { EditProfessionalDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditProfessionalDescriptionPanel.module.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

const EditProfessionalDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    currentUser,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    errors,
    tab,
    updateInProgress,
    // Upload profile image props
    onProfileImageUpload,
    profileImage: image,
    uploadProfileImageError,
    uploadProfileImageInProgress: uploadInProgress,
    updateProfileImageInProgress: updateImageInProgress,
    updateProfileImageError: updateProfileError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const user = ensureCurrentUser(currentUser);
  const isCompanyUser = isUserCompany(currentUser);
  const { description, publicData } = currentListing.attributes;

  const firstName = isCompanyUser
    ? publicData?.firstName
    : publicData?.firstName || user?.attributes?.profile?.firstName;
  const lastName = isCompanyUser
    ? publicData?.lastName
    : publicData?.lastName || user?.attributes?.profile?.lastName;
  const companyName = publicData?.companyName || user?.attributes?.profile?.publicData?.companyName;

  const profileImageId = user.profileImage ? user.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditProfessionalDescriptionPanel.title"
      values={{
        yourProfileLink: (
          <ProfileLink listing={listing} tab={tab}>
            <FormattedMessage id="EditProfessionalDescriptionPanel.yourProfile" />
          </ProfileLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditProfessionalDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditProfessionalDescriptionForm
        className={css.form}
        initialValues={{
          title: `${firstName} ${lastName}`,
          firstName,
          lastName,
          companyName,
          description,
          profileImage: user.profileImage,
          operator: publicData?.operator ? [publicData?.operator] : null,
          contractorLicence: publicData?.contractorLicence,
          crewMembers: publicData?.crewMembers,
          phoneNumber: publicData?.phoneNumber,
          website: publicData?.website,
          staff: publicData?.staff,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            firstName: fname,
            lastName: lname,
            description,
            operator,
            contractorLicence,
            crewMembers,
            phoneNumber,
            website,
            companyName,
            staff,
          } = values;

          let firstName = '';
          let lastName = '';

          if (isCompanyUser) {
            const nameParts = companyName.split(' ');

            if (nameParts.length === 1) {
              firstName = lastName = nameParts[0];
            } else if (nameParts.length === 2) {
              [firstName, lastName] = nameParts;
            } else {
              firstName = nameParts[0];
              lastName = nameParts[nameParts.length - 1];
            }
          } else {
            firstName = fname.trim();
            lastName = lname.trim();
          }

          const title = isCompanyUser ? companyName : `${firstName} ${lastName}`;
          const priceMaybe = !currentListing.attributes.price
            ? {
                price: {
                  amount: 1000,
                  currency: config.currency,
                },
              }
            : {};

          const uploadedImage = image;
          const operatorMaybe = operator ? { operator: operator[0] } : {};

          const updateValues = {
            title: title.trim(),
            description,
            ...priceMaybe,
            publicData: {
              type: config.listingTypeProfessional,
              firstName: isCompanyUser ? fname.trim() : firstName,
              lastName: isCompanyUser ? lname.trim() : lastName,
              contractorLicence,
              crewMembers,
              phoneNumber,
              website,
              staff,
              ...operatorMaybe,
              userData:
                uploadedImage && uploadedImage.imageId && uploadedImage.file
                  ? {
                      firstName,
                      lastName,
                      companyName,
                      profileImageId: uploadedImage.imageId,
                    }
                  : {
                      firstName,
                      lastName,
                      companyName,
                    },
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        fetchErrors={errors}
        currentUser={currentUser}
        onImageUpload={e => onImageUploadHandler(e, onProfileImageUpload)}
        image={image}
        profileImage={profileImage}
        uploadInProgress={uploadInProgress}
        uploadImageError={uploadProfileImageError}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        updateImageInProgress={updateImageInProgress}
      />
    </div>
  );
};

EditProfessionalDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditProfessionalDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditProfessionalDescriptionPanel;
