import React from 'react';
import { string } from 'prop-types';

const IconAchievement = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
    >
      <path
        d="M11.777 16.286a7.714 7.714 0 1 0 0-15.429 7.714 7.714 0 0 0 0 15.429Z"
        stroke="#f15b26"
        strokeWidth={1.714}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.777 12a3.429 3.429 0 1 0 0-6.857 3.429 3.429 0 0 0 0 6.857ZM10.286 16.149 8.777 22.49a.874.874 0 0 1-.446.566.927.927 0 0 1-.737 0l-5.691-2.486a.873.873 0 0 1-.309-1.337L6 13.714M13.714 16.063l1.543 6.428a.858.858 0 0 0 .463.566.875.875 0 0 0 .72 0l5.657-2.486a.856.856 0 0 0 .48-.6.823.823 0 0 0-.171-.737L17.81 13.39"
        stroke="#f15b26"
        strokeWidth={1.714}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconAchievement.defaultProps = {
  className: null,
};

IconAchievement.propTypes = {
  className: string,
};

export default IconAchievement;
