import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { required } from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCurrencyInput,
  FieldDropzone,
  FieldSelect,
  InlineTextButton,
  IconBin,
  SecondaryButton,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import config from '../../config';

import css from './CostsForm.module.css';

const costTypes = [
  { key: 'labor', label: 'Labor' },
  { key: 'Material', label: 'Material' },
  { key: 'equipment', label: 'Equipment' },
  { key: 'subcontractor', label: 'Subcontractor' },
  { key: 'bid', label: 'Bid' },
  { key: 'allowance', label: 'Allowance' },
  { key: 'other', label: 'Other' },
];

const CostsFormComponent = props => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (props?.initialValues?.attachments?.length > 0) {
      setFiles(props.initialValues.attachments);
    } else {
      setFiles([]);
    }
  }, [props.initialValues]);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          form,
          handleSubmit,
          onRemove,
          invalid,
          updateInProgress,
          selectedCostId,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              // As handleSubmit is asynchronous function
              // we can await until it finishes and clear
              // the uplaoded files
              await handleSubmit(values, form);
              setFiles([]);
              form.reset();
            }}
          >
            <FieldTextInput
              className={css.field}
              type="text"
              id="title"
              name="title"
              label="Title"
              placeholder="Add your cost title..."
              validate={required('Title is required')}
            />

            <FieldTextInput
              className={css.field}
              type="textarea"
              id="description"
              name="description"
              label="Description"
              placeholder="Add your cost description..."
            />

            <FieldTextInput
              className={css.field}
              type="date"
              id="date"
              name="date"
              label="Date"
              placeholder="Add your cost date..."
              validate={required('Date is required')}
            />

            <FieldDropzone
              className={css.field}
              id="attachments"
              name="attachments"
              label="Receipts"
              disabled={updateInProgress}
              accept={{
                ['image/*']: '',
                ['application/pdf']: '',
                ['application/msword']: '',
              }}
              files={files}
              setFiles={setFiles}
              isFiles
            />

            <div className={css.field}>
              <label className={css.billsLabel}>
                <FormattedMessage id="CostsForm.bills" />
              </label>
              <table className={css.billsTable}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="CostsForm.rowTitle" />
                    </th>
                    <th>
                      <FormattedMessage id="CostsForm.rowDescription" />
                    </th>
                    <th>
                      <FormattedMessage id="CostsForm.rowCostType" />
                    </th>
                    <th>
                      <FormattedMessage id="CostsForm.rowUnitPrice" />
                    </th>
                    <th>
                      <FormattedMessage id="CostsForm.rowQuantity" />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray id="bills" name="bills">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        return (
                          <tr key={`${name}.${index}`} className={css.trInputs}>
                            <td>
                              <FieldTextInput
                                type="text"
                                id={`bills.${index}.title`}
                                name={`bills.${index}.title`}
                                placeholder="Bill title"
                              />
                            </td>
                            <td>
                              <FieldTextInput
                                type="text"
                                id={`bills.${index}.description`}
                                name={`bills.${index}.description`}
                                placeholder="Bill description"
                              />
                            </td>
                            <td>
                              <FieldSelect
                                id={`bills.${index}.costType`}
                                name={`bills.${index}.costType`}
                              >
                                {costTypes.map(c => {
                                  return (
                                    <option key={c.key} value={c.key}>
                                      {c.label}
                                    </option>
                                  );
                                })}
                              </FieldSelect>
                            </td>
                            <td>
                              <FieldCurrencyInput
                                id={`bills.${index}.unitPrice`}
                                name={`bills.${index}.unitPrice`}
                                placeholder="$0.00"
                                currencyConfig={config.currencyConfig}
                              />
                            </td>
                            <td>
                              <FieldTextInput
                                type="number"
                                id={`bills.${index}.quantity`}
                                name={`bills.${index}.quantity`}
                                placeholder="1"
                              />
                            </td>
                            <td>
                              <InlineTextButton
                                className={css.deleteButton}
                                type="button"
                                onClick={() => {
                                  if (
                                    typeof window !== 'undefined' &&
                                    window.confirm('Are you sure you want to delete this bill?')
                                  ) {
                                    fields.remove(index);
                                  }
                                }}
                              >
                                <IconBin />
                              </InlineTextButton>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </FieldArray>
                </tbody>
              </table>
              <InlineTextButton
                className={css.addBill}
                type="button"
                onClick={() => form.mutators.push('bills', undefined)}
              >
                <FormattedMessage id="CostsForm.addBill" />
              </InlineTextButton>
            </div>

            {selectedCostId ? (
              <div className={css.submitButtons}>
                <SecondaryButton
                  className={css.removeButton}
                  type="button"
                  onClick={() => {
                    if (
                      typeof window !== 'undefined' &&
                      window.confirm('Are you sure you want to delete this cost?')
                    ) {
                      onRemove(selectedCostId);
                    }
                  }}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="CostsForm.deleteCost" />
                </SecondaryButton>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="CostsForm.submit" />
                </PrimaryButton>
              </div>
            ) : (
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="CostsForm.submit" />
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

CostsFormComponent.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { func, string } = PropTypes;

CostsFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const CostsForm = compose(injectIntl)(CostsFormComponent);
CostsForm.displayName = 'CostsForm';

export default CostsForm;
