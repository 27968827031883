import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionProfessionalFeatures.module.css';

const featureCard = (titleMsgId, textMsgId) => {
  return (
    <div className={css.card}>
      <h2 className={css.cardTitle}>
        <FormattedMessage id={titleMsgId} />
      </h2>
      <p className={css.cardText}>
        <FormattedMessage id={textMsgId} />
      </p>
    </div>
  );
};

const SectionProfessionalFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.upperContent}>
          <div className={css.subTitle}>
            <FormattedMessage id="SectionProfessionalFeatures.subTitle" />
          </div>
        </div>
        <div className={css.bottomContent}>
          <div className={css.title}>
            <FormattedMessage id="SectionProfessionalFeatures.title" />
          </div>
          <div className={css.cards}>
            {featureCard(
              'SectionProfessionalFeatures.createProfileTitle',
              'SectionProfessionalFeatures.createProfileText'
            )}
            {featureCard(
              'SectionProfessionalFeatures.getNoticedTitle',
              'SectionProfessionalFeatures.getNoticedText'
            )}
            {featureCard(
              'SectionProfessionalFeatures.getJobsTitle',
              'SectionProfessionalFeatures.getJobsText'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionProfessionalFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionProfessionalFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionProfessionalFeatures;
