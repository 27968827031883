/**
 * This component will show the booking info and calculated total price.
 */
import React from 'react';
import { oneOf, string, object } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import ItemQuotePrice from './ItemQuotePrice';
import ItemSubtotal from './ItemSubtotal';
import ItemCustomerCommissionMaybe from './ItemCustomerCommissionMaybe';
import ItemProviderCommissionMaybe from './ItemProviderCommissionMaybe';
import ItemTotalPrice from './ItemTotalPrice';
import LineItemBookingPeriod from './LineItemBookingPeriod';

import css from './EnquiryBreakdown.module.css';

export const EnquiryBreakdownComponent = props => {
  const { rootClassName, className, userRole, transactionQuote, bookingDates, intl } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const classes = classNames(rootClassName || css.root, className);

  /**
   * EnquiryBreakdown contains different items:
   *
   * ItemQuotePrice: prints customer quoted price to both parties.
   *
   * ItemSubtotal: shows the subtotal of the transaction.
   * Calculation is quoted price +- commission
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   *
   * ItemCustomerCommissionMaybe: prints the amount of customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * ItemTotalPrice: prints total price of the transaction
   *
   */

  const showBreakdownItems = false;

  return (
    <div className={classes}>
      {bookingDates ? <LineItemBookingPeriod booking={bookingDates} /> : null}
      <ItemQuotePrice intl={intl} transactionQuote={transactionQuote} />

      {showBreakdownItems ? <ItemSubtotal intl={intl} transactionQuote={transactionQuote} /> : null}

      {showBreakdownItems ? (
        <ItemCustomerCommissionMaybe
          isCustomer={isCustomer}
          transactionQuote={transactionQuote}
          intl={intl}
        />
      ) : null}
      {showBreakdownItems ? (
        <ItemProviderCommissionMaybe
          isProvider={isProvider}
          transactionQuote={transactionQuote}
          intl={intl}
        />
      ) : null}

      {showBreakdownItems ? (
        <ItemTotalPrice isProvider={isProvider} transactionQuote={transactionQuote} intl={intl} />
      ) : null}

      <span className={css.feeInfo}>
        <FormattedMessage id="EnquiryBreakdown.commissionFeeNote" />
      </span>
    </div>
  );
};

EnquiryBreakdownComponent.defaultProps = { rootClassName: null, className: null };

EnquiryBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transactionQuote: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryBreakdown = injectIntl(EnquiryBreakdownComponent);

EnquiryBreakdown.displayName = 'EnquiryBreakdown';

export default EnquiryBreakdown;
