import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, AvatarMedium } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';
import moment from 'moment';

import ConversationActionButton from './ConversationActionButton';
import RemoveInvitationButton from './RemoveInvitationButton';
import DateIcon from './DateIcon';
import css from './Invitation.module.css';

const Invitation = props => {
  const {
    className,
    rootClassName,
    currentUser,
    invitation,
    onRemoveInvitation,
    removeInvitationId,
    removeInvitationInProgress,
    removeInvitationError,
    onStartConversation,
    startConversationInProgress,
    onUpdateInvitation,
    updateInvitationInProgress,
    history,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const routes = routeConfiguration();

  const invitationId = invitation.id.uuid;
  const isCurrentlyRemoving = removeInvitationId === invitationId;
  const invitationDate = currentUser.attributes.profile.publicData.invitations?.find(
    i => i.id === invitationId
  )?.date;
  const formattedInvitationDate = moment(invitationDate, 'DD-MM-YYYY').format('MMM DD, YYYY');
  const invitationTxId = currentUser.attributes.profile.publicData.invitations?.find(
    i => i.id === invitationId
  )?.txId;

  const handleRemoveInvitation = () => {
    if (isCurrentlyRemoving || removeInvitationInProgress) {
      return null;
    } else {
      onRemoveInvitation(invitationId);
    }
  };

  const listingTitle = (
    <NamedLink
      className={css.listingLink}
      name="ListingPage"
      params={{
        id: invitation.id.uuid,
        slug: createSlug(invitation.attributes.title),
      }}
    >
      {invitation.attributes.title}
    </NamedLink>
  );
  const authorName = (
    <span className={css.highlighted}>{invitation.author.attributes.profile.displayName}</span>
  );
  return (
    <div className={classes}>
      <div className={css.avatarContainer}>
        <AvatarMedium className={css.avatar} user={invitation.author} />
      </div>
      <div className={css.invitationContent}>
        <p className={css.invitationMessage}>
          <FormattedMessage
            id="Invitation.invitationMessage"
            values={{ listingTitle, authorName }}
          />
        </p>
        <div className={css.invitationBottom}>
          <div>
            <ConversationActionButton
              onStartConversation={() => {
                onStartConversation(invitationId)
                  .then(async txId => {
                    await onUpdateInvitation(txId.uuid).then(() => {
                      // Redirect to OrderDetailsPage
                      history.push(
                        createResourceLocatorString(
                          'OrderDetailsPage',
                          routes,
                          { id: txId.uuid },
                          {}
                        )
                      );
                    });
                  })
                  .catch(err => {
                    // Do noting as we handle
                    // error with redux
                    console.log(err);
                  });
              }}
              startConversationInProgress={
                startConversationInProgress || updateInvitationInProgress
              }
              invitationTxId={invitationTxId}
            />
          </div>
          <div className={css.invitationInfo}>
            <span className={css.invitationDate}>
              <DateIcon />
              {formattedInvitationDate}
            </span>
            <RemoveInvitationButton
              id={invitationId}
              onClick={handleRemoveInvitation}
              isCurrentlyRemoving={isCurrentlyRemoving}
              removeInvitationInProgress={removeInvitationInProgress}
              removeInvitationError={removeInvitationError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const { string, object, func, bool } = PropTypes;

Invitation.defaultProps = {
  className: null,
  rootClassName: null,

  onRemoveInvitation: null,
  removeInvitationId: null,
  removeInvitationInProgress: false,
  removeInvitationError: null,
  onStartConversation: null,
  startConversationInProgress: false,
};

Invitation.propTypes = {
  className: string,
  rootClassName: string,
  invitation: object.isRequired,

  onRemoveInvitation: func.isRequired,
  removeInvitationId: string,
  removeInvitationInProgress: bool.isRequired,
  removeInvitationError: propTypes.error,
  onStartConversation: func.isRequired,
  startConversationInProgress: bool.isRequired,
};

export default Invitation;
