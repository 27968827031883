import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { AvatarMedium, ReviewRating, UserDisplayName, IconReviewStar } from '../../components';
import { averageProfessionalReview } from '../../util/data';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const Review = props => {
  const { review, additionalAttributes, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  const reviewRating = additionalAttributes
    ? Number(averageProfessionalReview(additionalAttributes))
    : review.attributes.rating;
  return (
    <div className={css.review}>
      <AvatarMedium className={css.avatar} user={review.author} />
      <div>
        <span className={css.displayName}>
          <UserDisplayName user={review.author} intl={intl} />
        </span>
        <div className={css.reviewWrapper}>
          {additionalAttributes ? (
            <>
              <div className={css.reviewAttributes}>
                {Object.keys(additionalAttributes).map((a, index) => {
                  const attributeName = a === 'jobQuality' ? 'Job quality' : a;
                  return (
                    <div key={index} className={css.reviewAttribute}>
                      <IconReviewStar className={css.reviewAttributeStar} isFilled />
                      <span className={css.reviewAttributeName}>{attributeName}</span>
                      <span className={css.reviewAttributeRating}>{additionalAttributes[a]}</span>
                    </div>
                  );
                })}
              </div>
              <span className={css.separator}>•</span>
            </>
          ) : null}
          <ReviewRating
            rating={reviewRating}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
          <p className={css.reviewInfo}>
            <span className={css.separator}>•</span>
            {dateString}
          </p>
        </div>
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, professionalReviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        const professionalReview = professionalReviews?.find(review => review.id === r.id.uuid);
        const professionalReviewAttributes = professionalReview?.attributes;
        return (
          <li key={r.id.uuid} className={css.reviewItem}>
            <Review review={r} additionalAttributes={professionalReviewAttributes} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
