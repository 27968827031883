import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './ContactForm.module.css';

const ContactFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        sendMessageError,
        currentUser,
      } = fieldRenderProps;

      const currentUserEmail = currentUser.attributes.email;

      // Email
      const emailLabel = intl.formatMessage({
        id: 'ContactForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ContactForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);

      // Message
      const messageLabel = intl.formatMessage({
        id: 'ContactForm.messageLabel',
      });
      const messagePlaceholder = intl.formatMessage({
        id: 'ContactForm.messagePlaceholder',
      });
      const messageRequiredMessage = intl.formatMessage({
        id: 'ContactForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="text"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={emailRequired}
            defaultValue={currentUserEmail}
            disabled={!!currentUserEmail}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          <div className={css.buttonWrapper}>
            {sendMessageError ? (
              <p className={css.error}>
                <FormattedMessage id="ContactForm.sendMessageError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="ContactForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ContactFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendMessageError: null,
};

ContactFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactForm = compose(injectIntl)(ContactFormComponent);

ContactForm.displayName = 'ContactForm';

export default ContactForm;
