import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviews.module.css';

const IconReviews = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        stroke="#B2B2B2"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.429 1.65a1.854 1.854 0 0 1 3.142 0l3.049 4.824 3.976.487a1.823 1.823 0 0 1 1.178 3.002l-3.315 3.803 1.068 5.264a1.838 1.838 0 0 1-.723 1.839 1.87 1.87 0 0 1-1.98.11L11 18.339l-4.824 2.64a1.87 1.87 0 0 1-1.98-.11 1.839 1.839 0 0 1-.723-1.839l1.068-5.264-3.315-3.803A1.823 1.823 0 0 1 2.404 6.96l3.976-.487L9.429 1.65Z" />
        <path d="M11 14.96V8.674H9.429M8.643 14.96h4.714" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconReviews.defaultProps = {
  className: null,
  rootClassName: null,
};

IconReviews.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconReviews;
