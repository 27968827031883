import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEye.module.css';

const IconEye = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="59" height="59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.5 32.75a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z" fill="#fff" />
      <path
        d="M29.5 19.75c-8.697 0-13.268 7.498-14.446 9.75 1.175 2.288 5.678 9.75 14.446 9.75 8.697 0 13.267-7.498 14.446-9.75-1.178-2.298-5.68-9.75-14.446-9.75Zm0 16.25a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13Z"
        fill="#fff"
      />
      <path
        d="M29.5.25A29.25 29.25 0 1 0 58.75 29.5 29.284 29.284 0 0 0 29.5.25Zm17.743 29.89c-.216.497-5.439 12.36-17.743 12.36-12.305 0-17.527-11.863-17.743-12.36a1.626 1.626 0 0 1 0-1.28c.216-.497 5.438-12.36 17.743-12.36 12.304 0 17.527 11.863 17.743 12.36a1.625 1.625 0 0 1 0 1.28Z"
        fill="#fff"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconEye.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEye.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEye;
