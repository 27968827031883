import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities, ensureAvailabilityException } from '../../util/data';
import { isSameDate, monthIdStringInUTC } from '../../util/dates';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { createSlug } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { uploadDocumentAPI, removeDocumentAPI } from '../../util/api';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import axios from 'axios';

const { UUID } = sdkTypes;

// A helper function to filter away exception that matches start and end timestamps
const removeException = (exception, calendar) => {
  const availabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = availabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [] };

  const exceptions = monthData.exceptions.filter(e => {
    const anException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = anException.attributes.start;
    const exceptionEnd = anException.attributes.end;

    return !(isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end));
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};

// A helper function to add a new exception and remove previous one if there's a matching exception
const addException = (exception, calendar) => {
  const { start } = ensureAvailabilityException(exception.availabilityException).attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  // TODO: API doesn't support "availability_exceptions/update" yet
  // So, when user wants to create an exception we need to ensure
  // that possible existing exception is removed first.
  const cleanCalendar = removeException(exception, calendar);
  const monthData = cleanCalendar[monthId] || { exceptions: [] };

  return {
    ...cleanCalendar,
    [monthId]: { ...monthData, exceptions: [...monthData.exceptions, exception] },
  };
};

// A helper function to update exception that matches start and end timestamps
const updateException = (exception, calendar) => {
  const newAvailabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = newAvailabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [] };

  const exceptions = monthData.exceptions.map(e => {
    const availabilityException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = availabilityException.attributes.start;
    const exceptionEnd = availabilityException.attributes.end;

    return isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end) ? exception : e;
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};

// Update calendar data of given month
const updateCalendarMonth = (state, monthId, data) => {
  // Ensure that every month has array for bookings and exceptions
  const defaultMonthData = { bookings: [], exceptions: [] };
  return {
    ...state,
    availabilityCalendar: {
      ...state.availabilityCalendar,
      [monthId]: {
        ...defaultMonthData,
        ...state.availabilityCalendar[monthId],
        ...data,
      },
    },
  };
};

// Helper object of functions that reduces image ids
// when uploading multiple images at once
const reduceImageIds = {
  requested: images =>
    images.reduce((obj, item) => Object.assign(obj, { [item.id]: { ...item } }), {}),
  uploaded: (images, tempIds) =>
    images.reduce(
      (obj, item, index) =>
        Object.assign(obj, {
          [tempIds[index]]: { ...item, imageId: item.id, id: tempIds[index] },
        }),
      {}
    ),
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { images, imageOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.images);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );
  return duplicateImageEntities.length > 0
    ? {
        images: {},
        imageOrder: [],
      }
    : {
        images,
        imageOrder,
      };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditProfessionalPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditProfessionalPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditProfessionalPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditProfessionalPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditProfessionalPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditProfessionalPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditProfessionalPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditProfessionalPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditProfessionalPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditProfessionalPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditProfessionalPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditProfessionalPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditProfessionalPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditProfessionalPage/SHOW_LISTINGS_ERROR';

export const FETCH_BOOKINGS_REQUEST = 'app/EditProfessionalPage/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/EditProfessionalPage/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/EditProfessionalPage/FETCH_BOOKINGS_ERROR';

export const FETCH_EXCEPTIONS_REQUEST =
  'app/EditProfessionalPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS =
  'app/EditProfessionalPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR =
  'app/EditProfessionalPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const CREATE_EXCEPTION_REQUEST =
  'app/EditProfessionalPage/CREATE_AVAILABILITY_EXCEPTION_REQUEST';
export const CREATE_EXCEPTION_SUCCESS =
  'app/EditProfessionalPage/CREATE_AVAILABILITY_EXCEPTION_SUCCESS';
export const CREATE_EXCEPTION_ERROR =
  'app/EditProfessionalPage/CREATE_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST =
  'app/EditProfessionalPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS =
  'app/EditProfessionalPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR =
  'app/EditProfessionalPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditProfessionalPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditProfessionalPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditProfessionalPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditProfessionalPage/UPDATE_IMAGE_ORDER';
export const REMOVE_LISTING_IMAGE = 'app/EditProfessionalPage/REMOVE_LISTING_IMAGE';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/EditProfessionalPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/EditProfessionalPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/EditProfessionalPage/SAVE_PAYOUT_DETAILS_ERROR';

export const UPLOAD_DOCUMENT_REQUEST = 'app/EditProfessionalPage/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'app/EditProfessionalPage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/EditProfessionalPage/UPLOAD_DOCUMENT_ERROR';

export const REMOVE_DOCUMENT_REQUEST = 'app/EditProfessionalPage/REMOVE_DOCUMENT_REQUEST';
export const REMOVE_DOCUMENT_SUCCESS = 'app/EditProfessionalPage/REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_ERROR = 'app/EditProfessionalPage/REMOVE_DOCUMENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  availabilityCalendar: {
    // '2018-12': {
    //   bookings: [],
    //   exceptions: [],
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: false,
    //   fetchBookingsError: null,
    //   fetchBookingsInProgress: false,
    // },
  },
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  uploadDocumentInProgress: false,
  uploadDocumentError: null,
  removeDocumentInProgress: false,
  removeDocumentError: null,
  removeDocumentId: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      return { ...initialState, availabilityCalendar: { ...state.availabilityCalendar } };

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case FETCH_BOOKINGS_REQUEST:
      return updateCalendarMonth(state, payload.params.monthId, {
        fetchBookingsError: null,
        fetchBookingsInProgress: true,
      });
    case FETCH_BOOKINGS_SUCCESS:
      return updateCalendarMonth(state, payload.monthId, {
        bookings: payload.bookings,
        fetchBookingsInProgress: false,
      });
    case FETCH_BOOKINGS_ERROR:
      return updateCalendarMonth(state, payload.monthId, {
        fetchBookingsError: payload.error,
        fetchBookingsInProgress: false,
      });

    case FETCH_EXCEPTIONS_REQUEST:
      return updateCalendarMonth(state, payload.params.monthId, {
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      });
    case FETCH_EXCEPTIONS_SUCCESS:
      return updateCalendarMonth(state, payload.monthId, {
        exceptions: payload.exceptions,
        fetchExceptionsInProgress: false,
      });
    case FETCH_EXCEPTIONS_ERROR:
      return updateCalendarMonth(state, payload.monthId, {
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      });

    case CREATE_EXCEPTION_REQUEST: {
      const { start, end, seats } = payload.params;
      const draft = ensureAvailabilityException({ attributes: { start, end, seats } });
      const exception = { availabilityException: draft, inProgress: true };
      const availabilityCalendar = addException(exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case CREATE_EXCEPTION_SUCCESS: {
      const availabilityCalendar = updateException(payload.exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case CREATE_EXCEPTION_ERROR: {
      const { availabilityException, error } = payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }

    case DELETE_EXCEPTION_REQUEST: {
      const { id, seats, currentException } = payload.params;

      // We first create temporary exception with given 'seats' count (the default after deletion).
      // This makes it possible to show the UI element immediately with default color that matches
      // with the availability plan.
      const exception = {
        id,
        inProgress: true,
        availabilityException: {
          ...currentException.availabilityException,
          attributes: { ...currentException.availabilityException.attributes, seats },
        },
      };

      const availabilityCalendar = updateException(exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case DELETE_EXCEPTION_SUCCESS: {
      const availabilityCalendar = removeException(payload.exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case DELETE_EXCEPTION_ERROR: {
      const { availabilityException, error } = payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }

    case UPLOAD_IMAGE_REQUEST: {
      const files = payload.params;

      const requestedImageIds = files.map(f => f.id);
      const requestedImages = reduceImageIds.requested(files);

      const uploadedImages = {
        ...state.images,
        ...requestedImages,
      };

      return {
        ...state,
        images: uploadedImages,
        imageOrder: state.imageOrder.concat(requestedImageIds),
        uploadImageError: null,
        uploadImageErrorIds: [],
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      const { images, fileTempIds } = payload;

      const newImages = reduceImageIds.uploaded(images, fileTempIds);
      return { ...state, images: { ...state.images, ...newImages } };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.imageOrder.filter(i => i !== id);

      return {
        ...state,
        imageOrder: uploadedImagesOrder,
        images: omit(state.images, id),
        uploadImageError: error,
        uploadImageErrorIds: [...state.uploadImageErrorIds, id],
      };
    }

    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case UPLOAD_DOCUMENT_REQUEST:
      return { ...state, uploadDocumentInProgress: true, uploadDocumentError: null };
    case UPLOAD_DOCUMENT_SUCCESS:
      return { ...state, uploadDocumentInProgress: false, uploadDocumentError: null };
    case UPLOAD_DOCUMENT_ERROR:
      return { ...state, uploadDocumentInProgress: false, uploadDocumentError: payload };

    case REMOVE_DOCUMENT_REQUEST:
      return {
        ...state,
        removeDocumentInProgress: true,
        removeDocumentId: payload.params.documentId,
        removeDocumentError: null,
      };
    case REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        removeDocumentInProgress: false,
        removeDocumentError: null,
      };
    case REMOVE_DOCUMENT_ERROR:
      return {
        ...state,
        removeDocumentInProgress: false,
        removeDocumentError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: bookings.query
export const fetchBookingsRequest = requestAction(FETCH_BOOKINGS_REQUEST);
export const fetchBookingsSuccess = successAction(FETCH_BOOKINGS_SUCCESS);
export const fetchBookingsError = errorAction(FETCH_BOOKINGS_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const createAvailabilityExceptionRequest = requestAction(CREATE_EXCEPTION_REQUEST);
export const createAvailabilityExceptionSuccess = successAction(CREATE_EXCEPTION_SUCCESS);
export const createAvailabilityExceptionError = errorAction(CREATE_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

export const uploadDocumentRequest = requestAction(UPLOAD_DOCUMENT_REQUEST);
export const uploadDocumentSuccess = successAction(UPLOAD_DOCUMENT_SUCCESS);
export const uploadDocumentError = errorAction(UPLOAD_DOCUMENT_ERROR);

export const removeDocumentRequest = requestAction(REMOVE_DOCUMENT_REQUEST);
export const removeDocumentSuccess = successAction(REMOVE_DOCUMENT_SUCCESS);
export const removeDocumentError = errorAction(REMOVE_DOCUMENT_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));
    return sdk.ownListings
      .show(actionPayload)
      .then(response => {
        // EditProfessionalPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.EditProfessionalPage (user will be redirected away)
        dispatch(showListingsSuccess(response));
        return response;
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

export function requestCreateListingDraft(payload) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraft(payload));
    const { publicData } = payload || {};
    const { userData } = publicData || {};
    const { profileImageId, firstName, lastName, ...rest } = userData || {};

    // Check if there is profile image uploaded
    if (profileImageId) {
      delete payload.publicData.userData.profileImageId;
    }

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(payload, queryParams)
      .then(({ data }) => {
        const listingData = data.data;
        const id = listingData.id;
        // Update professional profile
        const { title, description, state } = (id && listingData.attributes) || {};
        const actionPayload = {
          firstName, lastName, 
          bio: description,
          publicData: {
            ...rest,
            profile: {
              id: id.uuid,
              slug: createSlug(title),
              type: state == 'published' ? 'edit' : state,
            },
          },
        };

        if (profileImageId) {
          Object.assign(actionPayload, { profileImageId });
        }
        console.log(actionPayload, '**** **** => actionPayload');
        // Update professional profile
        dispatch(updateProfile(actionPayload));
        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));

      const listing = response.data.data;

      if (listing) {
        const { title } = listing.attributes;
        const actionPayload = {
          publicData: {
            profile: {
              id: listingId.uuid,
              slug: createSlug(title || ''),
              type: 'edit',
            },
          },
        };

        dispatch(updateProfile(actionPayload));
      }

      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return async (dispatch, getState, sdk) => {
    const files = actionPayload;
    const fileIds = files.map(f => f.id);

    dispatch(uploadImage(files));
    const responses = files.map(file => {
      const queryParams = {
        expand: true,
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      };
      return sdk.images
        .upload({ image: file.file }, queryParams)
        .then(apiResponse => {
          return apiResponse;
        })
        .catch(e => {
          const errorResponse = { id: file.id, error: storableError(e) };
          dispatch(uploadImageError(errorResponse));
          return errorResponse;
        });
    });

    const promises = await Promise.all(responses);
    const validImages = promises.filter(p => !p.error);
    const images = validImages.map(i => i.data.data);

    dispatch(
      uploadImageSuccess({
        data: {
          images,
          fileTempIds: fileIds,
        },
      })
    );
  };
}

export const requestFetchBookings = fetchParams => (dispatch, getState, sdk) => {
  const { listingId, start, end, state } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(fetchBookingsRequest({ ...fetchParams, monthId }));

  return sdk.bookings
    .query({ listingId, start, end, state }, { expand: true })
    .then(response => {
      const bookings = denormalisedResponseEntities(response);
      return dispatch(fetchBookingsSuccess({ data: { monthId, bookings } }));
    })
    .catch(e => {
      return dispatch(fetchBookingsError({ monthId, error: storableError(e) }));
    });
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  const { listingId, start, end } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(fetchAvailabilityExceptionsRequest({ ...fetchParams, monthId }));

  return sdk.availabilityExceptions
    .query({ listingId, start, end }, { expand: true })
    .then(response => {
      const exceptions = denormalisedResponseEntities(response).map(availabilityException => ({
        availabilityException,
      }));
      return dispatch(fetchAvailabilityExceptionsSuccess({ data: { monthId, exceptions } }));
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ monthId, error: storableError(e) }));
    });
};

export const requestCreateAvailabilityException = params => (dispatch, getState, sdk) => {
  const { currentException, ...createParams } = params;

  dispatch(createAvailabilityExceptionRequest(createParams));

  return sdk.availabilityExceptions
    .create(createParams, { expand: true })
    .then(response => {
      dispatch(
        createAvailabilityExceptionSuccess({
          data: {
            exception: {
              availabilityException: response.data.data,
            },
          },
        })
      );
      return response;
    })
    .catch(error => {
      const availabilityException = currentException && currentException.availabilityException;
      return dispatch(
        createAvailabilityExceptionError({
          error: storableError(error),
          availabilityException,
        })
      );
    });
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  const { currentException, seats, ...deleteParams } = params;

  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(deleteParams, { expand: true })
    .then(response => {
      dispatch(
        deleteAvailabilityExceptionSuccess({
          data: {
            exception: currentException,
          },
        })
      );
      return response;
    })
    .catch(error => {
      const availabilityException = currentException && currentException.availabilityException;
      return dispatch(
        deleteAvailabilityExceptionError({
          error: storableError(error),
          availabilityException,
        })
      );
    });
};

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.

export function requestUpdateListing(tab, data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListing(data));
    const { id, publicData } = data || {};
    const { userData } = publicData || {};
    const { profileImageId, firstName, lastName, ...rest } = userData || {};

    if (profileImageId) {
      delete data.publicData.userData.profileImageId;
    }

    return sdk.ownListings
      .update(data, { expand: true })
      .then(response => {
        const payload = {
          id,
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };

        dispatch(requestShowListing(payload));
        return response;
      })
      .then((response) => {
        const listingData = response.data.data;
        const { title, description, state } = listingData.attributes || {};
        const firstNameMaybe = !!firstName ? {firstName} : {};
        const lastNameMaybe = !!lastName ? {lastName} : {};
        const bioMaybe = !!description ? {bio:description} : {};
        const actionPayload = {
          ...firstNameMaybe,
          ...lastNameMaybe,
          ...bioMaybe,
          publicData: {
            ...rest,
            profile: {
              id: id.uuid,
              slug: createSlug(title),
              type: state == 'published' ? 'edit' : state,
            },
          },
        };

        if (profileImageId) {
          Object.assign(actionPayload, { profileImageId });
        }
        console.log(actionPayload, '**** **** => actionPayload');
        // Update professional profile
        dispatch(updateProfile(actionPayload));

        dispatch(markTabUpdated(tab));
        dispatch(updateListingSuccess(response));

        return response;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}


export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const uploadDocument = (listingId, document, documentType) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(uploadDocumentRequest());
  const formData = new FormData();

  // Apend form data
  formData.append('document', document);
  formData.append('listingId', listingId);
  formData.append('documentType', documentType);

  // Set headers
  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(uploadDocumentAPI, formData, {
      headers,
    });
    // We need to show the current ownListing
    // so that we can fetch latest documents
    const payload = {
      id: new UUID(listingId),
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };
    dispatch(requestShowListing(payload));
    dispatch(uploadDocumentSuccess(response));
  } catch (e) {
    dispatch(uploadDocumentError(storableError(e)));
  }
};

export const removeDocument = (listingId, documentId) => async (dispatch, getState, sdk) => {
  dispatch(removeDocumentRequest({ documentId }));

  try {
    const response = await axios(removeDocumentAPI, { params: { listingId, documentId } });
    // We need to show the current ownListing
    // so that we can fetch latest documents
    const payload = {
      id: new UUID(listingId),
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };
    dispatch(requestShowListing(payload));
    dispatch(removeDocumentSuccess(response));
  } catch (e) {
    dispatch(removeDocumentError(storableError(e)));
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  const { id, type } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(response => {
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return Promise.all([dispatch(requestShowListing(payload)), dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
};
