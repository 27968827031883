import React, { useState, useRef } from 'react';
import { string, func, object } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, IconEye, IconArrowHead } from '../../../components';
import Slider from 'react-slick';
import Skeleton from 'react-loading-skeleton';

import css from '../ProfileTabs.module.css';

const DEFAULT_IMAGE_INDEX = 0;
const SLIDER_SPEED = 500;
const SLIDES_TO_SHOW = 2;
const SLIDES_TO_SCROLL = 1;

const ImageOverlay = () => {
  return (
    <div className={css.photosImageOverlay}>
      <IconEye className={css.photosImageOverlayIcon} />
    </div>
  );
};

const Image = props => {
  const { image, imageAlt, onImageClick } = props;
  return (
    <div
      key={image.id.uuid}
      className={css.photosImage}
      onClick={() => {
        onImageClick();
      }}
    >
      <ResponsiveImage
        alt={imageAlt}
        image={image}
        variants={['landscape-crop', 'landscape-crop2x', 'profile-crop']}
      />
      <ImageOverlay />
    </div>
  );
};

Image.defaultProps = {
  image: object.isRequired,
  imageAlt: string,
  onImageClick: func.isRequired,
};

const NextArrow = ({ onClick }) => {
  return (
    <div className={css.arrowIconContainer} onClick={onClick}>
      <svg
        className={css.arrowIcon}
        width="20"
        height="40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.429 18.571 19a1.372 1.372 0 0 1 0 2L1 38.571"
          stroke="#000"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const NextArrowMobile = ({ onClick }) => {
  return (
    <div className={css.mobileArrowIconContainer} onClick={onClick}>
      <IconArrowHead className={css.mobileArrowIcon} direction="right" />
    </div>
  );
};

const PhotosTab = props => {
  const { currentListing, showLoadingSkeleton, onManageDisableScrolling } = props;

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(DEFAULT_IMAGE_INDEX);

  const slider = useRef(null);

  const images = currentListing?.images;
  const hasImages = !!images && images?.length > 0;
  const imageAlt = currentListing?.attributes?.title;

  // Slider settings
  const sliderSettings = {
    infinite: images?.length > 2 ? true : false,
    speed: SLIDER_SPEED,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SCROLL,
    nextArrow: <NextArrow />,
  };

  return (
    <div className={css.tab}>
      <div className={css.sectionContent}>
        <div className={css.sectionHeading}>
          {showLoadingSkeleton ? (
            <Skeleton width="30%" />
          ) : (
            <div className={css.photosHeading}>
              <FormattedMessage
                id="ProfessionalPage.photosHeading"
                values={{ length: images?.length }}
              />
              {hasImages && images.length > SLIDES_TO_SHOW ? (
                <NextArrowMobile onClick={() => slider?.current?.slickNext()} />
              ) : null}
            </div>
          )}
        </div>
        {images?.length > SLIDES_TO_SHOW ? (
          <div className={css.photosSlider}>
            <Slider className={css.photosContainer} ref={slider} {...sliderSettings}>
              {images?.map((image, index) => {
                return (
                  <Image
                    key={image.id.uuid}
                    image={image}
                    imageAlt={imageAlt}
                    onImageClick={() => {
                      setImageCarouselOpen(true);
                      setSelectedImageIndex(index);
                    }}
                  />
                );
              })}
            </Slider>
          </div>
        ) : (
          <div className={css.photosWrapper}>
            {images?.map((image, index) => {
              return (
                <Image
                  key={image.id.uuid}
                  image={image}
                  imageAlt={imageAlt}
                  onImageClick={() => {
                    setImageCarouselOpen(true);
                    setSelectedImageIndex(index);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
      <Modal
        id="ProfessionalPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={() => setImageCarouselOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {images ? <ImageCarousel images={images} selectedImageIndex={selectedImageIndex} /> : null}
      </Modal>
    </div>
  );
};

PhotosTab.defaultProps = {
  currentListing: object,
  onManageDisableScrolling: func.isRequired,
};

export default PhotosTab;
