import React from 'react';
import { string } from 'prop-types';

const ArrowRightIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="14"
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6h11M8 1.5L12.5 6 8 10.5"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowRightIcon.defaultProps = {
  className: null,
};

ArrowRightIcon.propTypes = {
  className: string,
};

export default ArrowRightIcon;
