import React from 'react';
import { object, func, bool } from 'prop-types';
import { ABOUT, PHOTOS, REVIEWS } from './ProfileTabs';

import AboutTab from './Tabs/AboutTab';
import PhotosTab from './Tabs/PhotosTab';
import ReviewsTab from './Tabs/ReviewsTab';

const TabPanel = props => {
  const {
    intl,
    params,
    currentListing,
    profileUser,
    showLoadingSkeleton,
    onManageDisableScrolling,
    isOwnListing,
    professionalReviews,
    reviews,
    queryReviewsInProgress,
    queryReviewsError,
    userSkills,
    companystaff,
    isProfileUserPro,
    isCompanyUser,
  } = props;

  const tabProps = {
    currentListing,
    profileUser,
    showLoadingSkeleton,
    onManageDisableScrolling,
    isOwnListing,
    userSkills,
    companystaff,
    isProfileUserPro,
    isCompanyUser,
  };

  const tab = params.tab || ABOUT;

  switch (tab) {
    case ABOUT:
      return <AboutTab {...tabProps} />;
    case PHOTOS:
      return <PhotosTab {...tabProps} />;
    case REVIEWS:
      return (
        <ReviewsTab
          intl={intl}
          professionalReviews={professionalReviews}
          reviews={reviews}
          queryReviewsInProgress={queryReviewsInProgress}
          queryReviewsError={queryReviewsError}
        />
      );

    default:
      return <AboutTab {...tabProps} />;
  }
};

TabPanel.propTypes = {
  params: object,
  currentListing: object,
  profileUser: object,
  showLoadingSkeleton: bool,
  onManageDisableScrolling: func.isRequired,
};

export default TabPanel;
