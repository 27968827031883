import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMessage.module.css';

const IconMessage = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_908_383)">
        <path
          d="M0.785706 2.75H21.2143V19.25H0.785706V2.75Z"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.785706 4.73785L9.98642 12.5023C10.2699 12.7416 10.629 12.8729 11 12.8729C11.371 12.8729 11.73 12.7416 12.0136 12.5023L21.2143 4.73785"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_908_383">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconMessage.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMessage.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMessage;
