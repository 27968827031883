const POSTED_PROJECT_ACHIEVEMENT = 'achievement/posted-project';

/*
  Achievements object
  You can add new achievement by passing it here
*/
const achievements = [
  {
    key: POSTED_PROJECT_ACHIEVEMENT,
    labelMsgId: 'Achievements.postedProject',
  },
];

// Achievements
export const postedProjectAchievement = achievements.find(
  a => a.key === POSTED_PROJECT_ACHIEVEMENT
);
