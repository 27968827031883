import React from 'react';

import css from './SearchFiltersPrimary.module.css';

const NoResultsIcon = () => (
  <svg
    className={css.noResultsIcon}
    height="120"
    width="120"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
  >
    <path
      style={{
        opacity: 0.2,
        fill: '#f15b26',
      }}
      d="M57.8 30.6c-6.7-4.3-7.6-15.9-11.7-18.2S28.4 7.9 23 14.2c-3.2 3.7-7.6-3.1-11.7-1.7-4.1 1.3-24.2 30.7 3.2 32 17.7.8 27.4 6.6 32.7 4.6 5.2-2.1 17.3-14.2 10.6-18.5z"
    />
    <path
      style={{
        fill: '#f15b26',
      }}
      d="M32.5 13.3c-5.1-5.1-13.3-5.1-18.4 0-5.1 5.1-5.1 13.3 0 18.4 5.1 5.1 13.3 5.1 18.4 0 5.1-5.1 5.1-13.3 0-18.4zm-1.1 17.3c-4.5 4.5-11.8 4.5-16.3 0s-4.5-11.8 0-16.3 11.8-4.5 16.3 0 4.5 11.8 0 16.3z"
    />
    <path
      style={{
        fill: '#f15b26',
      }}
      d="M53.2 49.1c.2-.7 0-1.5-.6-2.1-4.7-4.7-3-3-6.9-6.8-.3-.3-.8-.3-1 0-.3.3-.3.8 0 1 7.6 7.3 7.1 7 7.1 7.3 0 .4-.1.4-1.9 2.2-.1.1-.3.2-.5.2-.4 0 .2.6-10.7-10.4-.2-.2-.3-.5-.2-.7.1-.2 0-.2 1.9-2 .3-.3.8-.3 1.1 0l1.4 1.4c.3.3.7.3 1 0 .3-.3.3-.7 0-1-1.5-1.5-1.8-2-2.8-2.1l-3.7-3.7c6-8.5 3.2-20.5-6.1-25.4-.4-.2-.8 0-1 .3-.2.4 0 .8.3 1 8.8 4.6 11.2 16 5.2 23.7-.2.3-.2.7.1 1l3.6 3.6-2 2-3.6-3.6c-.3-.3-.7-.3-1-.1-10.3 8.1-25.4.7-25.4-12.4C7.5 11.7 18 4 28.4 7.5c.4.1.8-.1.9-.5.1-.4-.1-.8-.5-.9C17.7 2.3 6 10.6 6 22.5c0 14.1 16 22.1 27.3 14.1l3.7 3.7c0 .5.2 1 .6 1.4l10.1 10.1c.5.5 1.3.8 2.1.6.2 1.5 1.5 2.8 3.1 2.8 1.7 0 3.1-1.4 3.1-3.1 0-1.6-1.2-2.9-2.8-3zm-.3 4.6c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6z"
    />
  </svg>
);

export default NoResultsIcon;
