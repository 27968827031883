import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const ProfessionalismIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <path
          d="M7.36 13.43h0a1 1 0 0 1-.72 0h0a9.67 9.67 0 0 1-6.14-9V1.5a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2.92a9.67 9.67 0 0 1-6.14 9.01Z"
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={7}
          cy={5.5}
          r={2.5}
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M3.25 11.19a5 5 0 0 1 7.5 0"
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
    </svg>
  );
};

ProfessionalismIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

ProfessionalismIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProfessionalismIcon;
