import React from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { Form, Button, IconWarning } from '../../components';

import FieldDatesInput from './FieldDatesInput';
import css from './EditListingDatesForm.module.css';

const EditListingDatesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        form,
        values,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const startDateLabel = intl.formatMessage({
        id: 'EditListingDatesForm.startDateLabel',
      });
      const endDateLabel = intl.formatMessage({
        id: 'EditListingDatesForm.endDateLabel',
      });

      const startDateInputProps = {
        label: startDateLabel,
        placeholderText: moment().format('ddd, MMM DD'),
      };
      const endDateInputProps = {
        label: endDateLabel,
        placeholderText: moment()
          .add(1, 'days')
          .format('ddd, MMM DD'),
      };

      const dateInputProps = {
        startDateInputProps,
        endDateInputProps,
      };

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDatesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDatesForm.showListingFailed" />
        </p>
      ) : null;

      const timeZone = momentTimezone.tz.guess();

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldDatesInput
            {...dateInputProps}
            values={values}
            intl={intl}
            form={form}
            pristine={pristine}
            timeZone={timeZone}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDatesFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDatesFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(EditListingDatesFormComponent);
