import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { AddDocuments, Button, Form, IconSpinner, ExternalLink } from '../../components';

import css from './EditListingDocumentsForm.module.css';

const ACCEPT_FILES =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export class EditListingDocumentsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onDocumentUploadHandler = this.onDocumentUploadHandler.bind(this);
  }

  onDocumentUploadHandler(file) {
    if (file) {
      const listingId = this.props.listing.id.uuid;
      this.props.onUploadDocument(listingId, file);
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onDocumentUploadHandler={this.onDocumentUploadHandler}
        initialValues={{ documents: this.props.documents }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            invalid,
            onDocumentUploadHandler,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            listing,
            onRemoveDocument,
            uploadDocumentInProgress,
            removeDocumentInProgress,
            removeDocumentId,
            documents,
          } = formRenderProps;

          const listingId = listing?.id?.uuid;

          const chooseDocumentText = (
            <span className={css.chooseDocumentText}>
              <span className={css.chooseDocument}>
                <FormattedMessage id="EditListingDocumentsForm.chooseDocument" />
              </span>
              <span className={css.documentTypes}>
                <FormattedMessage id="EditListingDocumentsForm.documentTypes" />
              </span>
            </span>
          );

          const { updateListingError, uploadDocumentError, removeDocumentError } =
            fetchErrors || {};

          const uploadDocumentFailed = uploadDocumentError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDocumentsForm.uploadFailed" />
            </p>
          ) : null;
          const removeDocumentFailed = removeDocumentError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDocumentsForm.removeFailed" />
            </p>
          ) : null;

          const uploadingOverlay = uploadDocumentInProgress ? (
            <div className={css.thumbnailLoading}>
              <IconSpinner />
            </div>
          ) : null;

          const googleStorageLink = (
            <ExternalLink
              className={css.googleStorageLink}
              href="https://support.google.com/drive/answer/37603?hl=en"
            >
              <FormattedMessage id="EditListingDocumentsForm.googleStorageLink" />
            </ExternalLink>
          );

          const submitReady = updated || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || ready || uploadDocumentInProgress;

          const classes = classNames(css.root, className);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedDocuments = documents;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingDocumentsForm.updateFailed" />
                </p>
              ) : null}
              {uploadDocumentFailed}
              {removeDocumentFailed}

              <div
                className={classNames(css.formWrapper, {
                  [css.formWrapperWithDocuments]: documents?.length > 0,
                })}
              >
                <AddDocuments
                  className={css.documentsField}
                  onRemoveDocument={onRemoveDocument}
                  removeDocumentInProgress={removeDocumentInProgress}
                  removeDocumentId={removeDocumentId}
                  listingId={listingId}
                  documents={documents}
                >
                  <Field
                    id="addDocument"
                    name="addDocument"
                    accept={ACCEPT_FILES}
                    form={null}
                    label={chooseDocumentText}
                    type="file"
                    disabled={uploadDocumentInProgress}
                  >
                    {fieldprops => {
                      const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`document`, file);
                        form.blur(`document`);
                        onDocumentUploadHandler(file);
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <div className={css.addDocumentWrapper}>
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addDocumentInput} />
                            )}
                            <label htmlFor={name} className={css.addDocument}>
                              {label}
                            </label>
                            {uploadingOverlay}
                          </div>
                        </div>
                      );
                    }}
                  </Field>

                  {/* <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.documentRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="documents"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(documentRequiredMessage))}
                /> */}
                </AddDocuments>

                <p className={css.tip}>
                  <FormattedMessage
                    id="EditListingDocumentsForm.addDocumentsTip"
                    values={{ googleStorageLink }}
                  />
                </p>
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDocumentsFormComponent.defaultProps = { fetchErrors: null, documents: [] };

EditListingDocumentsFormComponent.propTypes = {
  fetchErrors: shape({
    uploadDocumentError: propTypes.error,
    removeDocumentError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  documents: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(EditListingDocumentsFormComponent);
