import React from 'react';
import { bool, object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { calculateTotalPriceFromPercentage } from '../../util/currency';
import config from '../../config';

import css from './EnquiryBreakdown.module.css';

const { Money } = sdkTypes;

const ItemTotalPrice = props => {
  const { isProvider, transactionQuote, intl } = props;
  const { amount, currency } = transactionQuote.price;

  // As it's a reverse marketplace flow we are switching
  // the messages
  const totalLabel = isProvider ? (
    <FormattedMessage id="BookingBreakdown.total" />
  ) : (
    <FormattedMessage id="BookingBreakdown.providerTotalDefault" />
  );

  const commissionPrice = { amount, currency };
  const commissionPercentage = isProvider
    ? config.customerCommissionPercentage
    : config.providerCommissionPercentage;

  const commission = calculateTotalPriceFromPercentage(commissionPrice, commissionPercentage);
  const combinedTotalAndCommission = amount + commission?.amount;

  const totalPrice = new Money(combinedTotalAndCommission, currency);
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

ItemTotalPrice.propTypes = {
  isProvider: bool.isRequired,
  transactionQuote: object.isRequired,
  intl: intlShape.isRequired,
};

export default ItemTotalPrice;
