import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleArrow.module.css';

const IconCircleArrow = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 8a6.75 6.75 0 1 1-13.5 0 6.75 6.75 0 0 1 13.5 0ZM16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8ZM7.47 5.53l1.72 1.72H4v1.5h5.19l-1.72 1.72 1.06 1.06 3-3a.75.75 0 0 0 0-1.06l-3-3-1.06 1.06Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconCircleArrow.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCircleArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCircleArrow;
