import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditProfessionalDocumentsForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ProfileLink, IconHelp } from '..';
import ReactTooltip from 'react-tooltip';

import css from './EditProfessionalDocumentsPanel.module.css';

class EditProfessionalDocumentsPanel extends Component {
  render() {
    const {
      intl,
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      listing,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onUploadDocument,
      uploadDocumentInProgress,
      onRemoveDocument,
      removeDocumentInProgress,
      removeDocumentId,
      tab,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditProfessionalDocumentsPanel.title"
        values={{
          yourProfileLink: (
            <ProfileLink listing={listing} tab={tab}>
              <FormattedMessage id="EditProfessionalDocumentsPanel.yourProfile" />
            </ProfileLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditProfessionalDocumentsPanel.createListingTitle" />
    );

    const documents = currentListing.attributes.publicData?.documents;

    return (
      <div className={classes}>
        <h1 className={css.title}>
          {panelTitle}
          <span
            className={css.tooltip}
            data-tip={intl.formatMessage({ id: 'EditProfessionalDocumentsPanel.toolTip' })}
          >
            <IconHelp />
            <ReactTooltip className={css.tip} />
          </span>
        </h1>
        <EditProfessionalDocumentsForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ documents }}
          onSubmit={values => {
            const { documents } = values;

            const verifications = documents
              .map(d => {
                const verification =
                  d.type === 'business-licence'
                    ? 'licenced'
                    : d.type === 'certificate-of-insurance'
                    ? 'insured'
                    : null;
                return verification;
              })
              ?.filter(d => d !== null);

            const passedDocumentsMaybe = !publicData?.passedDocuments
              ? { passedDocuments: true }
              : {};
            const updateValues = {
              publicData: { documents, verifications, ...passedDocumentsMaybe },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          onUploadDocument={onUploadDocument}
          uploadDocumentInProgress={uploadDocumentInProgress}
          onRemoveDocument={onRemoveDocument}
          removeDocumentInProgress={removeDocumentInProgress}
          removeDocumentId={removeDocumentId}
          documents={documents}
          listing={listing}
        />
      </div>
    );
  }
}

EditProfessionalDocumentsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditProfessionalDocumentsPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveDocument: func,
};

export default EditProfessionalDocumentsPanel;
