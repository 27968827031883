import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/InvitationsPage/SET_INITIAL_STATE';

export const FETCH_INVITATIONS_REQUEST = 'app/InvitationsPage/FETCH_INVITATIONS_REQUEST';
export const FETCH_INVITATIONS_SUCCESS = 'app/InvitationsPage/FETCH_INVITATIONSS_SUCCESS';
export const FETCH_INVITATIONS_ERROR = 'app/InvitationsPage/FETCH_INVITATIONS_ERROR';

export const REMOVE_INVITATION_REQUEST = 'app/InvitationsPage/REMOVE_INVITATION_REQUEST';
export const REMOVE_INVITATION_SUCCESS = 'app/InvitationsPage/REMOVE_INVITATION_SUCCESS';
export const REMOVE_INVITATION_ERROR = 'app/InvitationsPage/REMOVE_INVITATION_ERROR';

export const UPDATE_INVITATION_REQUEST = 'app/InvitationsPage/UPDATE_INVITATION_REQUEST';
export const UPDATE_INVITATION_SUCCESS = 'app/InvitationsPage/UPDATE_INVITATION_SUCCESS';
export const UPDATE_INVITATION_ERROR = 'app/InvitationsPage/UPDATE_INVITATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingRefs: [],
  fetchInvitationsInProgress: false,
  fetchInvitationsError: null,
  removeInvitationId: null,
  removeInvitationInProgress: false,
  removeInvitationError: null,
  updateInvitationInProgress: false,
  updateInvitationError: null,
};

const removeInvitationId = (invitations, invitationId) =>
  invitations.filter(invitation => invitationId !== invitation.uuid);

export default function invitationsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case FETCH_INVITATIONS_REQUEST:
      return {
        ...state,
        fetchInvitationsInProgress: true,
      };
    case FETCH_INVITATIONS_SUCCESS: {
      return {
        ...state,
        fetchInvitationsInProgress: false,
        listingRefs: payload,
      };
    }
    case FETCH_INVITATIONS_ERROR:
      return {
        ...state,
        fetchInvitationsInProgress: false,
        fetchInvitationsError: payload,
      };

    case REMOVE_INVITATION_REQUEST:
      return {
        ...state,
        removeInvitationId: payload.invitationId,
        removeInvitationInProgress: true,
      };
    case REMOVE_INVITATION_SUCCESS:
      return {
        ...state,
        removeInvitationInProgress: false,
        listingRefs: removeInvitationId(state.listingRefs, payload.invitationId),
      };
    case REMOVE_INVITATION_ERROR:
      return {
        ...state,
        removeInvitationInProgress: false,
        removeInvitationError: payload,
      };

    case UPDATE_INVITATION_REQUEST:
      return {
        ...state,
        updateInvitationInProgress: true,
        updateInvitationError: null,
      };
    case UPDATE_INVITATION_SUCCESS:
      return {
        ...state,
        updateInvitationInProgress: false,
        updateInvitationError: null,
      };
    case UPDATE_INVITATION_ERROR:
      return {
        ...state,
        updateInvitationInProgress: false,
        updateInvitationError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchInvitationsRequest = () => ({
  type: FETCH_INVITATIONS_REQUEST,
});
export const fetchInvitationsSuccess = listingRefs => ({
  type: FETCH_INVITATIONS_SUCCESS,
  payload: listingRefs,
});
export const fetchInvitationsError = e => ({
  type: FETCH_INVITATIONS_ERROR,
  error: true,
  payload: e,
});

export const removeInvitationRequest = invitationId => ({
  type: REMOVE_INVITATION_REQUEST,
  payload: { invitationId },
});
export const removeInvitationSuccess = invitationId => ({
  type: REMOVE_INVITATION_SUCCESS,
  payload: { invitationId },
});
export const removeInvitationError = e => ({
  type: REMOVE_INVITATION_ERROR,
  error: true,
  payload: e,
});

export const updateInvitationRequest = () => ({ type: UPDATE_INVITATION_REQUEST });
export const updateInvitationSuccess = () => ({ type: UPDATE_INVITATION_SUCCESS });
export const updateInvitationError = e => ({
  type: UPDATE_INVITATION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchInvitations = () => (dispatch, getState, sdk) => {
  dispatch(fetchInvitationsRequest());

  dispatch(fetchCurrentUser()).then(user => {
    const invitationListingIds = user?.attributes?.profile?.publicData?.invitations;
    const invitationIds =
      invitationListingIds?.length > 0 ? invitationListingIds.map(i => i.id) : [];

    return sdk.listings
      .query({
        ids: invitationIds,
        include: ['author', 'author.profileImage'],
        'fields.image': [
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ],
      })
      .then(response => {
        const listings = response.data.data;
        const listingRefs = listings?.map(l => l.id);

        dispatch(addMarketplaceEntities(response));
        dispatch(fetchInvitationsSuccess(listingRefs));
      })
      .catch(e => dispatch(fetchInvitationsError(storableError(e))));
  });
};

export const removeInvitation = invitationId => (dispatch, getState, sdk) => {
  dispatch(removeInvitationRequest(invitationId));

  dispatch(fetchCurrentUser()).then(user => {
    const profileInvitations = user.attributes.profile.publicData?.invitations;
    const newInvitationsArray = profileInvitations.filter(i => i.id !== invitationId);
    const invitations = newInvitationsArray?.length > 0 ? newInvitationsArray : null;

    return sdk.currentUser
      .updateProfile({
        publicData: {
          invitations,
        },
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(removeInvitationSuccess(invitationId));

        return response;
      })
      .catch(e => dispatch(removeInvitationError(storableError(e))));
  });
};

export const updateInvitation = (invitationId, txId) => async (dispatch, getState, sdk) => {
  const user = await dispatch(fetchCurrentUser());

  let invitations = user?.attributes?.profile?.publicData?.invitations;
  let invitationIndex = invitations.findIndex(i => i.id === invitationId);
  invitations[invitationIndex].txId = txId;

  const updateUserProfile = await dispatch(updateProfile({ publicData: { invitations } }));
  return updateUserProfile;
};

export const loadData = () => async (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  const fetchLatestInvitations = await dispatch(fetchInvitations());
  return fetchLatestInvitations;
};
