import { denormalisedResponseEntities } from '../../util/data';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const UPDATE_PLAN_REQUEST = 'app/LandingPage/UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'app/LandingPage/UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_ERROR = 'app/LandingPage/UPDATE_PLAN_ERROR';

// ================ Reducer ================ //

const initialState = {
  updatePlanInProgress: false,
  updatePlanError: null,
};

export default function subscriptionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case UPDATE_PLAN_REQUEST:
      return {
        ...state,
        updatePlanInProgress: true,
        updatePlanError: null,
      };
    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        updatePlanInProgress: false,
      };
    case UPDATE_PLAN_ERROR:
      return { ...state, updatePlanError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const updatePlanRequest = () => ({
  type: UPDATE_PLAN_REQUEST,
});

export const updatePlanSuccess = () => ({
  type: UPDATE_PLAN_SUCCESS,
});

export const updatePlanError = e => ({
  type: UPDATE_PLAN_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const updatePlan = plan => async (dispatch, getState, sdk) => {
  dispatch(updatePlanRequest());

  try {
    const payload = { publicData: { plan } };
    const response = await sdk.currentUser.updateProfile(payload, { expand: true });

    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
    }
    const currentUser = entities[0];

    dispatch(updatePlanSuccess());
    dispatch(currentUserShowSuccess(currentUser));
    return response;
  } catch (e) {
    dispatch(updatePlanError(storableError(e)));
  }
};
