import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const CleanlinessIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        d="m12.64 1.87-.84 2.48a.41.41 0 0 0 0 .37l1.57 2.1a.4.4 0 0 1-.33.64h-2.62a.43.43 0 0 0-.33.17l-1.46 2.1a.4.4 0 0 1-.71-.11l-.78-2.5a.38.38 0 0 0-.26-.26l-2.5-.78a.4.4 0 0 1-.11-.71l2.14-1.51a.43.43 0 0 0 .17-.33V.91a.4.4 0 0 1 .6-.33l2.1 1.57a.41.41 0 0 0 .37.05l2.48-.84a.4.4 0 0 1 .51.51ZM7.04 6.96.5 13.5"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
    </svg>
  );
};

CleanlinessIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

CleanlinessIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default CleanlinessIcon;
