import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldSelect,
  IconHelp,
} from '../../components';
import config from '../../config';
import ReactTooltip from 'react-tooltip';

import css from './EditListingLocationForm.module.css';

const identity = v => v;
const OTHER_OPTION = 'other';

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const homeAddressMessage = intl.formatMessage({ id: 'EditListingLocationForm.homeAddress' });
      const homeAddressPlaceholder = intl.formatMessage({
        id: 'EditListingLocationForm.homeAddressPlaceholder',
      });

      const timeFrameLabel = intl.formatMessage({
        id: 'EditListingFeaturesForm.timeFrameLabel',
      });
      const timeFramePlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.timeFramePlaceholder',
      });
      const timeFrameRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.timeFrameRequired',
      });
      const timeFrameRequired = required(timeFrameRequiredMessage);

      const otherTimeframePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherTimeframePlaceholder',
      });
      const otherTimeframeRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherTimeframeRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const filterConfig = config.custom.filters;
      const timeFrameOptions = findOptionsForSelectFilter('project-timeframe', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            serviceLocationSearch
          />

          <div className={css.homeAddress}>
            <label>
              {homeAddressMessage}
              <span
                className={css.tooltip}
                data-for="Tooltip.homeAddress"
                data-tip={intl.formatMessage({ id: 'EditProfessionalLocationForm.toolTip' })}
              >
                <IconHelp className={css.tooltipIcon} />
                <ReactTooltip id="Tooltip.homeAddress" className={css.tip} />
              </span>
            </label>
            <FieldTextInput
              id="homeAddress"
              name="homeAddress"
              type="text"
              placeholder={homeAddressPlaceholder}
            />
          </div>

          <FieldSelect
            className={css.timeframe}
            id="timeframe"
            name="timeframe"
            label={timeFrameLabel}
            validate={timeFrameRequired}
          >
            <option disabled value="">
              {timeFramePlaceholder}
            </option>
            {timeFrameOptions.map(t => {
              return (
                <option key={t.key} value={t.key}>
                  {t.label}
                </option>
              );
            })}
          </FieldSelect>

          {values.timeframe === OTHER_OPTION ? (
            <div className={css.otherOptionContainer}>
              <FieldTextInput
                id="otherTimeframe"
                name="otherTimeframe"
                type="text"
                placeholder={otherTimeframePlaceholder}
                validate={required(otherTimeframeRequiredMessage)}
              />
              <span className={css.otherOptionBadge}>{timeFrameLabel}</span>
            </div>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
