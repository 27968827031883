import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldTextInput, FieldCheckboxGroup, FieldSelect, Form } from '../../components';
import { required, requiredFieldArrayCheckbox } from '../../util/validators';

import css from './EditListingFeaturesForm.module.css';

const OTHER_OPTION = 'other';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        intl,
        disabled,
        ready,
        invalid,
        rootClassName,
        className,
        values,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const timeFrameLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.timeFrameLabel' });
      const timeFramePlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.timeFramePlaceholder',
      });
      const timeFrameRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.timeFrameRequired',
      });
      const timeFrameRequired = required(timeFrameRequiredMessage);

      const otherTimeframePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherTimeframePlaceholder',
      });
      const otherTimeframeRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherTimeframeRequiredMessage',
      });

      // Skils
      const skillsLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.skillsLabel' });
      const skillsRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.skillsRequiredMessage',
      });
      const skillsRequired = requiredFieldArrayCheckbox(skillsRequiredMessage);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('project-skills', filterConfig);
      const timeFrameOptions = findOptionsForSelectFilter('project-timeframe', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldSelect
            id="timeframe"
            name="timeframe"
            label={timeFrameLabel}
            validate={timeFrameRequired}
          >
            <option disabled value="">
              {timeFramePlaceholder}
            </option>
            {timeFrameOptions.map(t => {
              return (
                <option key={t.key} value={t.key}>
                  {t.label}
                </option>
              );
            })}
          </FieldSelect>

          {values.timeframe === OTHER_OPTION ? (
            <div className={css.otherOptionContainer}>
              <FieldTextInput
                id="otherTimeframe"
                name="otherTimeframe"
                type="text"
                placeholder={otherTimeframePlaceholder}
                validate={required(otherTimeframeRequiredMessage)}
              />
              <span className={css.otherOptionBadge}>{timeFrameLabel}</span>
            </div>
          ) : null}

          <FieldCheckboxGroup
            className={css.features}
            textClassName={css.featuresTextClass}
            id="skills"
            name="skills"
            label={skillsLabel}
            options={options}
            validate={skillsRequired}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
