import React from 'react';
import classNames from 'classnames';

import css from './IconShareProfile.module.css';

const IconShareProfile = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <circle
          cx={2.75}
          cy={7}
          r={2.25}
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={11.25}
          cy={11.25}
          r={2.25}
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={11.25}
          cy={2.75}
          r={2.25}
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m4.76 6 4.48-2.25M4.76 8l4.48 2.25"
        />
      </g>
    </svg>
  );
};

export default IconShareProfile;
