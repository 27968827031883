import React from 'react';
import PropTypes from 'prop-types';

const IconFilledCheckbox = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity={0.1} cx={9} cy={9.528} r={9} fill="#217BF4" />
      <circle cx={9} cy={9.528} r={4} fill="#f15b26" />
    </svg>
  );
};

const { string } = PropTypes;

IconFilledCheckbox.defaultProps = {
  className: null,
};

IconFilledCheckbox.propTypes = {
  className: string,
};

export default IconFilledCheckbox;
