import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ENQUIRE,
  TRANSITION_DISCUSSION,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_PRO_DISCUSSION,
  TRANSITION_PROPOSAL,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_DECLINE,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_DECLINE_COUNTER_OFFER,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_AUTO_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_ACCEPT,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_PROPOSAL,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_DISPUTE,
  TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
  TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
  TRANSITION_CANCEL_FROM_DISPUTED,
  TRANSITION_CLOSE_QUOTE,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryOwnListings } from '../ManagementPage/ManagementPage.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const sortLastTransitions = sort => {
  switch (sort) {
    case 'not-started':
      return [
        TRANSITION_ENQUIRE,
        TRANSITION_DISCUSSION,
        TRANSITION_REQUEST_PAYMENT,
        TRANSITION_PRO_DISCUSSION,
        TRANSITION_PROPOSAL,
        TRANSITION_DECLINE_PROPOSAL,
        TRANSITION_DECLINE,
        TRANSITION_COUNTER_OFFER,
        TRANSITION_DECLINE_COUNTER_OFFER,
        TRANSITION_EXPIRE_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_AUTO_DECLINE,
        TRANSITION_EXPIRE,
        TRANSITION_CANCEL,
      ];
    case 'in-progress':
      return [TRANSITION_ACCEPT_PROPOSAL, TRANSITION_ACCEPT_COUNTER_OFFER, TRANSITION_ACCEPT];
    case 'completed':
      return [
        TRANSITION_COMPLETE,
        TRANSITION_COMPLETE_PROPOSAL,
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
        TRANSITION_DISPUTE,
        TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
        TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
        TRANSITION_CANCEL_FROM_DISPUTED,
      ];

    case 'all-transactions':
    default:
      return TRANSITIONS;
  }
};

const BLACKLISTED_TRANSITIONS = [TRANSITION_CLOSE_QUOTE];

// ================ Action types ================ //

export const FETCH_SALES_REQUEST = 'app/InboxPage/FETCH_SALES_REQUEST';
export const FETCH_SALES_SUCCESS = 'app/InboxPage/FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'app/InboxPage/FETCH_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchSalesInProgress: false,
  fetchSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SALES_REQUEST:
      return { ...state, fetchSalesInProgress: true, fetchSalesError: null };
    case FETCH_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchSalesInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchSalesInProgress: false, fetchSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchSalesRequest = () => ({ type: FETCH_SALES_REQUEST });
const fetchSalesSuccess = response => ({
  type: FETCH_SALES_SUCCESS,
  payload: response,
});
const fetchSalesError = e => ({
  type: FETCH_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const fetchSales = (search, listingId) => async (dispatch, getState, sdk) => {
  dispatch(fetchSalesRequest());

  const { page = 1, sort } = parse(search);
  const lastTransitions = sortLastTransitions(sort);
  const allowedTransitions = lastTransitions.filter(
    transition => !BLACKLISTED_TRANSITIONS.includes(transition)
  );

  const apiQueryParams = {
    only: 'sale',
    lastTransitions: allowedTransitions,
    listingId,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchSalesSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchSalesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch(queryOwnListings()).then(response => {
    const listings = response.data.data;
    const selectedListingId = listings[0]?.id?.uuid;

    const listingId = params.id || selectedListingId;

    return dispatch(fetchSales(search, listingId));
  });
};
