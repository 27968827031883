/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    onLogout,
    isCurrentUserClient,
    isCurrentUserProfessional,
    currentUserHasProfile,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewCreateListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const currentProfessionalUserProfile = currentUser?.attributes?.profile?.publicData?.profile;
  const showEditProfessionalPageLink = currentUserHasProfile && currentProfessionalUserProfile;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.homeLink, currentPageClass('LandingPage'))}
          name="LandingPage"
        >
          <FormattedMessage id="TopbarMobileMenu.homeLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
          name="AboutPage"
        >
          <FormattedMessage id="TopbarMobileMenu.whyEquippedLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
          name="SearchPage"
        >
          <FormattedMessage id="TopbarMobileMenu.findProjectsLink" />
        </NamedLink>
        {currentUserHasListings ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManagementPage'))}
            name="ManagementPage"
          >
            <FormattedMessage id="TopbarMobileMenu.managementLink" />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SearchProfessionalsPage'))}
          name="SearchProfessionalsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.findProfessionalsLink" />
        </NamedLink>
        {isCurrentUserProfessional ? (
          <NamedLink
            className={classNames(
              css.navigationLink,

              currentPageClass('InvitationsPage')
            )}
            name="InvitationsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.invitationsLink" />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('CalendarPage'))}
          name="CalendarPage"
        >
          <FormattedMessage id="TopbarMobileMenu.calendarLink" />
        </NamedLink>
        {isCurrentUserProfessional ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('InboxPageProfessional'))}
            name="InboxPageProfessional"
          >
            <FormattedMessage id="TopbarMobileMenu.quotesLink" />
          </NamedLink>
        ) : null}
        {isCurrentUserProfessional && !showEditProfessionalPageLink ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('NewProfessionalPage'))}
            name="NewProfessionalPage"
          >
            <FormattedMessage id="TopbarMobileMenu.createProfileLink" />
          </NamedLink>
        ) : null}
        {isCurrentUserClient ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
        ) : null}
        {isCurrentUserProfessional && showEditProfessionalPageLink ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('EditProfessionalPage'))}
            name="EditProfessionalPage"
            params={{
              id: currentProfessionalUserProfile?.id,
              slug: currentProfessionalUserProfile?.slug || '',
              type: currentProfessionalUserProfile?.type || 'draft',
              tab: 'description',
            }}
          >
            <FormattedMessage id="TopbarMobileMenu.editProfileLink" />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
