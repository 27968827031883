import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities, isUserPro } from '../../util/data';
import { TRANSITION_ENQUIRE, TRANSITION_DISCUSSION } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { uploadDocumentAPI } from '../../util/api';
import axios from 'axios';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const QUERY_PROFESSIONALS_REQUEST = 'app/ListingPage/QUERY_PROFESSIONALS_REQUEST';
export const QUERY_PROFESSIONALS_SUCCESS = 'app/ListingPage/QUERY_PROFESSIONALS_SUCCESS';
export const QUERY_PROFESSIONALS_ERROR = 'app/ListingPage/QUERY_PROFESSIONALS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  professionalRefs: [],
  queryProfessionalsInProgress: false,
  queryProfessionalsError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case QUERY_PROFESSIONALS_REQUEST:
      return {
        ...state,
        queryProfessionalsInProgress: true,
      };
    case QUERY_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        queryProfessionalsInProgress: false,
        professionalRefs: payload.professionalIds,
      };
    case QUERY_PROFESSIONALS_ERROR:
      return {
        ...state,
        queryProfessionalsInProgress: false,
        queryProfessionalsError: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const queryProfessionalsRequest = () => ({
  type: QUERY_PROFESSIONALS_REQUEST,
});
export const queryProfessionalsSuccess = professionalIds => ({
  type: QUERY_PROFESSIONALS_SUCCESS,
  payload: { professionalIds },
});
export const queryProfessionalsError = e => ({
  type: QUERY_PROFESSIONALS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const queryProfessionals = listingData => (dispatch, getState, sdk) => {
  dispatch(queryProfessionalsRequest());

  const listing = listingData.data.data;
  const { publicData } = listing.attributes;

  // publicData values
  const skills = publicData?.skills;
  const shortLocation = publicData?.location?.shortLocation;

  const params = {
    pub_type: config.listingTypeProfessional,
    pub_skills: `has_any:${skills?.join(',')}`,
    pub_serviceLocationAddresses: `has_any:${shortLocation}`,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const { currentUser } = getState().user || {};

      const denormalisedProfessionals = denormalisedResponseEntities(response);
      const filteredProfessionals = denormalisedProfessionals.filter(p => {
        const isCurrentUserAuthor = currentUser.id.uuid === p.author.id.uuid;
        const isAuthorPro = isUserPro(p.author);

        const showProfessional = !isCurrentUserAuthor && isAuthorPro;
        return showProfessional;
      });

      const professionalRefs = filteredProfessionals.map(l => l.id);
      dispatch(queryProfessionalsSuccess(professionalRefs));
    })
    .catch(e => dispatch(storableError(e)));
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      dispatch(queryProfessionals(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const sendMessage = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_DISCUSSION,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      if (message) {
        // Send the message to the created transaction
        return sdk.messages.send({ transactionId, content: message }).then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return transactionId;
        });
      }

      dispatch(sendEnquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const sendQuote = bodyParams => (dispatch, getState, sdk) => {
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      dispatch(sendEnquirySuccess());
      const transactionId = response.data.data.id;
      return transactionId;
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const uploadDocument = document => async (dispatch, getState, sdk) => {
  const formData = new FormData();

  // Apend form data
  formData.append('document', document);

  // Set headers
  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(uploadDocumentAPI, formData, {
      headers,
    });

    return response;
  } catch (e) {
    console.log('Error while uploading the document', e);
  }
};

export const sendEnquiry = params => dispatch => {
  dispatch(sendEnquiryRequest());

  const { listingId, professionalListingId, budget, document } = params;

  const protectedData = {
    quote: {
      professionalListingId,
      price: {
        amount: budget.amount,
        currency: budget.currency,
      },
    },
  };

  // API body params
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: {
      listingId,
      protectedData,
    },
  };

  if (document) {
    return dispatch(uploadDocument(document))
      .then(doc => {
        const documentMaybe = doc
          ? {
              document: doc.data,
            }
          : {};

        return documentMaybe;
      })
      .then(documentMaybe => {
        const bodyParamsWithDocument = {
          ...bodyParams,
          params: {
            ...bodyParams.params,
            protectedData: {
              quote: {
                ...bodyParams.params.protectedData.quote,
                ...documentMaybe,
              },
            },
          },
        };

        return dispatch(sendQuote(bodyParamsWithDocument));
      });
  } else {
    return dispatch(sendQuote(bodyParams));
  }
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]);
};
