import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import InviteCard from './InviteCard';
import css from './InviteProfessional.module.css';

const InviteProfessionalComponent = props => {
  const {
    className,
    rootClassName,
    onSubmit,
    inviteError,
    inviteInProgress,
    ownListings,
    userInvitations,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // We need to manage the state of the current invite ID
  const [currentInviteId, setCurrentInviteId] = useState(null);

  const handleInvite = listingId => {
    setCurrentInviteId(listingId);
    onSubmit(listingId);
  };

  return (
    <div className={classes}>
      <div className={css.listingsWrapper}>
        {ownListings.map(l => {
          return (
            <InviteCard
              key={l.id.uuid}
              listing={l}
              inviteInProgress={inviteInProgress}
              inviteError={inviteError}
              currentInviteId={currentInviteId}
              setCurrentInviteId={setCurrentInviteId}
              userInvitations={userInvitations}
              onSubmit={() => handleInvite(l.id.uuid)}
            />
          );
        })}
      </div>
    </div>
  );
};

InviteProfessionalComponent.defaultProps = {
  className: null,
  rootClassName: null,
  inviteError: null,
};

const { bool, func, string, array } = PropTypes;

InviteProfessionalComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  ownListings: array.isRequired,
  userVerifications: array,

  // InviteCard specific
  onSubmit: func.isRequired,
  inviteError: propTypes.error,
  inviteInProgress: bool.isRequired,
};

const InviteProfessional = compose(injectIntl)(InviteProfessionalComponent);
InviteProfessional.displayName = 'InviteProfessional';

export default InviteProfessional;
