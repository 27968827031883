import React from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowHead, ExternalLink } from '../../components';
import classNames from 'classnames';

import css from './TransactionPanel.module.css';

const DocumentLinkMaybe = props => {
  const { transactionDocument, showDocument, onShowDocument } = props;

  if (!transactionDocument) {
    return null;
  }

  return (
    <div className={css.showDocument}>
      <div className={css.showDocumentLabel} onClick={onShowDocument}>
        <FormattedMessage id="TransactionPanel.showDocument" />
        <IconArrowHead
          className={classNames(css.showDocumentIcon, {
            [css.showDocumentIconActive]: showDocument,
          })}
          direction="down"
        />
      </div>
      {showDocument ? (
        <div className={css.transactionDocument}>
          <ExternalLink className={css.transactionDocumentLink} href={transactionDocument.link}>
            {transactionDocument.name}
          </ExternalLink>
        </div>
      ) : null}
    </div>
  );
};

DocumentLinkMaybe.defaultProps = {
  onShowDocument: null,
};

DocumentLinkMaybe.propTypes = {
  transactionDocument: object,
  onShowDocument: func.isRequired,
};

export default DocumentLinkMaybe;
