import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { LISTING_STATE_DRAFT, LISTING_STATE_CLOSED } from '../../util/types';
import { inviteProfessionalAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import config from '../../config';
import axios from 'axios';
import { TRANSITION_PRO_DISCUSSION, TRANSITION_PROPOSAL } from '../../util/transaction';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfessionalPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfessionalPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfessionalPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfessionalPage/SHOW_USER_ERROR';

export const QUERY_CURRENT_USER_LISTINGS_REQUEST =
  'app/ProfessionalPage/QUERY_CURRENT_USER_LISTINGS_REQUEST';
export const QUERY_CURRENT_USER_LISTINGS_SUCCESS =
  'app/ProfessionalPage/QUERY_CURRENT_USER_LISTINGS_SUCCESS';
export const QUERY_CURRENT_USER_LISTINGS_ERROR =
  'app/ProfessionalPage/QUERY_CURRENT_USER_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfessionalPage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfessionalPage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfessionalPage/QUERY_REVIEWS_ERROR';

export const SHOW_LISTING_REQUEST = 'app/ProfessionalPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ProfessionalPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ProfessionalPage/SHOW_LISTING_ERROR';

export const INVITE_PROFESSIONAL_REQUEST = 'app/ProfessionalPage/INVITE_PROFESSIONAL_REQUEST';
export const INVITE_PROFESSIONAL_SUCCESS = 'app/ProfessionalPage/INVITE_PROFESSIONAL_SUCCESS';
export const INVITE_PROFESSIONAL_ERROR = 'app/ProfessionalPage/INVITE_PROFESSIONAL_ERROR';

export const CONTACT_PROFESSIONAL_REQUEST = 'app/ProfessionalPage/CONTACT_PROFESSIONAL_REQUEST';
export const CONTACT_PROFESSIONAL_SUCCESS = 'app/ProfessionalPage/CONTACT_PROFESSIONAL_SUCCESS';
export const CONTACT_PROFESSIONAL_ERROR = 'app/ProfessionalPage/CONTACT_PROFESSIONAL_ERROR';

export const SEND_PROPOSAL_REQUEST = 'app/ProfessionalPage/SEND_PROPOSAL_REQUEST';
export const SEND_PROPOSAL_SUCCESS = 'app/ProfessionalPage/SEND_PROPOSAL_SUCCESS';
export const SEND_PROPOSAL_ERROR = 'app/ProfessionalPage/SEND_PROPOSAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  reviews: [],
  queryReviewsInProgress: false,
  queryReviewsError: null,
  listingId: null,
  currentUserListingRefs: [],
  queryCurrentUserListingsInProgress: false,
  queryCurrentUserListingsError: null,
  inviteProfessionalInProgress: false,
  inviteProfessionalError: null,
  contactProfessionalInProgress: false,
  contactProfessionalError: null,
  sendProposalInProgress: false,
  sendProposalError: null,
};

export default function professionalPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsInProgress: true, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, queryReviewsInProgress: false, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsInProgress: false, queryReviewsError: payload };

    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        listingId: payload,
        showListingInProgress: true,
      };
    case SHOW_LISTING_SUCCESS: {
      return {
        ...state,
        showListingInProgress: false,
      };
    }
    case SHOW_LISTING_ERROR:
      return {
        ...state,
        showListingInProgress: false,
      };

    case QUERY_CURRENT_USER_LISTINGS_REQUEST:
      return {
        ...state,
        queryCurrentUserListingsInProgress: true,
      };
    case QUERY_CURRENT_USER_LISTINGS_SUCCESS:
      return {
        ...state,
        queryCurrentUserListingsInProgress: false,
        currentUserListingRefs: payload.listingRefs,
      };
    case QUERY_CURRENT_USER_LISTINGS_ERROR:
      return {
        ...state,
        queryCurrentUserListingsInProgress: false,
        queryCurrentUserListingsError: payload,
      };

    case INVITE_PROFESSIONAL_REQUEST:
      return {
        ...state,
        inviteProfessionalInProgress: true,
        inviteProfessionalError: null,
      };
    case INVITE_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        inviteProfessionalInProgress: false,
        inviteProfessionalError: null,
      };
    case INVITE_PROFESSIONAL_ERROR:
      return {
        ...state,
        inviteProfessionalInProgress: false,
        inviteProfessionalError: payload,
      };

    case CONTACT_PROFESSIONAL_REQUEST:
      return {
        ...state,
        contactProfessionalInProgress: true,
        contactProfessionalError: null,
      };
    case CONTACT_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        contactProfessionalInProgress: false,
        contactProfessionalError: null,
      };
    case CONTACT_PROFESSIONAL_ERROR:
      return {
        ...state,
        contactProfessionalInProgress: false,
        contactProfessionalError: payload,
      };

    case SEND_PROPOSAL_REQUEST:
      return {
        ...state,
        sendProposalInProgress: true,
      };
    case SEND_PROPOSAL_SUCCESS:
      return {
        ...state,
        sendProposalInProgress: false,
      };
    case SEND_PROPOSAL_ERROR:
      return {
        ...state,
        sendProposalInProgress: false,
        sendProposalError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});
export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});
export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});
export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});
export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const showListingRequest = listingId => ({
  type: SHOW_LISTING_REQUEST,
  payload: listingId,
});
export const showListingSuccess = () => ({
  type: SHOW_LISTING_SUCCESS,
});
export const showListingError = () => ({
  type: SHOW_LISTING_ERROR,
});

export const queryCurrentUserListingsRequest = () => ({
  type: QUERY_CURRENT_USER_LISTINGS_REQUEST,
});
export const queryCurrentUserListingsSuccess = listingRefs => ({
  type: QUERY_CURRENT_USER_LISTINGS_SUCCESS,
  payload: { listingRefs },
});
export const queryCurrentUserListingsError = e => ({
  type: QUERY_CURRENT_USER_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const inviteProfessionalRequest = () => ({
  type: INVITE_PROFESSIONAL_REQUEST,
});
export const inviteProfessionalSuccess = () => ({
  type: INVITE_PROFESSIONAL_SUCCESS,
});
export const inviteProfessionalError = e => ({
  type: INVITE_PROFESSIONAL_ERROR,
  payload: e,
});

export const contactProfessionalRequest = () => ({
  type: CONTACT_PROFESSIONAL_REQUEST,
});
export const contactProfessionalSuccess = () => ({
  type: CONTACT_PROFESSIONAL_SUCCESS,
});
export const contactProfessionalError = e => ({
  type: CONTACT_PROFESSIONAL_ERROR,
  error: true,
  payload: e,
});

export const sendProposalRequest = () => ({ type: SEND_PROPOSAL_REQUEST });
export const sendProposalSuccess = () => ({ type: SEND_PROPOSAL_SUCCESS });
export const sendProposalError = e => ({
  type: SEND_PROPOSAL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = params => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  dispatch(showListingRequest(listingId));

  const showListingParams = {
    id: listingId,
    include: ['author', 'images'],
    'fields.image': [
      // Listing
      'variants.landscape',
      'variants.landscape-2x',
      'variants.landscape-crop',
      'variants.landscape-crop2x',

      // Custom variants
      'variants.profile-crop',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',
    ],
    'imageVariant.profile-page': 'w:380;h:380;fit:crop',
  };

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  const isOwn = !!ownListingVariants.includes(params.variant);

  const show = isOwn
    ? sdk.ownListings.show(showListingParams)
    : sdk.listings.show(showListingParams);

  return show;
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  dispatch(queryReviewsRequest());

  const switchedReviewsIds = ['6219ac43-91c3-4082-a445-10ed57de1992'];
  const reviewType = switchedReviewsIds.includes(userId.uuid) ? 'ofProvider' : 'ofCustomer';

  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      // type: reviewType,
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());

      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const queryCurrentUserListings = () => (dispatch, getState, sdk) => {
  dispatch(queryCurrentUserListingsRequest());

  // With the setTimeout we are ensuring that currentUser is
  // already loaded
  setTimeout(() => {
    // We need to see if currentUser is available before we can
    // query his listings to avoid issues and extra API calls
    const currentUser = getState().user.currentUser;

    if (currentUser) {
      return sdk.ownListings
        .query({
          include: ['images'],
          include: ['author', 'images'],
          'fields.image': [
            // Listing
            'variants.landscape',
            'variants.landscape-2x',
            'variants.landscape-crop',
            'variants.landscape-crop2x',

            // Custom variants
            'variants.profile-crop',

            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
          ],
          'imageVariant.profile-page': 'w:380;h:380;fit:crop',
        })
        .then(response => {
          const listings = response.data.data.filter(
            l =>
              l.attributes.publicData.type === config.listingTypeProject &&
              l.attributes.state !== LISTING_STATE_CLOSED &&
              l.attributes.state !== LISTING_STATE_DRAFT
          );

          // Pick only the id and type properties from the response listings
          const listingRefs = listings.map(({ id, type }) => ({
            id,
            type,
          }));

          dispatch(addMarketplaceEntities(response));
          dispatch(queryCurrentUserListingsSuccess(listingRefs));
        })
        .catch(e => dispatch(queryCurrentUserListingsError(storableError(e))));
    }
  }, [500]);
};

export const inviteProfessional = params => (dispatch, getState, sdk) => {
  dispatch(inviteProfessionalRequest());

  return axios
    .get(inviteProfessionalAPI, { params })
    .then(response => {
      const integrationResponse = response.data;

      dispatch(inviteProfessionalSuccess());
      dispatch(addMarketplaceEntities(integrationResponse));

      return response;
    })
    .catch(e => dispatch(inviteProfessionalError(storableError(e))));
};

export const contactProfessional = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(contactProfessionalRequest());
  const bodyParams = {
    transition: TRANSITION_PRO_DISCUSSION,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;
      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(contactProfessionalSuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(contactProfessionalError(storableError(e)));
      throw e;
    });
};

export const sendProposal = params => (dispatch, getState, sdk) => {
  dispatch(sendProposalRequest());

  const { listingId, originalListingId, originalListingTitle, budget } = params;

  const bodyParams = {
    transition: TRANSITION_PROPOSAL,
    processAlias: config.bookingProcessAlias,
    params: {
      listingId,
      protectedData: {
        quote: {
          price: {
            amount: budget.amount,
            currency: budget.currency,
          },
        },
        originalListingId,
        originalListingTitle,
      },
    },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      dispatch(sendProposalSuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;
    })
    .catch(e => {
      dispatch(sendProposalError(storableError(e)));
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  return dispatch(showListing(params)).then(response => {
    const listing = denormalisedResponseEntities(response)[0];

    if (listing) {
      const userId = listing.author.id;

      // Clear state so that previously loaded data is not visible
      // in case this page load fails.
      dispatch(setInitialState());

      return Promise.all([
        dispatch(fetchCurrentUser()),
        dispatch(showUser(userId, listingId)),
        dispatch(queryUserReviews(userId)),
        dispatch(queryCurrentUserListings()),
        dispatch(addMarketplaceEntities(response)), // Dispatch to get the showed listing
      ]);
    }
  });
};
