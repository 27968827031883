import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingDatesForm } from '../../forms';
import { ALLOWED_TRANSITIONS } from '../../containers/CalendarPage/CalendarPage.duck';

import css from './EditListingDatesPanel.module.css';

const EditListingDatesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingTransaction,
  } = props;

  const canEditDates = !ALLOWED_TRANSITIONS.includes(
    listingTransaction?.attributes?.lastTransition
  );

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const dates = publicData?.dates;
  const start = dates?.start;
  const end = dates?.end;

  const startDate = start ? { date: new Date(start) } : null;
  const endDate = end ? { date: new Date(end) } : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDatesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDatesPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDatesForm
        className={css.form}
        initialValues={{ startDate, endDate }}
        saveActionMsg={submitButtonText}
        canEditDates={canEditDates}
        onSubmit={values => {
          const { startDate, endDate } = values;

          const submitValues = {
            publicData: {
              dates: {
                start: startDate.date.toString(),
                end: endDate.date.toString(),
              },
            },
          };

          onSubmit(submitValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDatesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDatesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDatesPanel;
