import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal } from '..';
import { ProposalForm } from '../../forms';

import css from './ProposalModal.module.css';

const ProposalModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    proposalInProgress,
    proposalError,
    displayName,
    ownListings,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ProposalModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ProposalModal.description" values={{ displayName }} />
      </p>
      <ProposalForm
        onSubmit={onSubmit}
        proposalInProgress={proposalInProgress}
        proposalError={proposalError}
        ownListings={ownListings}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

ProposalModal.defaultProps = {
  className: null,
  rootClassName: null,
  proposalInProgress: false,
  proposalError: null,
};

ProposalModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  proposalInProgress: bool,
  proposalError: propTypes.error,
};

export default injectIntl(ProposalModal);
