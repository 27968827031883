import { string, object } from 'prop-types';
import { compose } from 'redux';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { PROJECT_MANAGEMENT_PAGES } from '../../routeConfiguration';
import { TabNav } from '..';
import classNames from 'classnames';

import css from './ManagementSideNav.module.css';

const MANAGEMENT_PAGE = 'ManagementPage';
const ESTIMATE_PAGE = 'EstimatePage';
const INBOX_PAGE = 'InboxPage';
const BUDGET_PAGE = 'BudgetPage';
const DATES_PAGE = 'DatesPage';
const DAILY_LOGS_PAGE = 'DailyLogsPage';

const tabText = (intl, pageName) => {
  let key = null;
  if (pageName === MANAGEMENT_PAGE) {
    key = 'ManagementUserNav.manage';
  } else if (pageName === ESTIMATE_PAGE) {
    key = 'ManagementUserNav.estimate';
  } else if (pageName === INBOX_PAGE) {
    key = 'ManagementUserNav.inbox';
  } else if (pageName === BUDGET_PAGE) {
    key = 'ManagementUserNav.budget';
  } else if (pageName === DATES_PAGE) {
    key = 'ManagementUserNav.dates';
  } else if (pageName === DAILY_LOGS_PAGE) {
    key = 'ManagementUserNav.dailyLogs';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Render management listing tabs
 *
 * @param {array} listings
 * @param {string} selectedListingId
 *
 * @return {object} listing tabs
 */
const renderTabs = (intl, selectedPageName = MANAGEMENT_PAGE, pageParams, toParams) =>
  PROJECT_MANAGEMENT_PAGES.map(page => ({
    text: tabText(intl, page),
    selected: selectedPageName === page,
    linkProps: {
      name: page,
      params: pageParams,
      to: toParams,
    },
  }));

const ManagementSideNav = props => {
  const { rootClassName, className, intl, selectedPageName, pageParams, toParams } = props;

  const classes = classNames(rootClassName || css.root, className);

  const tabs = renderTabs(intl, selectedPageName, pageParams, toParams);
  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  return <div className={classes}>{nav}</div>;
};

ManagementSideNav.defaultProps = {
  rootClassName: null,
  className: null,
  selectedPageName: null,
  pageParams: null,
};

ManagementSideNav.propTypes = {
  rootClassName: string,
  className: string,
  selectedPageName: string,
  pageParams: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(ManagementSideNav);
