import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteLinkedinPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, linkedinLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.links}>
          <div className={css.organization} id="organization">
            <NamedLink name="LandingPage" className={css.logoLink}>
              <Logo format="footer" className={css.logo} />
            </NamedLink>
          </div>
          <div className={css.listWrapper}>
            <ul className={css.list}>
              <li className={css.listItem}>
                <FormattedMessage id="Footer.ourServicesTitle" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="AboutPage" className={css.link}>
                  <FormattedMessage id="Footer.toAboutPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SupportPage" className={css.link}>
                  <FormattedMessage id="Footer.toHelpPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CommunityPage" className={css.link}>
                  <FormattedMessage id="Footer.toCommunityPage" />
                </NamedLink>
              </li>
              {/* <li className={css.listItem}>
                <NamedLink name="SubscriptionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSubscriptionPage" />
                </NamedLink>
              </li> */}
              <li className={css.listItem}>
                <NamedLink name="ContactPage" className={css.link}>
                  <FormattedMessage id="Footer.toContactPage" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.listWrapper}>
            <ul className={css.list}>
              <li className={css.listItem}>
                <FormattedMessage id="Footer.supportTitle" />
              </li>

              <li className={css.listItem}>
                <NamedLink
                  name="SupportPage"
                  params={{ slug: 'project-tips' }}
                  className={css.link}
                >
                  <FormattedMessage id="Footer.projectTips" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="SupportPage"
                  params={{ slug: 'professional-tips' }}
                  className={css.link}
                >
                  <FormattedMessage id="Footer.professionalsTips" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SupportPage" params={{ slug: 'bookings' }} className={css.link}>
                  <FormattedMessage id="Footer.bookings" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.listWrapper}>
            <ul className={css.list}>
              <li className={css.listItem}>
                <FormattedMessage id="Footer.privacyTitle" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={css.bottomWrapper}>
          <div className={css.copyright}>
            <FormattedMessage id="Footer.copyright" />
          </div>
          <div className={css.copyrightMobile}>
            <FormattedMessage id="Footer.copyrightMobile" />
          </div>
          <div className={css.socialLinks}>{socialMediaLinks}</div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
