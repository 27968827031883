import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from './equipped-logo.png';
import FooterImage from './equipped-footer-logo.png';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  const classes = classNames(css.logo, className);

  const imageSrc = format === 'footer' ? FooterImage : LogoImage;
  return <img className={classes} src={imageSrc} alt={config.siteTitle} {...rest} />;
};

const { string } = PropTypes;

Logo.defaultProps = {
  className: null,
};

Logo.propTypes = {
  className: string,
};

export default Logo;
