import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, PROJECT_MANAGEMENT_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { isUserPro } from '../../util/data';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconArrowHead,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    topbarDesktopClassName,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isScrollAtTop,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
    searchProjects,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const currentProfessionalUserProfile = currentUser?.attributes?.profile?.publicData?.profile;
  const showEditProfessionalPageLink = currentUserHasProfile && currentProfessionalUserProfile;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isProjectManagementPage =
      page === 'ManagementPage' && PROJECT_MANAGEMENT_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null || isProjectManagementPage
      ? css.currentPage
      : null;
  };

  const classes = classNames(rootClassName || css.root, className, topbarDesktopClassName, {
    [css.topbarLandingPage]: currentPageClass('LandingPage'),
    [css.topbarScroll]: !isScrollAtTop,
  });

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={classNames(css.topbarSearchWithLeftMargin, css.topbarSearch)}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      searchProjects={searchProjects}
    />
  );

  const findProjectsLink = (
    <NamedLink
      className={classNames(css.navigationLink, {
        [css.navigationLinkActive]: currentPageClass('SearchPage'),
      })}
      name="SearchPage"
    >
      <FormattedMessage id="TopbarDesktop.findProjects" />
    </NamedLink>
  );
  const findProfessionalsLink = (
    <NamedLink
      className={classNames(css.navigationLink, css.navigationLinkProfessionals, {
        [css.navigationLinkActive]: currentPageClass('SearchProfessionalsPage'),
      })}
      name="SearchProfessionalsPage"
    >
      <FormattedMessage id="TopbarDesktop.findProfessionals" />
    </NamedLink>
  );
  const createProjectLink = (
    <NamedLink
      className={classNames(css.navigationLink, {
        [css.navigationLinkActive]: currentPageClass('NewCreateListingPage'),
      })}
      name="NewCreateListingPage"
    >
      <FormattedMessage id="TopbarDesktop.createProjectPage" />
    </NamedLink>
  );
  const projectManagementLink = (
    <NamedLink
      className={classNames(css.navigationLink, {
        [css.navigationLinkActive]: currentPageClass('ManagementPage'),
      })}
      name="ManagementPage"
    >
      <FormattedMessage id="TopbarDesktop.projectManagement" />
    </NamedLink>
  );

  const companyMenu = (
    <Menu rootClassName={css.navigationMenu}>
      <MenuLabel
        className={classNames(css.navigationLink, css.navigationMenuLabel)}
        isOpenClassName={css.navigationMenuIsOpen}
      >
        <FormattedMessage id="TopbarDesktop.company" />
        <IconArrowHead className={css.avatarArrow} direction="down" />
      </MenuLabel>
      <MenuContent className={css.navigationMenuContent}>
        <MenuItem key="AboutPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AboutPage'))}
            name="AboutPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.whyEquipped" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SubscriptionPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('SubscriptionPage'))}
            name="SubscriptionPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.pricing" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SupportPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('SupportPage'))}
            name="SupportPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.support" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ContactPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ContactPage'))}
            name="ContactPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.contact" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <IconArrowHead className={css.avatarArrow} direction="down" />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InvitationsPage" isDisabled={isCurrentUserClient}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InvitationsPage'))}
            name="InvitationsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.invitations" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPageProfessional" isDisabled={isCurrentUserClient}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPageProfessional'))}
            name="InboxPageProfessional"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.quotes" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="CalendarPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('CalendarPage'))}
            name="CalendarPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.calendar" />
          </NamedLink>
        </MenuItem>
        <MenuItem
          key={currentUserHasProfile ? 'EditProfessionalPage' : 'NewProfessionalPage'}
          isDisabled={isCurrentUserClient}
        >
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass(
                showEditProfessionalPageLink ? 'EditProfessionalPage' : 'NewProfessionalPage'
              )
            )}
            name={showEditProfessionalPageLink ? 'EditProfessionalPage' : 'NewProfessionalPage'}
            params={
              showEditProfessionalPageLink
                ? {
                    id: currentProfessionalUserProfile?.id,
                    slug: currentProfessionalUserProfile?.slug || '',
                    type:
                      currentProfessionalUserProfile?.type === 'published'
                        ? 'edit'
                        : currentProfessionalUserProfile?.type || 'draft',
                    tab: 'description',
                  }
                : {}
            }
          >
            <span className={css.menuItemBorder} />
            {showEditProfessionalPageLink ? (
              <FormattedMessage id="TopbarDesktop.editProfessionalPage" />
            ) : (
              <FormattedMessage id="TopbarDesktop.createProfessionalPage" />
            )}
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage" isDisabled={isCurrentUserProfessional}>
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={classNames(css.navigationLink, css.navigationButton)}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      className={classNames(css.navigationLink, {
        [css.navigationLinkActive]: currentPageClass('LoginPage'),
      })}
      name="LoginPage"
    >
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.topbarWrapper}>
        <div className={css.logoAndSearchContainer}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
          {search}
        </div>
        <div className={css.navigation}>
          {companyMenu}
          {findProfessionalsLink}
          {findProjectsLink}
          {currentUserHasListings ? projectManagementLink : createProjectLink}
          {profileMenu}
          {loginLink}
          {signupLink}
        </div>
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
