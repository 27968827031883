import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './AddDocuments.module.css';

const RemoveDocumentButton = props => {
  const { className, rootClassName, link } = props;
  const classes = classNames(rootClassName || css.viewDocument, className);
  return (
    <ExternalLink className={classes} href={link}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="10" height="10">
        <path
          style={{
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m.5 13.5 13-13M8.5.5h5v5"
          transform="scale(.71429)"
        />
      </svg>
    </ExternalLink>
  );
};

RemoveDocumentButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

RemoveDocumentButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default RemoveDocumentButton;
