import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { ensureCurrentUser, isUserCompany } from '../../util/data';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, validLink } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldSelect,
  Avatar,
  ImageFromFile,
  IconSpinner,
  NamedLink,
} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { isUploadImageOverLimitError } from '../../util/errors';

import config from '../../config';
import css from './EditProfessionalDescriptionForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const NAME_MAX_LENGTH = 30;

const phoneNumberOptions = [
  { key: 'private', label: 'Private' },
  { key: 'public', label: 'Public' },
];

class EditProfessionalDescriptionFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            currentUser,
            className,
            disabled,
            ready,
            handleSubmit,
            values,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            onImageUpload,
            profileImage,
            updateProfileError,
            updateImageInProgress,
            uploadInProgress,
            uploadImageError,
            form,
            filterConfig,
          } = formRenderProps;

          const user = ensureCurrentUser(currentUser);
          const isCompanyUser = isUserCompany(user);
          const stuffMemberOptions = config.custom.staff;

          const firstNameMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.firstName',
          });
          const firstNamePlaceholderMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.firstNameRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'EditProfessionalDescriptionForm.maxLength' },
            {
              maxLength: NAME_MAX_LENGTH,
            }
          );

          const lastNameMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.lastName',
          });
          const lastNamePlaceholderMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.lastNameRequired',
          });

          const companyNameLabel = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.companyNameLabel',
          });
          const companyNamePlaceholder = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.companyNamePlaceholder',
          });
          const companyNameRequiredMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.companyNameRequired',
          });
          const companyNameRequired = required(companyNameRequiredMessage);

          const descriptionMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.description',
          });
          const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.descriptionPlaceholder',
          });
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.descriptionRequired',
          });

          const operatorMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.operator',
          });

          const staffMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.staff',
          });

          const contractorLicenceMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.contractorLicence',
          });
          const contractorLicencePlaceholder = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.contractorLicencePlaceholder',
          });

          const crewMembersMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.crewMembers',
          });
          const crewMembersPlaceholderMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.crewMembersPlaceholder',
          });

          const phoneNumberMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.phoneNumber',
          });
          const phoneNumberPlaceholder = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.phoneNumberPlaceholder',
          });
          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.phoneNumberRequired',
          });
          const phoneNumberRequired = required(phoneNumberRequiredMessage);

          const websiteMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.website',
          });
          const websitePlaceholderMessage = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.websitePlaceholder',
          });
          const websiteValidLink = intl.formatMessage({
            id: 'EditProfessionalDescriptionForm.websiteValidLink',
          });

          const maxLength30Message = maxLength(maxLengthMessage, NAME_MAX_LENGTH);

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProfessionalDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditProfessionalWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProfessionalDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProfessionalDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          // Submit profile error
          const submitCurrentUserDataError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress || uploadInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || uploadInProgress || updateImageInProgress;

          // UPLOAD PROFILE IMAGE
          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage?.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage?.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage?.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage?.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage?.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage?.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage?.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="EditProfessionalDescriptionForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="EditProfessionalDescriptionForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="EditProfessionalDescriptionForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const operatorOptions = findOptionsForSelectFilter('operator', filterConfig);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              {submitCurrentUserDataError}

              <div className={css.profileImage}>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="EditProfessionalDescriptionForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="EditProfessionalDescriptionForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
              </div>

              {isCompanyUser ? (
                <FieldTextInput
                  className={css.companyName}
                  type="text"
                  id="companyName"
                  name="companyName"
                  label={companyNameLabel}
                  placeholder={companyNamePlaceholder}
                  validate={companyNameRequired}
                />
              ) : null}

              <div className={css.name}>
                <FieldTextInput
                  id="firstName"
                  name="firstName"
                  className={css.firstName}
                  type="text"
                  label={firstNameMessage}
                  placeholder={firstNamePlaceholderMessage}
                  maxLength={NAME_MAX_LENGTH}
                  validate={composeValidators(
                    required(firstNameRequiredMessage),
                    maxLength30Message
                  )}
                />
                <FieldTextInput
                  id="lastName"
                  name="lastName"
                  className={css.lastName}
                  type="text"
                  label={lastNameMessage}
                  placeholder={lastNamePlaceholderMessage}
                  maxLength={NAME_MAX_LENGTH}
                  validate={composeValidators(
                    required(lastNameRequiredMessage),
                    maxLength30Message
                  )}
                />
              </div>

              {isCompanyUser ? null : (
                <FieldTextInput
                  className={css.companyName}
                  type="text"
                  id="companyName"
                  name="companyName"
                  label={companyNameLabel}
                  placeholder={companyNamePlaceholder}
                  validate={companyNameRequired}
                />
              )}

              <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />

              <div className={css.operator}>
                <label className={css.operatorLabel}>{operatorMessage}</label>
                {operatorOptions.map(o => {
                  return (
                    <FieldCheckbox
                      key={o.key}
                      id="operator"
                      name="operator"
                      label={o.label}
                      value={o.key}
                    />
                  );
                })}
              </div>

              {isCompanyUser ? (
                <FieldCheckboxGroup
                  className={css.features}
                  textClassName={css.featuresTextClass}
                  id="staff"
                  name="staff"
                  label={staffMessage}
                  options={stuffMemberOptions}
                  twoColumns={true}
                />
              ) : null}

              <div className={css.phoneNumber}>
                <div className={css.phoneNumberHeader}>
                  <label>{phoneNumberMessage}</label>
                  <NamedLink className={css.updatePhoneLink} name="ContactDetailsPage">
                    <FormattedMessage id="EditProfessionalDescriptionForm.updatePhone" />
                  </NamedLink>
                </div>
                <FieldSelect name="phoneNumber" id="phoneNumber" validate={phoneNumberRequired}>
                  <option disabled value="">
                    {phoneNumberPlaceholder}
                  </option>
                  {phoneNumberOptions.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>

              <FieldTextInput
                id="contractorLicence"
                name="contractorLicence"
                className={css.contractor}
                type="text"
                label={contractorLicenceMessage}
                placeholder={contractorLicencePlaceholder}
              />

              <FieldTextInput
                className={css.crew}
                id="crewMembers"
                name="crewMembers"
                type="number"
                label={crewMembersMessage}
                placeholder={crewMembersPlaceholderMessage}
                min="0"
              />

              <FieldTextInput
                id="website"
                name="website"
                type="text"
                label={websiteMessage}
                placeholder={websitePlaceholderMessage}
                validate={validLink(websiteValidLink)}
              />

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditProfessionalDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditProfessionalDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  projectTypes: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditProfessionalDescriptionFormComponent);
