import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  InlineTextButton,
  IconAdd,
  IconHelp,
  IconClose,
} from '../../components';

import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import ReactTooltip from 'react-tooltip';

import css from './EditProfessionalLocationForm.module.css';

const identity = v => v;

// Service location
const SERVICE_LOCATIONS_NAME = 'serviceLocations';
const SERVICE_LOCATIONS_MAX_FIELDS = 10;
const ENABLE_SERVICE_LOCATION_SEARCHES = true; // If enabled search will only return city, place

export const EditProfessionalLocationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
      } = formRenderProps;

      const {
        mutators: { push },
      } = form;

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditProfessionalLocationForm.address',
      });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditProfessionalLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditProfessionalLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditProfessionalLocationForm.addressNotRecognized',
      });

      const serviceLocationMessage = intl.formatMessage({
        id: 'EditProfessionalLocationForm.serviceLocation',
      });
      const serviceLocationPlaceholder = intl.formatMessage({
        id: 'EditProfessionalLocationForm.serviceLocationPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditProfessionalLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditProfessionalLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const serviceLocationButtonDisabled =
        values.serviceLocations && values.serviceLocations.length >= SERVICE_LOCATIONS_MAX_FIELDS;

      const serviceLocationsLength = values.serviceLocations?.length;
      const serviceLocationsCount = (
        <span className={css.serviceLocationsCount}>{`(${
          serviceLocationsLength ? serviceLocationsLength : '0'
        } / ${SERVICE_LOCATIONS_MAX_FIELDS})`}</span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            includePostcode={true}
          />

          <div className={css.serviceLocations}>
            <h3 className={css.serviceLocationsTitle}>
              <FormattedMessage
                id="EditProfessionalLocationForm.serviceLocationsTitle"
                values={{
                  count: serviceLocationsCount,
                }}
              />
              <span
                className={css.tooltipContainer}
                data-tip={intl.formatMessage({
                  id: 'EditProfessionalLocationForm.serviceLocationsTooltip',
                })}
              >
                <IconHelp className={css.tooltipIcon} />
                <ReactTooltip className={css.tooltip} />
              </span>
            </h3>
            <div className={css.serviceLocationsFields}>
              <FieldArray name={SERVICE_LOCATIONS_NAME}>
                {({ fields }) => {
                  return fields.map((field, index) => {
                    if (index < SERVICE_LOCATIONS_MAX_FIELDS) {
                      const fieldValue = values.serviceLocations[index]?.location;
                      return (
                        <div key={`${field}.${index}`} className={css.serviceLocationField}>
                          <label className={css.serviceLocationLabel}>
                            {serviceLocationMessage}
                            <div
                              className={css.removeIconContainer}
                              onClick={() => fields.remove(index)}
                            >
                              <IconClose className={css.removeIcon} />
                            </div>
                          </label>
                          <LocationAutocompleteInputField
                            rootClassName={css.serviceLocation}
                            inputClassName={css.locationAutocompleteInput}
                            iconClassName={css.locationAutocompleteInputIcon}
                            predictionsClassName={css.predictionsRoot}
                            validClassName={css.validLocation}
                            id={`${field}.location`}
                            name={`${field}.location`}
                            autoFocus
                            placeholder={serviceLocationPlaceholder}
                            useDefaultPredictions={false}
                            format={identity}
                            valueFromForm={fieldValue}
                            validate={composeValidators(
                              autocompleteSearchRequired(addressRequiredMessage),
                              autocompletePlaceSelected(addressNotRecognizedMessage)
                            )}
                            serviceLocationSearch={ENABLE_SERVICE_LOCATION_SEARCHES}
                          />
                        </div>
                      );
                    }

                    return null;
                  });
                }}
              </FieldArray>
              <div className={css.addServiceLocationButtonContainer}>
                <InlineTextButton
                  className={classNames(css.addServiceLocationButton, {
                    [css.addServiceLocationButtonDisabled]: serviceLocationButtonDisabled,
                  })}
                  disabled={serviceLocationButtonDisabled}
                  type="button"
                  onClick={() => {
                    push('serviceLocations', undefined);
                  }}
                >
                  <IconAdd className={css.addServiceLocationIcon} />
                  <FormattedMessage id="EditProfessionalLocationForm.addServiceLocation" />
                </InlineTextButton>
              </div>
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditProfessionalLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditProfessionalLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditProfessionalLocationFormComponent);
