import React from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { Modal } from '..';
import { CounterOfferForm } from '../../forms';

import css from './CounterOfferModal.module.css';

// Show dispute form
const CounterOfferInfo = props => (
  <>
    <p className={css.modalTitle}>
      <FormattedMessage id="CounterOfferModal.title" />
    </p>
    <p className={css.modalMessage}>
      <FormattedMessage id="CounterOfferModal.description" />
    </p>
    <CounterOfferForm
      onSubmit={props.onSubmitCounterOffer}
      counterOfferSubmitted={props.counterOfferSubmitted}
      inProgress={props.counterOfferInProgress}
      counterOfferError={props.counterOfferError}
    />
  </>
);

// Show info that dispute form has been sent already.
const CounterOfferSentInfo = () => (
  <>
    <p className={css.modalTitle}>
      <FormattedMessage id="CounterOfferModal.sentTitle" />
    </p>
    <p className={css.modalMessage}>
      <FormattedMessage id="CounterOfferModal.sentMessage" />
    </p>
    <p className={css.modalMessage}>
      <FormattedMessage id="CounterOfferModal.sentNextStep" />
    </p>
  </>
);

// Dispute modal
const CounterOfferModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitCounterOffer,
    counterOfferSubmitted,
    counterOfferInProgress,
    counterOfferError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={intl.formatMessage({ id: 'CounterOfferModal.close' })}
    >
      {counterOfferSubmitted ? (
        <CounterOfferSentInfo />
      ) : (
        <CounterOfferInfo
          onSubmitCounterOffer={onSubmitCounterOffer}
          counterOfferSubmitted={counterOfferSubmitted}
          counterOfferInProgress={counterOfferInProgress}
          counterOfferError={counterOfferError}
        />
      )}
    </Modal>
  );
};

CounterOfferModal.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: false,
  counterOfferSubmitted: false,
  counterOfferInProgress: false,
  counterOfferError: null,
};

CounterOfferModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string.isRequired,
  isOpen: bool,
  intl: intlShape.isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmitCounterOffer: func.isRequired,
  counterOfferSubmitted: bool,
  counterOfferInProgress: bool,
  counterOfferError: propTypes.error,
};

export default injectIntl(CounterOfferModal);
