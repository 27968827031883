import React from 'react';
import PropTypes from 'prop-types';

const PhoneIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="22"
      width="22"
      viewBox="0 0 24 24"
    >
      <path
        d="M8.76 13a3.19 3.19 0 0 0 4-.44l.45-.44a1.08 1.08 0 0 0 0-1.51L11.3 8.72a1.07 1.07 0 0 0-1.5 0h0a1.08 1.08 0 0 1-1.51 0l-3-3a1.06 1.06 0 0 1 0-1.51h0a1.07 1.07 0 0 0 0-1.5L3.39.81a1.08 1.08 0 0 0-1.51 0l-.44.45a3.19 3.19 0 0 0-.44 4A28.94 28.94 0 0 0 8.76 13Z"
        style={{
          fill: 'none',
          stroke: '#656464',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
    </svg>
  );
};

PhoneIcon.defaultProps = { className: null };

const { string } = PropTypes;

PhoneIcon.propTypes = { className: string };

export default PhoneIcon;
