import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.

const defaultLocations = [
  {
    id: 'default-sammamish',
    predictionPlace: {
      address: 'Sammamish, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.657488996, -121.953189004),
        new LatLng(47.558957, -122.098155936)
      ),
    },
  },
  {
    id: 'default-redmond',
    predictionPlace: {
      address: 'Redmond, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.733619903, -121.949624507),
        new LatLng(47.594575412, -122.164707976)
      ),
    },
  },
  {
    id: 'default-bellevue',
    predictionPlace: {
      address: 'Bellevue, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.660781232, -122.079299118),
        new LatLng(47.532810052, -122.228002996)
      ),
    },
  },
  {
    id: 'default-seattle',
    predictionPlace: {
      address: 'Seattle, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.778803038, -122.218864003),
        new LatLng(47.350685958, -122.435900266)
      ),
    },
  },
  {
    id: 'default-mapple-valley',
    predictionPlace: {
      address: 'Mapple Valley, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.50460198, -121.805925028),
        new LatLng(47.340166025, -122.084915956)
      ),
    },
  },
];
export default defaultLocations;
