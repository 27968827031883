import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { Button, ModalInMobile, IconWarning } from '../../components';
import { QuoteForm } from '../../forms';
import { types as sdkTypes} from '../../util/sdkLoader';

import css from './BookingPanel.module.css';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = price instanceof Money ? formatMoney(intl, price) : null;
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ ...parse(search), book: true })}`;
  history.push(`${pathname}${searchString}`, state);
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    onSubmit,
    listing,
    title,
    isOwnListing,
    intl,
    onManageDisableScrolling,
    location,
    history,
    sendEnquiryInProgress,
    sendEnquiryError,
    quoteFormId,
    onContactUser,
    unknownPrice,
    isCurrentUserClient,
    isCurrentUserProfessional,
    isProfessionalProfileSetup,
    showContactButton,
    currentUser,
    currentAuthor,
  } = props;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const { formattedPrice } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const isAuthorProfessional =
    currentAuthor?.attributes?.profile?.publicData?.userType === 'professional';

  const currentUserClientErrorBox =
    !isOwnListing && isCurrentUserClient ? (
      <div className={css.bookingPanelError}>
        <IconWarning className={css.bookingPanelErrorIcon} />
        <p className={css.bookingPanelErrorText}>
          <FormattedMessage id="BookingPanel.currentUserClientError" />
        </p>
      </div>
    ) : null;

  const professionalProfileSetupErrorBox =
    isCurrentUserProfessional && !isOwnListing && !isProfessionalProfileSetup ? (
      <div className={css.bookingPanelError}>
        <IconWarning className={css.bookingPanelErrorIcon} />
        <p className={css.bookingPanelErrorText}>
          <FormattedMessage id="BookingPanel.professionalProfileSetupError" />
        </p>
      </div>
    ) : null;

  const ownListingErrorBox = isOwnListing ? (
    <div className={css.bookingPanelError}>
      <IconWarning className={css.bookingPanelErrorIcon} />
      <p className={css.bookingPanelErrorText}>
        <FormattedMessage id="BookingPanel.ownListingError" />
      </p>
    </div>
  ) : null;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        modalContentClassName={css.modalContent}
        id="QuoteFormModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {currentUserClientErrorBox}
        {professionalProfileSetupErrorBox}
        {ownListingErrorBox}
        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          <span className={css.budget}>
            <FormattedMessage
              id="BookingPanel.budget"
              values={{
                budgetPrice: (
                  <span className={css.budgetPrice}>
                    {unknownPrice ? 'Open Bid' : formattedPrice}
                  </span>
                ),
              }}
            />
          </span>
          <QuoteForm
            formId={quoteFormId}
            className={css.quoteForm}
            onSubmit={onSubmit}
            formattedPrice={formattedPrice}
            isOwnListing={isOwnListing}
            inProgress={sendEnquiryInProgress}
            sendQuoteError={sendEnquiryError}
            isClosed={isClosed}
            isAuthorProfessional={isAuthorProfessional}
            isCurrentUserClient={isCurrentUserClient}
            isProfessionalProfileSetup={isProfessionalProfileSetup}
            currentUser={currentUser}
          />
        </div>
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {showContactButton ? (
          <Button rootClassName={css.bookButton} onClick={onContactUser}>
            <FormattedMessage id="BookingPanel.contact" />
          </Button>
        ) : null}
        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : (
          <Button rootClassName={css.bookButton} onClick={() => openBookModal(history, location)}>
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        )}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
