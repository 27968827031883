import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal, InviteProfessional } from '..';

import css from './InviteProfessionalModal.module.css';

const InviteProfessionalModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    inviteInProgress,
    inviteError,
    displayName,
    ownListings,
    userInvitations,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'InviteProfessionalModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="InviteProfessionalModal.title" values={{ displayName }} />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="InviteProfessionalModal.description" />
      </p>
      <InviteProfessional
        onSubmit={onSubmit}
        inviteInProgress={inviteInProgress}
        inviteError={inviteError}
        ownListings={ownListings}
        userInvitations={userInvitations}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

InviteProfessionalModal.defaultProps = {
  className: null,
  rootClassName: null,
  inviteInProgress: false,
  inviteError: null,
};

InviteProfessionalModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  inviteInProgress: bool,
  inviteError: propTypes.error,
};

export default injectIntl(InviteProfessionalModal);
