import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

const SaleCounterOfferActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptCounterOfferInProgress,
    declineCounterOfferInProgress,
    acceptCounterOfferError,
    declineCounterOfferError,
    onAcceptCounterOffer,
    onDeclineCounterOffer,
  } = props;

  const buttonsDisabled = acceptCounterOfferInProgress || declineCounterOfferInProgress;

  const acceptErrorMessage = acceptCounterOfferError ? (
    <p className={css.counterOfferActionError}>
      <FormattedMessage id="TransactionPanel.acceptCounterOfferFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineCounterOfferError ? (
    <p className={css.counterOfferActionError}>
      <FormattedMessage id="TransactionPanel.declineCounterOfferFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.counterOfferActionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.counterOfferActionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.counterOfferActionButtonWrapper}>
        <SecondaryButton
          inProgress={declineCounterOfferInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineCounterOffer}
        >
          <FormattedMessage id="TransactionPanel.declineCounterOfferButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptCounterOfferInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptCounterOffer}
        >
          <FormattedMessage id="TransactionPanel.acceptCounterOfferButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleCounterOfferActionButtonsMaybe;
