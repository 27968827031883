import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconSpinner } from '../../components';
import classNames from 'classnames';

import css from './Invitation.module.css';

const ConversationActionButton = props => {
  const { onStartConversation, startConversationInProgress, invitationTxId } = props;
  const classes = classNames(css.conversationActionButton, {
    [css.disableConversationActionButton]: startConversationInProgress,
  });
  return invitationTxId ? (
    <NamedLink name="OrderDetailsPage" className={classes} params={{ id: invitationTxId }}>
      <FormattedMessage id="ConversationActionButton.seeTx" />
    </NamedLink>
  ) : (
    <div className={classes} onClick={onStartConversation}>
      {startConversationInProgress ? <IconSpinner className={css.spinnerIcon} /> : null}
      <FormattedMessage id="ConversationActionButton.start" />
    </div>
  );
};

export default ConversationActionButton;
