import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ProfileCard, NamedLink } from '..';
import classNames from 'classnames';

import css from './SectionProfessionals.module.css';

const SectionProfessionals = props => {
  const { rootClassName, className, listings, queryListingsInProgress, queryListingsError } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.subTitle}>
        <FormattedMessage id="SectionProfessionals.subTitle" />
      </div>
      <div className={css.title}>
        <FormattedMessage id="SectionProfessionals.title" />
      </div>
      <p className={css.text}>
        <FormattedMessage id="SectionProfessionals.text" />
      </p>
      <div className={css.cards}>
        {listings.map(l => {
          return <ProfileCard key={l.id.uuid} className={css.card} listing={l} />;
        })}
      </div>
      <div className={css.bottomWrapper}>
        <NamedLink name="SearchProfessionalsPage" className={css.bottomLink}>
          <FormattedMessage id="SectionProfessionals.bottomLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionProfessionals.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionProfessionals.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionProfessionals;
