import React from 'react';
import { string, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { ensureListing, ensureUser, checkSelected } from '../../util/data';
import config from '../../config';
import { NamedLink, AvatarLarge, Button } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { richText } from '../../util/richText';
import classNames from 'classnames';
import TruncateMarkup from 'react-truncate-markup';

import css from './ProfileCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const ProfileCardComponent = props => {
  const { className, rootClassName, intl, listing, setActiveListing, history } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const author = ensureUser(listing.author);

  const userCompany = currentListing?.attributes?.publicData?.company;
  const userSkills = currentListing?.attributes?.publicData?.skills;
  const userVerifications = currentListing?.attributes?.publicData?.documents?.length > 0;

  const skills = findOptionsForSelectFilter('project-skills', config.custom.filters);
  const selectedSkills = checkSelected(skills, userSkills);

  // Get count of skills that are not loaded
  const skillsNotLoaded = value => {
    return intl.formatMessage(
      {
        id: 'ProfileCard.skillsNotLoaded',
      },
      {
        count: skills?.length - value,
      }
    );
  };

  return (
    <NamedLink className={classes} name="ProfessionalPage" params={{ id }}>
      <div
        className={css.wrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <AvatarLarge
          className={classNames(css.avatar, {
            [css.avatarWithRing]: !!userVerifications,
          })}
          user={author}
          disableProfileLink
        />
        <div className={css.displayNameContainer}>
          <h3 className={css.displayName}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </h3>
          {userCompany ? <span className={css.company}>{userCompany}</span> : null}
        </div>
        <TruncateMarkup
          lines={3}
          ellipsis={value => (
            <div className={css.skill}>{skillsNotLoaded(value.props.children.length)}</div>
          )}
        >
          <div className={css.skills}>
            {selectedSkills.map(skill => {
              return (
                <TruncateMarkup.Atom key={skill.key}>
                  <span className={css.skill}>{skill.label}</span>
                </TruncateMarkup.Atom>
              );
            })}
          </div>
        </TruncateMarkup>
        <Button
          className={css.learnMoreButton}
          onClick={() =>
            history.push(
              createResourceLocatorString('ProfessionalPage', routeConfiguration(), { id })
            )
          }
        >
          <FormattedMessage id="ProfileCard.learnMoreButtonText" />
        </Button>
      </div>
    </NamedLink>
  );
};

ProfileCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  setActiveListing: () => null,
};

ProfileCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  setActiveListing: func,
};

export default injectIntl(ProfileCardComponent);
