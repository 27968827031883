import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const JobQualityIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <path
          d="M9.5 1.5H11a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h1.5"
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <rect
          x={4.5}
          y={0.5}
          width={5}
          height={2.5}
          rx={1}
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m4.5 8.5 2 1.5L9 6"
        />
      </g>
    </svg>
  );
};

JobQualityIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

JobQualityIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default JobQualityIcon;
