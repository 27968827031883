import React from 'react';
import { string } from 'prop-types';

import css from './SectionClientFeatures.module.css';

const SecurePaymentIcon = props => {
  const { className } = props;
  return (
    <div className={css.iconWrapper}>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="39"
        viewBox="0 0 100 100"
        xmlSpace="preserve"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M83.332 10H16.667C13 10 10 13.001 10 16.667v53.332c0 3.665 3 6.667 6.667 6.667h29.999v6.666H33.333v6.667h33.333v-6.667H53.334v-6.666h29.998c3.666 0 6.668-3.002 6.668-6.667V16.667C90 13.001 86.998 10 83.332 10zM43.333 50.286l-4.713 4.713-6.953-6.953-6.953 6.953L20 50.286l6.953-6.953L20 36.38l4.713-4.713 6.953 6.953 6.953-6.953 4.713 4.713-6.953 6.953zm19.999 3.736-9.998-10 4.713-4.713 5.285 5.283 11.953-11.95L80 37.356z"
          data-original="#000000"
        />
      </svg>
    </div>
  );
};

SecurePaymentIcon.defaultProps = { className: null };

SecurePaymentIcon.propTypes = {
  className: string,
};

export default SecurePaymentIcon;
