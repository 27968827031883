import React, { Component } from 'react';
import { arrayOf, func, node, object, shape, string } from 'prop-types';
import { Button } from '../../components';
import classNames from 'classnames';

import css from './SelectButtonFilterPopup.module.css';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectButtonFilter extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen });
  }

  selectOption(queryParamName, option) {
    this.props.onSelect({ [queryParamName]: option });
  }

  componentDidMount() {
    const { queryParamNames, currentQueryParams } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const isFilterActive = currentQueryParams[queryParamName];

    if (isFilterActive) {
      this.onToggleActive(true);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      currentQueryParams,
      initialValues,
    } = this.props;

    const option = options[0];
    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;
    const isFilterActive = currentQueryParams[queryParamName];

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(option, initialValue) : label;
    // const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);
    const buttonClasses = classNames(css.filterButton, {
      [css.filterButtonActive]: isFilterActive,
    });

    return (
      <div className={classes}>
        <Button
          className={buttonClasses}
          onClick={() => {
            if (this.state.isOpen) {
              this.selectOption(queryParamName, null);
            } else {
              this.selectOption(queryParamName, option.key);
            }
          }}
        >
          {menuLabel}
        </Button>
      </div>
    );
  }
}

SelectButtonFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
};

SelectButtonFilter.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  currentQueryParams: object.isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
};

export default SelectButtonFilter;
