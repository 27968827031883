import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const CommunicationIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <circle
          cx={3.5}
          cy={7}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={6.75}
          cy={7}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={10}
          cy={7}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M7 .5a6.5 6.5 0 0 0-5.41 10.1L.5 13.5l3.65-.66A6.5 6.5 0 1 0 7 .5Z"
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
    </svg>
  );
};

CommunicationIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

CommunicationIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommunicationIcon;
