import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ProfileLink, Button, IconHelp } from '..';
import ReactTooltip from 'react-tooltip';

import StripeBox from './StripeBox';
import css from './EditProfessionalPayoutDetailsPanel.module.css';

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

const EditProfessionalPayoutDetailsPanel = props => {
  const {
    intl,
    className,
    rootClassName,
    listing,
    tab,
    currentUserLoaded,
    currentUser,
    stripeConnectForm,
    stripeAccount,
    updateInProgress,
    onSubmit,
    submitButtonText,
  } = props;
  const [updated, setUpdated] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const stripeConnected =
    currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const isStripeConnected = stripeConnected && !requirementsMissing;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const tooltip =
    currentUserLoaded && !isStripeConnected ? (
      <span
        className={css.tooltip}
        data-tip={intl.formatMessage({ id: 'EditProfessionaPayoutDetails.toolTip' })}
      >
        <IconHelp />
        <ReactTooltip className={css.tip} place="right" />
      </span>
    ) : null;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditProfessionalPayoutDetailsPanel.title"
      values={{
        yourProfileLink: (
          <ProfileLink listing={listing} tab={tab}>
            <FormattedMessage id="EditProfessionalPayoutDetailsPanel.yourProfile" />
          </ProfileLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditProfessionalPayoutDetailsPanel.createListingTitle" />
  );

  const payoutButtonInProgress = updateInProgress;
  const payoutButtonDisabled = updateInProgress || !currentUserLoaded || !isStripeConnected;
  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>{panelTitle}</h1>
        {tooltip}
      </div>
      <StripeBox
        intl={intl}
        currentUserLoaded={currentUserLoaded}
        isStripeConnected={isStripeConnected}
      >
        {stripeConnectForm}
      </StripeBox>
      <Button
        className={css.payoutButton}
        inProgress={payoutButtonInProgress}
        disabled={payoutButtonDisabled}
        ready={updated}
        onClick={() => {
          // We currently don't pass any values on this panel
          // but we need to pass empty object so that the API
          // doesn't return an issue
          const updatedValues = {};

          setUpdated(true);
          onSubmit(updatedValues);
        }}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

EditProfessionalPayoutDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
};

EditProfessionalPayoutDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,
};

export default EditProfessionalPayoutDetailsPanel;
