import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { LinkTabNavHorizontal } from '../../components';
import classNames from 'classnames';

import css from './ManagementUserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, selectedPageName, pageParams } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="ManagementUserNav.manage" />,
      selected: selectedPageName === 'ManagementPage',
      linkProps: {
        name: 'ManagementPage',
        params: pageParams,
      },
    },
    {
      text: <FormattedMessage id="ManagementUserNav.estimate" />,
      selected: selectedPageName === 'EstimatePage',
      linkProps: {
        name: 'EstimatePage',
        params: pageParams,
      },
    },
    {
      text: <FormattedMessage id="ManagementUserNav.inbox" />,
      selected: selectedPageName === 'InboxPage',
      linkProps: {
        name: 'InboxPage',
        params: pageParams,
      },
    },
    {
      text: <FormattedMessage id="ManagementUserNav.dailyLogs" />,
      selected: selectedPageName === 'DailyLogsPage',
      linkProps: {
        name: 'DailyLogsPage',
        params: pageParams,
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string, object } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  pageParams: object.isRequired,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
  } = state.user;

  const currentProfessionalUserProfile = currentUser?.attributes?.profile?.publicData?.profile;

  return {
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
    currentProfessionalUserProfile,
  };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
