import React from 'react';
import { func, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import classNames from 'classnames';

import css from './Invitation.module.css';

const RemoveInvitationIcon = () => {
  return (
    <svg
      className={css.removeInvitationIcon}
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 16 16"
    >
      <path
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        d="m11.5 5.5-1 8h-7l-1-8M1 3.5h12M4.46 3.21V1.48a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2"
        transform="scale(1.14286)"
      />
    </svg>
  );
};

const RemoveInvitationButton = props => {
  const { onClick, isCurrentlyRemoving, removeInvitationInProgress, removeInvitationError } = props;
  const classes = classNames(css.removeInvitationContainer, {
    [css.disabledRemoveInvitation]: removeInvitationInProgress,
  });

  if (isCurrentlyRemoving && removeInvitationError) {
    return (
      <p className={css.errorMessage}>
        <FormattedMessage id="Invitation.removeInvitationError" />
      </p>
    );
  }

  const renderRemoveInvitationIcon = !isCurrentlyRemoving ? (
    <RemoveInvitationIcon />
  ) : (
    <IconSpinner className={css.spinnerIcon} />
  );
  return (
    <div className={classes} onClick={onClick}>
      {renderRemoveInvitationIcon}
      <FormattedMessage id="RemoveInvitationButton.deleteMessage" />
    </div>
  );
};

RemoveInvitationButton.defaultProps = {
  onClick: null,
  isCurrentlyRemoving: false,
  removeInvitationInProgress: false,
  removeInvitationError: null,
};

RemoveInvitationButton.propTypes = {
  onClick: func.isRequired,
  isCurrentlyRemoving: bool,
  removeInvitationInProgress: bool.isRequired,
  removeInvitationError: propTypes.error,
};

export default RemoveInvitationButton;
