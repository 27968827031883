import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowHead } from '..';

import css from './EditProfessionalPayoutDetailsPanel.module.css';

const StripeBox = props => {
  const { intl, children, currentUserLoaded, isStripeConnected } = props;
  const [showStripeForm, setShowStripeForm] = useState(false);

  useEffect(() => {
    const { currentUserLoaded } = props;

    if (currentUserLoaded && !isStripeConnected) {
      setShowStripeForm(true);
    }
  }, [isStripeConnected]);

  // intl messages
  const badgeText = isStripeConnected
    ? intl.formatMessage({
        id: 'EditProfessionalPayoutDetailsPanel.connected',
      })
    : intl.formatMessage({
        id: 'EditProfessionalPayoutDetailsPanel.notConnected',
      });

  const chevronClasses = classNames(css.stripeBoxChevron, {
    [css.stripeBoxChevronActive]: !!showStripeForm,
  });
  const badgeClasses = classNames(css.stripeBoxBadge, {
    [css.stripeBoxBadgeConnected]: isStripeConnected,
  });
  return (
    <div className={css.stripeBox}>
      <div className={css.stripeBoxHeader} onClick={() => setShowStripeForm(!showStripeForm)}>
        <h3 className={css.stripeBoxHeading}>
          {currentUserLoaded ? (
            <>
              <FormattedMessage id="EditProfessionalPayoutDetailsPanel.stripeBoxHeading" />
              <span className={badgeClasses}>{badgeText}</span>
            </>
          ) : (
            <FormattedMessage id="EditProfessionalPayoutDetailsPanel.stripeBoxLoading" />
          )}
        </h3>
        <IconArrowHead className={chevronClasses} direction="down" />
      </div>
      {showStripeForm ? <div className={css.stripeBoxContent}>{children}</div> : null}
    </div>
  );
};

export default StripeBox;
