import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconSpinner } from '../../components';

import css from './AddDocuments.module.css';
import ViewDocumentButton from './ViewDocumentButton';
import RemoveDocumentButton from './RemoveDocumentButton';

const ThumbnailWrapper = props => {
  const {
    thumbnailClassName,
    listingId,
    document,
    onRemoveDocument,
    removeDocumentInProgress,
    removeDocumentId,
  } = props;
  const handleRemoveClick = e => {
    // Prevent form from submitting
    e.stopPropagation();
    e.preventDefault();

    // Remove the document
    onRemoveDocument(listingId, document.id);
  };

  if (document.link) {
    const isBeingRemoved = document.id === removeDocumentId;
    const removeDocumentOverlay =
      isBeingRemoved && removeDocumentInProgress ? (
        <div className={css.thumbnailLoading}>
          <IconSpinner />
        </div>
      ) : null;

    const classes = classNames(css.documentWrapper, thumbnailClassName);
    return (
      <div className={classes}>
        <div className={css.documentAspectRatio}>
          <div className={css.documentContent}>
            <span className={css.documentName}>{document.name}</span>
          </div>
        </div>
        {removeDocumentOverlay}
        <ViewDocumentButton link={document.link} />
        <RemoveDocumentButton onClick={handleRemoveClick} />
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  document: object.isRequired,
  onRemoveDocument: func,
};

const AddDocuments = props => {
  const {
    children,
    className,
    thumbnailClassName,
    listingId,
    documents,
    onRemoveDocument,
    removeDocumentInProgress,
    removeDocumentId,
  } = props;
  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {documents.map((document, index) => {
        return (
          <ThumbnailWrapper
            key={document.id}
            document={document}
            index={index}
            className={thumbnailClassName}
            listingId={listingId}
            onRemoveDocument={onRemoveDocument}
            removeDocumentInProgress={removeDocumentInProgress}
            removeDocumentId={removeDocumentId}
          />
        );
      })}
      {children}
    </div>
  );
};

AddDocuments.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddDocuments.propTypes = {
  documents: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  onRemoveDocument: func,
};

export default AddDocuments;
