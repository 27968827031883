import React from 'react';
import classNames from 'classnames';
import { EnquiryBreakdown } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build EnquiryBreakdown
const EnquiryBreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    intl,
    transaction,
    transactionQuote,
    userRole,
    bookingDates,
  } = props;
  const loaded = transaction && transaction.id && transactionQuote?.price;

  const classes = classNames(rootClassName || css.enquiryPanel, className);
  const breakdownClasses = classNames(breakdownClassName || css.enquiryBreakdown);

  return loaded ? (
    <div className={classes}>
      <EnquiryBreakdown
        className={breakdownClasses}
        intl={intl}
        userRole={userRole}
        transactionQuote={transactionQuote}
        bookingDates={bookingDates}
      />
    </div>
  ) : null;
};

export default EnquiryBreakdownMaybe;
