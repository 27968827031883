import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleCompletedButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButton,
    markCompletedInProgress,
    markCompletedError,
    onMarkCompleted,
  } = props;

  const buttonsDisabled = markCompletedInProgress;

  const completeErrorMessage = markCompletedError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.markSaleCompletedFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButton ? (
    <div className={classes}>
      {markCompletedError ? <div className={css.actionErrors}>{completeErrorMessage}</div> : null}
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          className={css.actionButton}
          inProgress={markCompletedInProgress}
          disabled={buttonsDisabled}
          onClick={onMarkCompleted}
        >
          <FormattedMessage id="TransactionPanel.markCompletedButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleCompletedButtonMaybe;
