import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDocumentsForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingDocumentsPanel.module.css';

class EditListingDocumentsPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      listing,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onUploadDocument,
      uploadDocumentInProgress,
      onRemoveDocument,
      removeDocumentInProgress,
      removeDocumentId,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingDocumentsPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingDocumentsPanel.createListingTitle" />
    );

    const documents = currentListing.attributes.publicData?.documents;

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingDocumentsForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ documents }}
          onSubmit={values => {
            const { documents } = values;
            const updateValues = {
              publicData: { documents },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          onUploadDocument={onUploadDocument}
          uploadDocumentInProgress={uploadDocumentInProgress}
          onRemoveDocument={onRemoveDocument}
          removeDocumentInProgress={removeDocumentInProgress}
          removeDocumentId={removeDocumentId}
          documents={documents}
          listing={listing}
        />
      </div>
    );
  }
}

EditListingDocumentsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDocumentsPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default EditListingDocumentsPanel;
