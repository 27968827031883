import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import config from '../../config';

const LANDING_PAGE_LISTINGS_COUNT = 4;
const LANDING_PAGE_PROJECTS_COUNT = 8;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const QUERY_PROJECTS_REQUEST = 'app/LandingPage/QUERY_PROJECTS_REQUEST';
export const QUERY_PROJECTS_SUCCESS = 'app/LandingPage/QUERY_PROJECTS_SUCCESS';
export const QUERY_PROJECTS_ERROR = 'app/LandingPage/QUERY_PROJECTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingRefs: [],
  queryListingsInProgress: false,
  queryListingsError: null,
  projectRefs: [],
  queryProjectsInProgress: false,
  queryProjectsError: null,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        listingRefs: payload.listingRefs,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listingRefs: [], queryListingsError: payload };

    case QUERY_PROJECTS_REQUEST:
      return {
        ...state,
        queryProjectsInProgress: true,
        queryProjectsError: null,
      };
    case QUERY_PROJECTS_SUCCESS:
      return {
        ...state,
        queryProjectsInProgress: false,
        projectRefs: payload.projectRefs,
      };
    case QUERY_PROJECTS_ERROR:
      return { ...state, projectRefs: [], queryProjectsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryProjectsRequest = () => ({
  type: QUERY_PROJECTS_REQUEST,
});

export const queryProjectsSuccess = projectRefs => ({
  type: QUERY_PROJECTS_SUCCESS,
  payload: { projectRefs },
});

export const queryProjectsError = e => ({
  type: QUERY_PROJECTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypeProfessional,
      pub_highlighted: true,
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      per_page: LANDING_PAGE_LISTINGS_COUNT,
    })
    .then(response => {
      const listingRefs = response.data.data.map(l => l.id);
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));

      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryProjects = () => (dispatch, getState, sdk) => {
  dispatch(queryProjectsRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypeProject,
      pub_preference: 'public',
      perPage: LANDING_PAGE_PROJECTS_COUNT,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      const projectRefs = response.data.data.map(l => l.id);
      dispatch(addMarketplaceEntities(response));
      dispatch(queryProjectsSuccess(projectRefs));

      return response;
    })
    .catch(e => dispatch(queryProjectsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(queryListings()), dispatch(queryProjects())]);
};
