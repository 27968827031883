import omit from 'lodash/omit';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { uploadDocumentAPI, removeDocumentAPI, uploadImageAPI } from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const { UUID } = sdkTypes;
const UPDATE_LISTING_DELAY = 500;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

const createListingEntities = data => {
  const generateUUID = new UUID(uuidv4());
  const listingData = {
    type: 'ownListing',
    images: [],
    id: generateUUID,
    attributes: {
      state: LISTING_STATE_DRAFT,
      ...data,
    },
  };

  return listingData;
};

const updateListingEntities = (data, listingDraft) => {
  // Fields that may apply
  const geolocationMaybe = data.geolocation ? { geolocation: data.geolocation } : {};
  const priceMaybe = data.price ? { price: data.price } : {};

  // Static fields
  const publicData = data.publicData
    ? { ...data.publicData, ...listingDraft.attributes.publicData }
    : { ...listingDraft.attributes.publicData };

  // Generate & update listing data object that
  // that will mimic real listing object
  const listingData = {
    ...listingDraft,
    attributes: {
      ...listingDraft.attributes,
      ...geolocationMaybe,
      ...priceMaybe,
      publicData,
    },
  };

  return listingData;
};

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/CreateListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/CreateListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/CreateListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/CreateListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/CreateListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/CreateListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/CreateListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/CreateListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/CreateListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/CreateListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/CreateListingPage/UPDATE_LISTING_ERROR';

export const CREATE_LISTING_REQUEST = 'app/CreateListingPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/CreateListingPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/CreateListingPage/CREATE_LISTING_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/CreateListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/CreateListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/CreateListingPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/CreateListingPage/UPDATE_IMAGE_ORDER';
export const REMOVE_LISTING_IMAGE = 'app/CreateListingPage/REMOVE_LISTING_IMAGE';

export const UPLOAD_DOCUMENT_REQUEST = 'app/CreateListingPage/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'app/CreateListingPage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/CreateListingPage/UPLOAD_DOCUMENT_ERROR';

export const REMOVE_DOCUMENT_REQUEST = 'app/CreateListingPage/REMOVE_DOCUMENT_REQUEST';
export const REMOVE_DOCUMENT_SUCCESS = 'app/CreateListingPage/REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_ERROR = 'app/CreateListingPage/REMOVE_DOCUMENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  uploadImageError: null,
  createListingDraftInProgress: false,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  uploadDocumentInProgress: false,
  uploadDocumentError: null,
  removeDocumentInProgress: false,
  removeDocumentError: null,
  removeDocumentId: null,
  createListingInProgress: true,
  createListingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
      };
    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        listingDraft: payload.listingData,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        createListingDraftError: null,
        updateListingError: null,

        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false, listingDraft: payload.listingData };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload.listingData };

    case CREATE_LISTING_REQUEST:
      return { ...state, createListingInProgress: true, createListingError: null };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
        createListingDraftError: null,
      };
    case CREATE_LISTING_ERROR:
      return { ...state, createListingInProgress: false, createListingError: payload };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case UPLOAD_DOCUMENT_REQUEST:
      return { ...state, uploadDocumentInProgress: true, uploadDocumentError: null };
    case UPLOAD_DOCUMENT_SUCCESS:
      return { ...state, uploadDocumentInProgress: false, uploadDocumentError: null };
    case UPLOAD_DOCUMENT_ERROR:
      return { ...state, uploadDocumentInProgress: false, uploadDocumentError: payload };

    case REMOVE_DOCUMENT_REQUEST:
      return {
        ...state,
        removeDocumentInProgress: true,
        removeDocumentId: payload.params.documentId,
        removeDocumentError: null,
      };
    case REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        removeDocumentInProgress: false,
        removeDocumentError: null,
      };
    case REMOVE_DOCUMENT_ERROR:
      return {
        ...state,
        removeDocumentInProgress: false,
        removeDocumentError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.create
export const createListing = requestAction(CREATE_LISTING_REQUEST);
export const createListingSuccess = successAction(CREATE_LISTING_SUCCESS);
export const createListingError = errorAction(CREATE_LISTING_ERROR);

// SDK method: images.upload (using integration SDK)
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

export const uploadDocumentRequest = requestAction(UPLOAD_DOCUMENT_REQUEST);
export const uploadDocumentSuccess = successAction(UPLOAD_DOCUMENT_SUCCESS);
export const uploadDocumentError = errorAction(UPLOAD_DOCUMENT_ERROR);

export const removeDocumentRequest = requestAction(REMOVE_DOCUMENT_REQUEST);
export const removeDocumentSuccess = successAction(REMOVE_DOCUMENT_SUCCESS);
export const removeDocumentError = errorAction(REMOVE_DOCUMENT_ERROR);

// ================ Thunk ================ //

export const requestCreateListingDraft = data => (dispatch, getState, sdk) => {
  dispatch(createListingDraft());
  const listingData = createListingEntities(data);

  const draftPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        dispatch(createListingDraftSuccess({ data: { listingData } }));

        resolve(listingData);
      } catch (e) {
        console.log(e);
        const errorResponse = storableError(e);
        dispatch(createListingDraftError(errorResponse));
        reject(errorResponse);
      }
    }, UPDATE_LISTING_DELAY);
  });

  return draftPromise;
};

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  const publishPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        dispatch(publishListing({ listingId }));
        dispatch(publishListingSuccess({ data: { listingId } }));
        resolve(listingId);
      } catch (e) {
        console.log(e);
        const errorResponse = storableError(e);
        dispatch(publishListingError(errorResponse));
        reject(errorResponse);
      }
    }, UPDATE_LISTING_DELAY);
  });

  return publishPromise;
};

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListing());

    const { listingDraft } = getState().CreateListingPage;
    const listingData = updateListingEntities(data, listingDraft);

    const updatePromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          dispatch(markTabUpdated(tab));
          dispatch(updateListingSuccess({ data: { listingData } }));
          resolve(listingData);
        } catch (e) {
          const errorResponse = storableError(e);
          dispatch(updateListingError(errorResponse));
          reject(errorResponse);
        }
      }, UPDATE_LISTING_DELAY);
    });

    return updatePromise;
  };
}

export function requestCreateListing(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListing());

    return sdk.ownListings
      .create(data)
      .then(response => {
        dispatch(createListingSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: data });
        return dispatch(createListingError(storableError(e)));
      });
  };
}

export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));

    const formData = new FormData();
    formData.append('image', actionPayload.file);
    const headers = {
      'content-type': 'multipart/form-data',
    };

    return axios
      .post(uploadImageAPI, formData, { headers })
      .then(resp => {
        const imageId = resp.data.data.data.id;
        dispatch(uploadImageSuccess({ data: { id, imageId } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const uploadDocument = (listingId, document) => async (dispatch, getState, sdk) => {
  dispatch(uploadDocumentRequest());

  const formData = new FormData();
  formData.append('document', document);
  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(uploadDocumentAPI, formData, {
      headers,
    });
    const document = response.data;

    const { listingDraft } = getState().CreateListingPage;

    let listingData = listingDraft;
    const documents = listingData.attributes.publicData.documents;
    listingData.attributes.publicData = {
      ...listingDraft.attributes.publicData,
      documents: documents ? [...documents, document] : [document],
    };

    dispatch(uploadDocumentSuccess({ data: { document } }));
    dispatch(updateListingSuccess({ data: { listingData } }));
  } catch (e) {
    dispatch(uploadDocumentError(storableError(e)));
  }
};

export const removeDocument = (listingId, documentId) => async (dispatch, getState, sdk) => {
  dispatch(removeDocumentRequest({ documentId }));

  try {
    const response = await axios(removeDocumentAPI, { params: { documentId } });
    const { listingDraft } = getState().CreateListingPage;

    let listingData = listingDraft;
    const documents = listingData.attributes.publicData.documents;
    listingData.attributes.publicData = {
      ...listingDraft.attributes.publicData,
      documents: documents.filter(d => d.id !== documentId),
    };

    dispatch(removeDocumentSuccess(response));
    dispatch(updateListingSuccess({ data: { listingData } }));
  } catch (e) {
    dispatch(removeDocumentError(storableError(e)));
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());

  return dispatch(fetchCurrentUser());
};
