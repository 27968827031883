import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { propTypes } from '../../util/types';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { Button, IconSpinner } from '../../components';
import classNames from 'classnames';

import InviteCard from './InviteCard';
import css from './InviteSingleProfessional.module.css';

const InviteSingleProfessionalComponent = props => {
  const {
    className,
    rootClassName,
    onSubmit,
    inviteError,
    inviteInProgress,
    queryProfessionalsInProgress,
    professionals,
    projectId,
    onRedirectToSearchPage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // We need to manage the state of the current invite ID
  const [currentInviteId, setCurrentInviteId] = useState(null);

  const handleInvite = profileId => {
    setCurrentInviteId(profileId);
    onSubmit(profileId);
  };

  return (
    <div className={classes}>
      {queryProfessionalsInProgress ? (
        <div className={css.loadingWrapper}>
          <IconSpinner className={css.loadingIcon} />
        </div>
      ) : (
        <div className={css.listingsWrapper}>
          {professionals.map(l => {
            return (
              <InviteCard
                key={l.id.uuid}
                listing={l}
                projectId={projectId}
                inviteInProgress={inviteInProgress}
                inviteError={inviteError}
                currentInviteId={currentInviteId}
                setCurrentInviteId={setCurrentInviteId}
                onSubmit={() => handleInvite(l.author.id.uuid)}
              />
            );
          })}
        </div>
      )}
      <div className={css.buttonWrapper}>
        <p className={css.buttonWrapperText}>
          <FormattedMessage id="InviteSingleProfessional.buttonWrapperText" />
        </p>
        <Button onClick={onRedirectToSearchPage}>
          <FormattedMessage id="InviteSingleProfessional.buttonText" />
        </Button>
      </div>
    </div>
  );
};

InviteSingleProfessionalComponent.defaultProps = {
  className: null,
  rootClassName: null,
  inviteError: null,
};

const { bool, func, string, array } = PropTypes;

InviteSingleProfessionalComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  professionals: array.isRequired,
  userVerifications: array,

  // InviteCard specific
  onSubmit: func.isRequired,
  inviteError: propTypes.error,
  inviteInProgress: bool.isRequired,
};

const InviteSingleProfessional = compose(injectIntl)(InviteSingleProfessionalComponent);
InviteSingleProfessional.displayName = 'InviteSingleProfessional';

export default InviteSingleProfessional;
