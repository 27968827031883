import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Slider from '@mui/material/Slider';

import css from './FieldSliderInput.module.css';

class FieldSliderInputComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.input.onChange(event.target.value);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      id,
      label,
      input,
      minValue,
      maxValue,
      defaultValue,
      options,
      values,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    } else if (input && !input.name) {
      throw new Error('name is required');
    }

    const inputClasses = inputRootClass || css.input;
    const inputValue = values[input.name];

    const inputProps = {
      id,
      name,
      className: inputClasses,
      defaultValue: inputValue,
      value: inputValue,
      min: 0 || minValue,
      max: 2 || maxValue,
      defaultValue: 0 || defaultValue,
      ...rest,
    };

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <div className={css.inputWrapper}>
          <Slider {...inputProps} marks={options} onChange={this.handleChange} />
        </div>
      </div>
    );
  }
}

FieldSliderInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  id: null,
  label: null,
};

FieldSliderInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
};

const FieldSliderInput = props => {
  return <Field component={FieldSliderInputComponent} {...props} />;
};

export default FieldSliderInput;
