import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckbox,
  FieldSliderInput,
  InlineTextButton,
  NamedLink,
} from '../../components';

import {
  CommunicationIcon,
  ProfessionalismIcon,
  JobQualityIcon,
  PunctualityIcon,
  CleanlinessIcon,
  MaterialQualityIcon,
} from './Icons';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

const servicePrioritiesMarks = [
  {
    value: 0,
    label: 'low priority',
  },
  {
    value: 1,
    label: 'neutral',
  },
  {
    value: 2,
    label: 'high priority',
  },
];

const materialQualityMarks = [
  {
    value: 0,
    label: 'good',
  },
  {
    value: 1,
    label: 'better',
  },
  {
    value: 2,
    label: 'best',
  },
];

export const EditListingPricingFormComponent = props => {
  const [showMissingFields, setShowMissingFields] = useState(false);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          unknownBudgetName,
        } = formRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const translationKey = isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const communicationLabel = intl.formatMessage({
          id: 'EditListingPricingForm.communicationLabel',
        });
        const professionalismLabel = intl.formatMessage({
          id: 'EditListingPricingForm.professionalismLabel',
        });
        const punctualityLabel = intl.formatMessage({
          id: 'EditListingPricingForm.punctualityLabel',
        });
        const jobQualityLabel = intl.formatMessage({
          id: 'EditListingPricingForm.jobQualityLabel',
        });
        const cleanlinessLabel = intl.formatMessage({
          id: 'EditListingPricingForm.cleanlinessLabel',
        });
        const materialQualityLabel = intl.formatMessage({
          id: 'EditListingPricingForm.materialQuality',
        });

        const unknownBudgetLabel = intl.formatMessage({
          id: 'EditListingPricingForm.unknownBudgetLabel',
        });

        const unknownPrice = values.unknownPrice;
        const isUnknownPriceSelected = unknownPrice?.includes('yes');

        const classes = classNames(css.root, className);
        const priceInputClasses = classNames(css.priceInput, {
          [css.priceInputDisabled]: !!isUnknownPriceSelected,
        });

        const price = values.price || values.priceMask;
        const isPriceValid = !!isUnknownPriceSelected || price;
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !isPriceValid;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <label>{pricePerUnitMessage}</label>
            <div className={css.priceWrapper}>
              <FieldCheckbox
                id={unknownBudgetName}
                name={unknownBudgetName}
                className={css.uknownPriceInput}
                label={unknownBudgetLabel}
                value="yes"
              />
              <span className={css.priceSeparator}>
                <FormattedMessage id="EditListingPricingForm.priceSeparator" />
              </span>
              {isUnknownPriceSelected ? (
                <FieldCurrencyInput
                  id="priceMask"
                  name="priceMask"
                  className={priceInputClasses}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              ) : (
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={priceInputClasses}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              )}
            </div>

            <div className={css.sliderFieldsWrapper}>
              <h4 className={css.sliderFieldsTitle}>
                <FormattedMessage id="EditListingPricingForm.servicePriorities" />
              </h4>
              <div className={css.sliderFields}>
                <div className={css.sliderItem}>
                  <label className={css.sliderLabel}>
                    <CommunicationIcon />
                    {communicationLabel}
                  </label>
                  <FieldSliderInput
                    id="EditListingPricingForm.communication"
                    name="communication"
                    className={css.sliderInput}
                    options={servicePrioritiesMarks}
                    values={values}
                  />
                </div>

                <div className={css.sliderItem}>
                  <label className={css.sliderLabel}>
                    <ProfessionalismIcon />
                    {professionalismLabel}
                  </label>
                  <FieldSliderInput
                    id="EditListingPricingForm.professionalism"
                    name="professionalism"
                    className={css.sliderInput}
                    options={servicePrioritiesMarks}
                    values={values}
                  />
                </div>

                <div className={css.sliderItem}>
                  <label className={css.sliderLabel}>
                    <PunctualityIcon />
                    {punctualityLabel}
                  </label>
                  <FieldSliderInput
                    id="EditListingPricingForm.punctuality"
                    name="punctuality"
                    className={css.sliderInput}
                    options={servicePrioritiesMarks}
                    values={values}
                  />
                </div>
                {!showMissingFields ? (
                  <div className={css.showMore} onClick={() => setShowMissingFields(true)}>
                    <FormattedMessage id="EditListingPricingForm.showMore" />
                  </div>
                ) : null}

                {showMissingFields ? (
                  <>
                    <div className={css.sliderItem}>
                      <label className={css.sliderLabel}>
                        <JobQualityIcon />
                        {jobQualityLabel}
                      </label>
                      <FieldSliderInput
                        id="EditListingPricingForm.jobQuality"
                        name="jobQuality"
                        className={css.sliderInput}
                        options={servicePrioritiesMarks}
                        values={values}
                      />
                    </div>

                    <div className={css.sliderItem}>
                      <label className={css.sliderLabel}>
                        <CleanlinessIcon />
                        {cleanlinessLabel}
                      </label>
                      <FieldSliderInput
                        id="EditListingPricingForm.cleanliness"
                        name="cleanliness"
                        className={css.sliderInput}
                        options={servicePrioritiesMarks}
                        values={values}
                      />
                    </div>

                    <div className={css.sliderItem}>
                      <label className={css.sliderLabel}>
                        <MaterialQualityIcon />
                        {materialQualityLabel}
                      </label>
                      <FieldSliderInput
                        id="EditListingPricingForm.materialQuality"
                        name="materialQuality"
                        className={css.sliderInput}
                        options={materialQualityMarks}
                        values={values}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
