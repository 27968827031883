import axios from 'axios';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { TRANSITIONS, TRANSITION_CLOSE_QUOTE } from '../../util/transaction';
import { queryTransactionsAPI, sendInviteEmailAPI } from '../../util/api';
import { ALLOWED_TRANSITIONS } from '../CalendarPage/CalendarPage.duck';

const TRANSACTIONS_PAGE_SIZE = 100;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const BLACKLISTED_TRANSITIONS = [TRANSITION_CLOSE_QUOTE];

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManagementPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManagementPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManagementPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManagementPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManagementPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManagementPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManagementPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManagementPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManagementPage/CLOSE_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ManagementPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ManagementPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ManagementPage/UPDATE_LISTING_ERROR';

export const FETCH_SALES_REQUEST = 'app/ManagementPage/FETCH_SALES_REQUEST';
export const FETCH_SALES_SUCCESS = 'app/ManagementPage/FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'app/ManagementPage/FETCH_SALES_ERROR';

export const INVITE_PROFESSIONAL_REQUEST = 'app/ManagementPage/INVITE_PROFESSIONAL_REQUEST';
export const INVITE_PROFESSIONAL_SUCCESS = 'app/ManagementPage/INVITE_PROFESSIONAL_SUCCESS';
export const INVITE_PROFESSIONAL_ERROR = 'app/ManagementPage/INVITE_PROFESSIONAL_ERROR';

export const FIND_LISTING_TRANSACTION_REQUEST =
  'app/ManagementPage/FIND_LISTING_TRANSACTION_REQUEST';
export const FIND_LISTING_TRANSACTION_SUCCESS =
  'app/ManagementPage/FIND_LISTING_TRANSACTION_SUCCESS';
export const FIND_LISTING_TRANSACTION_ERROR = 'app/ManagementPage/FIND_LISTING_TRANSACTION_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManagementPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchListingsInProgress: false,
  fetchListingsError: null,
  listingIds: [],
  fetchSalesInProgress: false,
  fetchSalesError: null,
  pagination: null,
  transactionRefs: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  updateListingInProgress: false,
  updateListingError: null,
  inviteProfessionalInProgress: false,
  inviteProfessionalError: null,
  listingTransaction: null,
  findListingTransactionInProgress: false,
  findListingTransactionError: null,
};

const resultIds = data =>
  data.data.filter(l => l.attributes.publicData.type === config.listingTypeProject).map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const managementPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
        listingIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listingIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateListingInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateListingInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateListingInProgress: false, updateListingError: payload };

    case FETCH_SALES_REQUEST:
      return { ...state, fetchSalesInProgress: true, fetchSalesError: null };
    case FETCH_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchSalesInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchSalesInProgress: false, fetchSalesError: payload };

    case INVITE_PROFESSIONAL_REQUEST:
      return { ...state, inviteProfessionalInProgress: true, inviteProfessionalError: null };
    case INVITE_PROFESSIONAL_SUCCESS: {
      return {
        ...state,
        inviteProfessionalInProgress: false,
        inviteProfessionalError: null,
      };
    }
    case INVITE_PROFESSIONAL_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, inviteProfessionalInProgress: false, inviteProfessionalError: payload };

    case FIND_LISTING_TRANSACTION_REQUEST:
      return {
        ...state,
        findListingTransactionInProgress: true,
        findListingTransactionError: null,
      };
    case FIND_LISTING_TRANSACTION_SUCCESS:
      return {
        ...state,
        findListingTransactionInProgress: false,
        findListingTransactionError: null,
        listingTransaction: payload,
      };

    case FIND_LISTING_TRANSACTION_ERROR:
      return {
        ...state,
        findListingTransactionInProgress: false,
        findListingTransactionError: payload,
      };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default managementPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManagementPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});
export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});
export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});
export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});
export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const updateListingRequest = () => ({
  type: UPDATE_LISTING_REQUEST,
});
export const updateListingSuccess = response => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: response.data,
});
export const updateListingError = e => ({
  type: UPDATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchSalesRequest = () => ({ type: FETCH_SALES_REQUEST });
export const fetchSalesSuccess = response => ({
  type: FETCH_SALES_SUCCESS,
  payload: response,
});
export const fetchSalesError = e => ({
  type: FETCH_SALES_ERROR,
  error: true,
  payload: e,
});

export const inviteProfessionalRequest = () => ({ type: INVITE_PROFESSIONAL_REQUEST });
export const inviteProfessionalSuccess = response => ({
  type: INVITE_PROFESSIONAL_SUCCESS,
  payload: response,
});
export const inviteProfessionalError = e => ({
  type: INVITE_PROFESSIONAL_ERROR,
  error: true,
  payload: e,
});

export const findListingTransactionRequest = () => ({ type: FIND_LISTING_TRANSACTION_REQUEST });
export const findListingTransactionSuccess = transaction => ({
  type: FIND_LISTING_TRANSACTION_SUCCESS,
  payload: transaction,
});
export const findListingTransactionError = e => ({
  type: FIND_LISTING_TRANSACTION_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const queryOwnListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  const params = {
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(fetchListingsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const updateListing = data => (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());

  return sdk.ownListings
    .update(data, { expand: true })
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(updateListingSuccess(response));
      return response;
    })
    .catch(e => {
      log.error(e, 'update-listing-failed', { listingData: data });
      return dispatch(updateListingError(storableError(e)));
    });
};

export const fetchSales = () => (dispatch, getState, sdk) => {
  dispatch(fetchSalesRequest());

  const allowedTransitions = TRANSITIONS.filter(
    transition => !BLACKLISTED_TRANSITIONS.includes(transition)
  );

  const apiQueryParams = {
    only: 'sale',
    lastTransitions: allowedTransitions,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    per_page: TRANSACTIONS_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchSalesSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchSalesError(storableError(e)));
      throw e;
    });
};

export const inviteProfessional = (email, listing) => (dispatch, getState, sdk) => {
  dispatch(inviteProfessionalRequest());

  const params = {
    profileEmail: email,
    projectId: listing.id.uuid,
    projectTitle: listing.attributes.title,
  };

  return axios
    .get(sendInviteEmailAPI, { params })
    .then(response => {
      dispatch(inviteProfessionalSuccess());
      return response;
    })
    .catch(e => dispatch(inviteProfessionalError(storableError(e))));
};

export const findListingTransaction = (listingId, providerId) => (dispatch, getState, sdk) => {
  dispatch(findListingTransactionRequest());

  return axios
    .get(queryTransactionsAPI, { params: { listingId, providerId } })
    .then(response => {
      const transactions = response.data.data.data;
      const transaction = transactions.find(t =>
        ALLOWED_TRANSITIONS.includes(t.attributes.lastTransition)
      );

      dispatch(findListingTransactionSuccess(transaction));
      return transaction;
    })
    .catch(e => dispatch(findListingTransactionError(storableError(e))));
};

export const loadData = (params, search) => dispatch => {
  return dispatch(queryOwnListings()).then(response => {
    const listings = response.data.data;
    const selectedListingId = params.id || listings[0]?.id?.uuid;

    return dispatch(fetchSales(selectedListingId));
  });
};
