import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { PrimaryButton, IconShield, Modal } from '..';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

import css from './SubscriptionModal.module.css';

const SubscriptionModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    subscriptionMessageKey,
    history,
    location,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'SubscriptionModal.later' });

  const handleButton = () => {
    const state = location
      ? { from: `${location.pathname}${location.search}${location.hash}` }
      : null;

    history.push(
      createResourceLocatorString('SubscriptionPage', routeConfiguration(), {}, {}),
      state
    );
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconShield className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="SubscriptionModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id={subscriptionMessageKey ? subscriptionMessageKey : 'SubscriptionModal.description'}
        />
      </p>
      <PrimaryButton className={css.modalButton} onClick={handleButton}>
        <FormattedMessage id="SubscriptionModal.buttonText" />
      </PrimaryButton>
    </Modal>
  );
};

const { string } = PropTypes;

SubscriptionModal.defaultProps = {
  className: null,
  rootClassName: null,
};

SubscriptionModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SubscriptionModal);
