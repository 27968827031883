import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ENQUIRE,
  TRANSITION_DISCUSSION,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_PRO_DISCUSSION,
  TRANSITION_PROPOSAL,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_DECLINE,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_DECLINE_COUNTER_OFFER,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_AUTO_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_ACCEPT,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_PROPOSAL,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_DISPUTE,
  TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
  TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
  TRANSITION_CANCEL_FROM_DISPUTED,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const sortLastTransitions = sort => {
  switch (sort) {
    case 'not-started':
      return [
        TRANSITION_ENQUIRE,
        TRANSITION_DISCUSSION,
        TRANSITION_REQUEST_PAYMENT,
        TRANSITION_PRO_DISCUSSION,
        TRANSITION_PROPOSAL,
        TRANSITION_DECLINE_PROPOSAL,
        TRANSITION_DECLINE,
        TRANSITION_COUNTER_OFFER,
        TRANSITION_DECLINE_COUNTER_OFFER,
        TRANSITION_EXPIRE_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_AUTO_DECLINE,
        TRANSITION_EXPIRE,
        TRANSITION_CANCEL,
      ];
    case 'in-progress':
      return [TRANSITION_ACCEPT_PROPOSAL, TRANSITION_ACCEPT_COUNTER_OFFER, TRANSITION_ACCEPT];
    case 'completed':
      return [
        TRANSITION_COMPLETE,
        TRANSITION_COMPLETE_PROPOSAL,
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
        TRANSITION_DISPUTE,
        TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
        TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
        TRANSITION_CANCEL_FROM_DISPUTED,
      ];

    case 'all-transactions':
    default:
      return TRANSITIONS;
  }
};

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST =
  'app/InboxPageProfessional/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
  'app/InboxPageProfessional/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPageProfessional/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1, sort } = parse(search);
  const lastTransitions = sortLastTransitions(sort);

  const apiQueryParams = {
    lastTransitions,
    only: 'order',
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
