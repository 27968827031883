import React from 'react';
import { NamedLink } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const { showDetailCardHeadings, listingTitle, listingId } = props;

  if (!listingTitle || !listingId) {
    return null;
  }

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      {listingTitle ? <h2 className={css.detailCardTitle}>{listingTitle}</h2> : null}
      {listingId ? (
        <NamedLink
          className={css.detailCardSubtitle}
          name="ListingPage"
          params={{ id: listingId, slug: createSlug(listingTitle) }}
        >
          <FormattedMessage id="TransactionPanel.viewListing" />
        </NamedLink>
      ) : null}
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
