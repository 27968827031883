import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureUser } from '../../util/data';
import { Button, ArrowButton, ResponsiveImage, AvatarMedium } from '..';

import css from './InviteSingleProfessional.module.css';

const InviteCard = props => {
  const { listing, projectId, inviteInProgress, inviteError, currentInviteId, onSubmit } = props;

  if (!listing) {
    return null;
  }

  const { title } = listing.attributes;
  const listingId = listing.id.uuid;

  // Listing fields
  const listingLocation = listing.attributes.publicData.userLocation;
  const listingAuthor = ensureUser(listing?.author);

  // Author fields
  const userInvitations = listingAuthor.attributes.profile.publicData?.invitations;

  // User invitations
  const userInvitationIds = userInvitations?.map(l => l.id);
  const isAlreadyInvited = userInvitationIds?.includes(projectId);

  // Is invitation currently going on
  const isCurrentlyInviting = currentInviteId === listingAuthor.id.uuid;

  const errorMessage =
    inviteError && isCurrentlyInviting ? (
      <p className={css.error}>
        <FormattedMessage id="InviteSingleProfessional.errorMessage" />
      </p>
    ) : null;

  const buttonDisabled = !!inviteError || !!isAlreadyInvited;
  return (
    <div key={listingId} className={css.listingWrapper}>
      <div className={css.listingContentWrapper}>
        <div className={css.listingContent}>
          <AvatarMedium user={listingAuthor} />
          <div className={css.listingInfo}>
            <h4 className={css.listingTitle}>{title}</h4>
            <span className={css.listingLocation}>{listingLocation}</span>
          </div>
        </div>
        {isAlreadyInvited ? (
          <Button className={css.arrowButton} disabled={buttonDisabled}>
            <FormattedMessage id="InviteSingleProfessional.invitedButtonText" />
          </Button>
        ) : (
          <ArrowButton
            className={css.arrowButton}
            onClick={onSubmit}
            inProgress={inviteInProgress && isCurrentlyInviting}
            disabled={buttonDisabled}
          >
            <FormattedMessage id="InviteSingleProfessional.inviteButtonText" />
          </ArrowButton>
        )}
      </div>
      {errorMessage}
    </div>
  );
};

InviteCard.defaultProps = {
  inviteError: null,
  inviteInProgress: false,
};

const { bool, func, array } = PropTypes;

InviteCard.propTypes = {
  userVerifications: array,
  onSubmit: func.isRequired,
  inviteError: propTypes.error,
  inviteInProgress: bool.isRequired,
};

export default InviteCard;
