import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing, formatShortLocation } from '../../util/data';
import { ProfileLink } from '..';
import { EditProfessionalLocationForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditProfessionalLocationPanel.module.css';

const { LatLng: SDKLatLng } = sdkTypes;

class EditProfessionalLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.constructServiceLocation = this.constructServiceLocation.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  constructServiceLocation(location) {
    const locationFieldsPresent = location && location.search && location.selectedPlace;
    const { selectedPlace } = location;
    const { origin } = selectedPlace;

    return locationFieldsPresent
      ? {
          location: {
            ...location,
            selectedPlace: {
              ...selectedPlace,
              origin: new SDKLatLng(origin.lat, origin.lng),
            },
          },
        }
      : null;
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;
    const serviceLocations = publicData?.serviceLocations?.map(l =>
      this.constructServiceLocation(l.location)
    );

    return {
      building,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
      serviceLocations,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      tab,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditProfessionalLocationPanel.title"
        values={{
          yourProfileLink: (
            <ProfileLink listing={listing} tab={tab}>
              <FormattedMessage id="EditProfessionalLocationPanel.yourProfile" />
            </ProfileLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditProfessionalLocationPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditProfessionalLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { building = '', location, serviceLocations } = values;
            const {
              selectedPlace: { address, origin, locationInfo },
            } = location;
            const denormalisedServiceLocations = serviceLocations?.map(l => {
              const serviceLocationField = l.location.selectedPlace;
              return {
                location: {
                  search: serviceLocationField.address,
                  selectedPlace: {
                    address: serviceLocationField.address,
                    origin: serviceLocationField.origin,
                    shortLocationInfo: serviceLocationField.shortLocationInfo,
                  },
                },
              };
            });
            const serviceLocationAddresses = denormalisedServiceLocations?.map(l =>
              formatShortLocation(l.location.selectedPlace.shortLocationInfo)
            );
            const serviceLocationNames = denormalisedServiceLocations?.map(
              l => l.location.selectedPlace.shortLocationInfo
            );

            const passedLocationMaybe = !publicData.passedLocation ? { passedLocation: true } : {};

            const updateValues = {
              geolocation: origin,
              publicData: {
                location: { address, origin },
                userLocation: locationInfo,
                serviceLocations: denormalisedServiceLocations,
                serviceLocationAddresses,
                serviceLocationNames,
                ...passedLocationMaybe,
              },
            };
            this.setState({
              initialValues: {
                building,
                location: { search: address, selectedPlace: { address, origin, locationInfo } },
                serviceLocations: denormalisedServiceLocations,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditProfessionalLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditProfessionalLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditProfessionalLocationPanel;
