import React from 'react';
import { string } from 'prop-types';

const IconLocation = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.786 6.429C14.786 9.63 9 17.357 9 17.357S3.214 9.63 3.214 6.43a5.786 5.786 0 1 1 11.572 0v0Z"
        stroke="#656464"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.357A1.929 1.929 0 1 0 9 4.5a1.929 1.929 0 0 0 0 3.857Z"
        stroke="#656464"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconLocation.defaultProps = { className: null };

IconLocation.propTypes = { className: string };

export default IconLocation;
