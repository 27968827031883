import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import GetJobsIcon from './GetJobsIcon';
import GetMatchedIcon from './GetMatchedIcon';
import GetQuoteIcon from './GetQuoteIcon';
import SecurePaymentIcon from './SecurePaymentIcon';
import LeaveReviewIcon from './LeaveReviewIcon';
import EnjoyIcon from './EnjoyIcon';
import GradientIcon from './GradientIcon';

import gearImage from '../../assets/gear-image.png';
import css from './SectionClientFeatures.module.css';

const MOBILE_BREAKPOINT = 768; // Search is in modal on mobile layout

const featureCard = (titleMsgId, textMsgId, order, iconDisplay) => {
  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MOBILE_BREAKPOINT;
  return (
    <div className={css.card}>
      <GradientIcon className={css.cardIconMobile}>{order}</GradientIcon>
      {iconDisplay}
      <div className={css.cardContent}>
        <h2 className={css.cardTitle}>
          {!isMobileLayout ? `${order}. ` : null}
          <FormattedMessage id={titleMsgId} />
        </h2>
        <p className={css.cardText}>
          <FormattedMessage id={textMsgId} />
        </p>
      </div>
    </div>
  );
};

const SectionClientFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.subTitle}>
        <FormattedMessage id="SectionClientFeatures.subTitle" />
      </div>
      <div className={css.title}>
        <FormattedMessage id="SectionClientFeatures.title" />
      </div>
      <p className={css.text}>
        <FormattedMessage id="SectionClientFeatures.text" />
      </p>
      <div className={css.cardsWrapper}>
        <img className={css.gearImage} src={gearImage} alt="Equipped" />
        <div className={css.cards}>
          {featureCard(
            'SectionClientFeatures.postJobTitle',
            'SectionClientFeatures.postJobText',
            1,
            <GetJobsIcon className={css.cardIconDesktop} />
          )}
          {featureCard(
            'SectionClientFeatures.getMatchedTitle',
            'SectionClientFeatures.getMatchedText',
            2,
            <GetMatchedIcon className={css.cardIconDesktop} />
          )}
          {featureCard(
            'SectionClientFeatures.getQuotesTitle',
            'SectionClientFeatures.getQuotesText',
            3,
            <LeaveReviewIcon className={css.cardIconDesktop} />
          )}
          {featureCard(
            'SectionClientFeatures.securePaymentTitle',
            'SectionClientFeatures.securePaymentText',
            4,

            <GetQuoteIcon className={css.cardIconDesktop} />
          )}
          {featureCard(
            'SectionClientFeatures.leaveReviewTitle',
            'SectionClientFeatures.leaveReviewText',
            5,
            <SecurePaymentIcon className={css.cardIconDesktop} />
          )}
          {featureCard(
            'SectionClientFeatures.enjoyTitle',
            'SectionClientFeatures.enjoyText',
            6,
            <EnjoyIcon className={css.cardIconDesktop} />
          )}
        </div>
      </div>
    </div>
  );
};

SectionClientFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionClientFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionClientFeatures;
