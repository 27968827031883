import React from 'react';
import { object, bool } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { ensureListing } from '../../../util/data';
import { format as formatPhoneNumber } from '../../../components/FieldPhoneNumberInput/fiFormatter';
import { ExternalLink } from '../..';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import css from '../ProfileTabs.module.css';

const AboutTab = props => {
  const { currentListing, showLoadingSkeleton, userSkills, companystaff, isCompanyUser } = props;
  const { title, publicData } = currentListing.attributes;

  // User info
  const userDisplayName = isCompanyUser
    ? `${publicData?.firstName} ${publicData?.lastName}`
    : title;
  const companyDisplayName = publicData?.userData?.companyName || publicData?.company;
  const userServiceLocations = publicData?.serviceLocationNames
    ?.map(l => l?.replace(',', ''))
    .join(', ');
  const hasServiceLocations = !!userServiceLocations && userServiceLocations.length > 0;
  const hasUserSkills = userSkills?.length > 1;
  const hasCompanystaff = companystaff?.length > 1;
  const ownerOperator = publicData?.operator;
  const contractorLicence = publicData?.contractorLicence;
  const crewMembers = publicData?.crewMembers;
  const phoneNumber = ensureListing(currentListing).author?.attributes?.profile?.publicData
    ?.phoneNumber;
  const phoneNumberVisibility = publicData?.phoneNumber;
  const showPhoneNumber = phoneNumber && phoneNumberVisibility === 'public';
  const website = publicData?.website;

  // Biography
  const bio = currentListing?.attributes?.description;

  return (
    <div className={css.tab}>
      <div className={css.sectionContent}>
        <div className={css.sectionHeading}>
          <FormattedMessage id="ProfessionalPage.infoHeading" />
        </div>
        <ul className={css.aboutList}>
          {isCompanyUser ? null : (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.fullNameHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? <Skeleton width="30%" /> : userDisplayName}
              </span>
            </li>
          )}
          <li className={css.aboutListItem}>
            <FormattedMessage id="ProfessionalPage.companyHeading" />
            <span className={css.aboutListItemAnswer}>
              {showLoadingSkeleton ? <Skeleton width="30%" /> : companyDisplayName}
            </span>
          </li>
          {isCompanyUser ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.fullNameHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? <Skeleton width="30%" /> : userDisplayName}
              </span>
            </li>
          ) : null}
          {ownerOperator ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.ownerOperatorHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? (
                  <Skeleton width="30%" />
                ) : (
                  <FormattedMessage id="ProfessionalPage.ownerOperatorMessage" />
                )}
              </span>
            </li>
          ) : null}
          {hasCompanystaff ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.staffHeading" />

              <span className={css.aboutListItemAnswer}>
                {companystaff.map(v => v.label).join(', ')}
              </span>
            </li>
          ) : null}
          {contractorLicence ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.contractorLicenceHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? <Skeleton width="30%" /> : contractorLicence}
              </span>
            </li>
          ) : null}
          {crewMembers ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.crewMembersHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? <Skeleton width="30%" /> : crewMembers}
              </span>
            </li>
          ) : null}
          {showPhoneNumber ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.phoneNumberHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? (
                  <Skeleton width="30%" />
                ) : (
                  <a href={`tel:${phoneNumber}`}>
                    {formatPhoneNumber(phoneNumber)?.replaceAll(' ', '-')}
                  </a>
                )}
              </span>
            </li>
          ) : null}
          {hasServiceLocations ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.serviceLocationsHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? <Skeleton width="30%" /> : userServiceLocations}
              </span>
            </li>
          ) : null}
          {website ? (
            <li className={css.aboutListItem}>
              <FormattedMessage id="ProfessionalPage.websiteHeading" />
              <span className={css.aboutListItemAnswer}>
                {showLoadingSkeleton ? (
                  <Skeleton width="30%" />
                ) : (
                  <ExternalLink className={css.link} href={website}>
                    {website}
                  </ExternalLink>
                )}
              </span>
            </li>
          ) : null}
        </ul>
      </div>
      {bio ? (
        <div className={css.sectionContent}>
          <div className={css.sectionHeading}>
            <FormattedMessage id="ProfessionalPage.bioHeading" />
          </div>
          <p className={css.aboutBio}>
            {showLoadingSkeleton ? <Skeleton count="4" width="100%" /> : bio}
          </p>
        </div>
      ) : null}
      {hasUserSkills ? (
        <div className={classNames(css.sectionContent, css.sectionContentSkills)}>
          <div className={css.sectionHeading}>
            <FormattedMessage id="ProfessionalPage.userSkillsHeading" />
          </div>
          <ul className={css.userSkillsList}>
            {userSkills.map(v => {
              return (
                <li className={css.userSkillsItem} key={v.key}>
                  {v.label}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

AboutTab.propTypes = {
  currentListing: object,
  showLoadingSkeleton: bool.isRequired,
};

export default AboutTab;
