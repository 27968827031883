import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Reviews, IconReviewStar } from '../../../components';

import css from '../ProfileTabs.module.css';

const reviewExample = [
  'Communication',
  'Professionalism',
  'Punctuality',
  'Job quality',
  'Cleanliness',
];

const ReviewsTab = props => {
  const { intl, reviews, professionalReviews, queryReviewsInProgress } = props;
  const hasReviews = reviews?.length > 0;

  console.log(reviews, professionalReviews);
  return (
    <div className={css.tab}>
      <div className={css.sectionContent}>
        <div className={css.sectionHeading}>
          <FormattedMessage
            id="ProfessionalPage.reviewsHeading"
            values={{ count: reviews?.length }}
          />
        </div>
        {!queryReviewsInProgress ? (
          hasReviews ? (
            <Reviews intl={intl} reviews={reviews} professionalReviews={professionalReviews} />
          ) : (
            <div>
              <div className={css.reviewAttributes}>
                {reviewExample.map((r, index) => {
                  return (
                    <div key={index} className={css.reviewAttribute}>
                      <IconReviewStar className={css.reviewAttributeStar} isFilled />
                      <span className={css.reviewAttributeName}>{r}</span>
                    </div>
                  );
                })}
              </div>
              <p className={css.reviewsBio}>
                <FormattedMessage id="ProfessionalPage.noReviews" />
              </p>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default ReviewsTab;
