import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconLogs, Modal, ExternalLink, IconEye } from '..';
import { DailyLogsForm } from '../../forms';
import moment from 'moment';

import css from './DailyLogsModal.module.css';

const DailyLogsModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitLog,
    createLogInProgress,
    createLogError,
    dailyLogSubmitted,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'DailyLogsModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconLogs className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="DailyLogsModal.title" />
      </p>
      <DailyLogsForm
        onSubmit={onSubmitLog}
        createLogInProgress={createLogInProgress}
        createLogError={createLogError}
        dailyLogSubmitted={dailyLogSubmitted}
      />
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

DailyLogsModal.defaultProps = {
  className: null,
  rootClassName: null,
  createLogInProgress: false,
  createLogError: null,
  dailyLogSubmitted: false,
};

DailyLogsModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,

  onSubmitLog: func.isRequired,
  createLogInProgress: bool.isRequired,
  createLogError: propTypes.error,
  dailyLogSubmitted: bool.isRequired,
};

export default injectIntl(DailyLogsModal);

/**
 * Modal for showing single log details
 */
export const DailyLogsDetailsModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    log,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'DailyLogsModal.later' });

  if (!log) {
    return null;
  }

  const renderLogItem = (title, text, capitalizeText = false) => {
    return (
      <li className={css.logItem}>
        <span className={css.logItemTitle}>{title}</span>
        <p
          className={css.logItemText}
          style={capitalizeText ? { textTransform: 'capitalize' } : {}}
        >
          {text || 'N / A'}
        </p>
      </li>
    );
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconLogs className={css.modalIcon} />
      <p className={css.modalTitle}>{moment(log.date).format('ddd, MMM DD')}</p>
      <ul className={css.logList}>
        {renderLogItem('Notes', log.notes)}
        {renderLogItem('Job Progress', log.jobProgress)}
        {renderLogItem('Any Issues on Jobsite?', log.issues, true)}
        {log.issues === 'yes' && log.issueReason
          ? renderLogItem('Issue reason', log.issueReason)
          : null}
        <li className={css.logItem}>
          <span className={css.logItemTitle}>
            <FormattedMessage
              id="DailyLogsDetailsModal.attachments"
              values={{ count: log.attachments.length }}
            />
          </span>
          <ul className={css.logAttachments}>
            {log.attachments.map(a => {
              return (
                <li className={css.logAttachment}>
                  <ExternalLink href={a}>
                    <img className={css.logAttachmentImage} src={a} alt="Attachment" />
                    <div className={css.logAttachmentImageOverlay}>
                      <IconEye className={css.logAttachmentImageOverlayIcon} />
                    </div>
                  </ExternalLink>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </Modal>
  );
};

DailyLogsDetailsModal.defaultProps = {
  className: null,
  rootClassName: null,
};

DailyLogsDetailsModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  log: propTypes.dailyLog,
};

DailyLogsDetailsModal.displayName = 'DailyLogsDetailsModal';
