import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const MaterialQualityIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        d="m3.37 5.85 2.54-4.06a1.09 1.09 0 0 1 .94-.52h0A1.11 1.11 0 0 1 8 2.37v2.91h4.39a1.15 1.15 0 0 1 1.1 1.32l-.8 5.16a1.14 1.14 0 0 1-1.13 1H5a2 2 0 0 1-.9-.21l-.72-.36M3.37 5.85v6.31M1 5.85h2.37v6.31h0H1a.5.5 0 0 1-.5-.5V6.35a.5.5 0 0 1 .5-.5Z"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
    </svg>
  );
};

MaterialQualityIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

MaterialQualityIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default MaterialQualityIcon;
