import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowHead } from '../../components';
import { DiscountForm } from '../../forms';
import { denormaliseDiscountCode } from '../../util/data';
import classNames from 'classnames';

import css from './BookingDiscount.module.css';

const DEFAULT_ARROW_DIRECTION = 'down';

const BookingDiscount = props => {
  const { rootClassName, className, discountCode, setDiscountCode, currentUser } = props;

  // Manage discount state
  const [showDiscount, setShowDiscount] = useState(false);
  const [invalidDiscount, setInvalidDiscount] = useState(false);

  const currentUserDiscounts = currentUser.attributes.profile.protectedData.discounts;

  const handleDiscountSubmit = values => {
    const { code } = values;
    const denoramlisedDiscountCode = denormaliseDiscountCode(code);

    const discountCodes = process.env.REACT_APP_DISCOUNT_CODES.split(',');
    const isDiscountAvailable = discountCodes.includes(denoramlisedDiscountCode);
    const isDiscountAlreadyUsed = currentUserDiscounts?.includes(denoramlisedDiscountCode);

    if (isDiscountAvailable) {
      if (isDiscountAlreadyUsed) {
        setInvalidDiscount(true);
      } else {
        setDiscountCode(denoramlisedDiscountCode);
        setShowDiscount(false);
        setInvalidDiscount(false);
      }
    } else {
      setInvalidDiscount(true);
    }
  };

  const classes = classNames(rootClassName || css.root, className);
  const discountContainerClasses = classNames(css.discountContainer, {
    [css.discountContainerApplied]: discountCode,
  });
  const iconClasses = classNames(css.iconArrow, {
    [css.iconArrowShow]: showDiscount,
  });
  return (
    <div className={classes}>
      <div className={discountContainerClasses} onClick={() => setShowDiscount(!showDiscount)}>
        <span className={css.addDiscountText}>
          <FormattedMessage id="BookingDiscount.addDiscount" />
        </span>
        <IconArrowHead className={iconClasses} direction={DEFAULT_ARROW_DIRECTION} />
      </div>
      {showDiscount ? (
        <DiscountForm onSubmit={handleDiscountSubmit} invalidDiscount={invalidDiscount} />
      ) : null}
    </div>
  );
};

BookingDiscount.defaultProps = {
  className: null,
  rootClassName: null,
  discountCode: null,
  setDiscountCode: null,
};

const { string, func } = PropTypes;

BookingDiscount.propTypes = {
  className: string,
  rootClassName: string,
  discountCode: string,
  setDiscountCode: func.isRequired,
};

export default BookingDiscount;
