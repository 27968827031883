import React from 'react';
import { array, func, string } from 'prop-types';
import { NamedLink } from '../../components';
import { ABOUT } from './ProfileTabs';
import classNames from 'classnames';

import css from './ProfileTabs.module.css';

const Tab = props => {
  const { tabClassName, tab, params, tabLabel, tabIcon, listingId } = props;
  const tabClasses = classNames(tabClassName, css.tabNavHeading);

  const isVariant = !!params.variant;

  const tabFromParams = params.tab || ABOUT;
  const isTabSelected = tab === tabFromParams;

  return (
    <li className={tabClasses}>
      <NamedLink
        className={classNames(css.tabNavHeadingLink, {
          [css.tabNavHeadingSelectedLink]: isTabSelected,
        })}
        name={isVariant ? 'ProfessionalPageVariant' : 'ProfessionalPage'}
        params={
          isVariant
            ? {
                id: listingId,
                variant: params.variant,
                tab,
              }
            : {
                id: listingId,
                tab,
              }
        }
      >
        {tabIcon(tab)}
        {tabLabel(tab)}
      </NamedLink>
    </li>
  );
};

Tab.propTypes = {
  tabClassName: null,
  listingId: null,
  tabLabel: null,
  tabIcon: null,
};

Tab.propTypes = {
  tabClassName: string,
  listingId: string,
  tabLabel: func.isRequired,
  tabIcon: func,
};

const TabNav = props => {
  const { rootClassName, className, tabClassName, tabs } = props;
  const classes = classNames(rootClassName || className, css.tabNav);
  return (
    <ul className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab === 'string' ? tab : `${index}`;
        return <Tab key={id} tabClassName={tabClassName} tab={tab} {...props} />;
      })}
    </ul>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  rootClassName: string,
  className: string,
  tabClassName: string,
  tabs: array.isRequired,
};

export default TabNav;
