import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldSelect,
  FieldCurrencyInput as FieldCurrency,
} from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';

import css from './ProposalModal.module.css';

const ProposalModalComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        intl,
        proposalInProgress,
        proposalError,
        ownListings,
      } = fieldRenderProps;

      const projectLabel = intl.formatMessage({
        id: 'ProposalForm.projectLabel',
      });
      const projectPlaceholder = intl.formatMessage({
        id: 'ProposalForm.projectPlaceholder',
      });
      const projectRequiredMessage = intl.formatMessage({
        id: 'ProposalForm.projectRequired',
      });
      const projectRequired = validators.requiredAndNonEmptyString(projectRequiredMessage);

      const budgetLabel = intl.formatMessage({ id: 'ProposalForm.budgetLabel' });
      const budgetPlaceholder = intl.formatMessage({ id: 'ProposalForm.budgetPlaceholder' });
      const budgetRequiredMessage = intl.formatMessage({ id: 'ProposalForm.budgetRequired' });
      const budgetRequired = validators.required(budgetRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = proposalInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldSelect
            className={css.field}
            id="projectId"
            name="projectId"
            label={projectLabel}
            validate={projectRequired}
          >
            <option disabled value="">
              {projectPlaceholder}
            </option>
            {ownListings.map(l => {
              return (
                <option key={l.id.uuid} value={l.id.uuid}>
                  {l.attributes.title}
                </option>
              );
            })}
          </FieldSelect>

          <FieldCurrency
            className={css.field}
            id="budget"
            name="budget"
            label={budgetLabel}
            placeholder={budgetPlaceholder}
            validate={budgetRequired}
            currencyConfig={config.currencyConfig}
          />

          <div className={submitButtonWrapperClassName}>
            {proposalError ? (
              <p className={css.error}>
                <FormattedMessage id="ProposalModal.proposalError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="ProposalModal.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ProposalModalComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  proposalInProgress: false,
  proposalError: null,
};

ProposalModalComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  proposalInProgress: bool,
  proposalError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProposalModal = compose(injectIntl)(ProposalModalComponent);

ProposalModal.displayName = 'ProposalModal';

export default ProposalModal;
