/**
 * Helper component that uses react-dropzone
 * to make uploading images and videos easier.
 */
import React from 'react';
import { node, string, func, bool, object } from 'prop-types';
import { Field } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import css from './FieldMultipleImages.module.css';

const FieldMultipleImagesComponent = props => {
  const {
    rootClassName,
    className,
    aspectWrapperClassName,
    children,
    form,
    input,
    onChange,
    dropzoneAccept,
    dropzoneRef,
    isDragActive,
    onToggleDragActive,
    hasFiles,
  } = props;

  if (!onToggleDragActive) {
    throw new Error(
      `FieldMultipleImages has invalid props:
        Both isDragActive and onToggleDragActive need to be defined.`
    );
  }

  const { name, type, accept, multiple, disabled } = input;

  const handleOnChange = files => {
    form.change(name, files);
    form.blur(name);

    // Call onChange only if it was passed
    // to the component props
    if (onChange) {
      onChange(files);
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: dropzoneAccept,
    noClick: true,
    noKeyboard: true,
    multiple,
    ref: ref => dropzoneRef(ref),
    onDrop: files => handleOnChange(files),
    onDragEnter: () => onToggleDragActive(true),
    onDragLeave: () => onToggleDragActive(false),
    onDropAccepted: () => onToggleDragActive(false),
    onDropRejected: () => onToggleDragActive(false),
  });

  const inputProps = {
    accept,
    id: name,
    name,
    onChange: e => handleOnChange(acceptedFiles),
    type,
    multiple,
  };

  const classes = classNames(rootClassName || css.root, className);
  const aspectRatioClass = classNames(css.aspectRatioWrapper, {
    [css.multipleFilesAspectRatio]: hasFiles,
    [aspectWrapperClassName]: hasFiles,
  });
  const labelClasses = classNames(css.label, {
    [css.labelDropFile]: isDragActive,
  });

  return (
    <button className={classes} type="button" {...getRootProps()} onClick={open}>
      <div className={aspectRatioClass}>
        {disabled ? null : (
          <input className={css.dropzoneInput} {...inputProps} {...getInputProps()} />
        )}
        <label htmlFor={name} className={labelClasses}>
          {children}
        </label>
      </div>
    </button>
  );
};

FieldMultipleImagesComponent.defaultProps = {
  rootClassName: null,
  className: null,
  aspectWrapperClassName: null,
  id: null,
  form: null,
  onChange: null,
  dropzoneAccept: null,
  dropzoneRef: null,
  isDragActive: false,
  onToggleDragActive: null,
  hasFiles: false,
};

FieldMultipleImagesComponent.propTypes = {
  rootClassName: string,
  className: string,
  aspectWrapperClassName: string,
  children: node.isRequired,

  // Final form required fields
  id: string,
  form: object.isRequired,
  onChange: func.isRequired,

  // react-dropzone props
  drozoneAccept: object,
  dropzoneRef: func,

  // Required custom react-dropzone props that determine
  // and toggle dropzone dragging
  isDragActive: bool.isRequired,
  onToggleDragActive: func.isRequired,

  hasFiles: bool.isRequired,
};

const FieldMultipleImages = props => {
  return <Field component={FieldMultipleImagesComponent} {...props} />;
};

export default FieldMultipleImages;
