import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { AddDocuments, Button, Form, IconSpinner, ExternalLink } from '../../components';
import config from '../../config';

import css from './EditProfessionalDocumentsForm.module.css';

const ACCEPT_FILES =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export class EditProfessionalDocumentsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingDocumentType: null,
    };
    this.onDocumentUploadHandler = this.onDocumentUploadHandler.bind(this);
  }

  onDocumentUploadHandler(file, documentType) {
    if (file) {
      this.setState({ uploadingDocumentType: documentType });
      const listingId = this.props.listing.id.uuid;
      this.props.onUploadDocument(listingId, file, documentType);
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onDocumentUploadHandler={this.onDocumentUploadHandler}
        initialValues={{ documents: this.props.documents }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            onDocumentUploadHandler,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            listing,
            onRemoveDocument,
            uploadDocumentInProgress,
            removeDocumentInProgress,
            removeDocumentId,
            documents,
          } = formRenderProps;

          const listingId = listing?.id?.uuid;
          const professionalVerifications = config.custom.professionalVerifications;

          const chooseDocumentText = documentName => (
            <span className={css.chooseDocumentText}>
              <span className={css.chooseDocument}>{documentName}</span>
              <span className={css.documentTypes}>
                <FormattedMessage id="EditProfessionalDocumentsForm.documentTypes" />
              </span>
            </span>
          );

          const { updateListingError, uploadDocumentError, removeDocumentError } =
            fetchErrors || {};

          const uploadDocumentFailed = uploadDocumentError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProfessionalDocumentsForm.uploadFailed" />
            </p>
          ) : null;
          const removeDocumentFailed = removeDocumentError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProfessionalDocumentsForm.removeFailed" />
            </p>
          ) : null;

          const uploadingOverlay = uploadDocumentInProgress ? (
            <div className={css.thumbnailLoading}>
              <IconSpinner />
            </div>
          ) : null;

          const googleStorageLink = (
            <ExternalLink
              className={css.googleStorageLink}
              href="https://support.google.com/drive/answer/37603?hl=en"
            >
              <FormattedMessage id="EditProfessionalDocumentsForm.googleStorageLink" />
            </ExternalLink>
          );

          const submitReady = updated || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = disabled || submitInProgress || ready || uploadDocumentInProgress;

          const classes = classNames(css.root, className);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedDocuments = documents;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditProfessionalDocumentsForm.updateFailed" />
                </p>
              ) : null}
              {uploadDocumentFailed}
              {removeDocumentFailed}

              <div className={css.formWrapper}>
                <AddDocuments
                  className={css.documentsField}
                  onRemoveDocument={onRemoveDocument}
                  removeDocumentInProgress={removeDocumentInProgress}
                  removeDocumentId={removeDocumentId}
                  listingId={listingId}
                  documents={documents}
                >
                  {professionalVerifications.map((doc, index) => {
                    return (
                      <Field
                        key={doc.key}
                        id={`addDocument${index}`}
                        name={`addDocument${index}`}
                        accept={ACCEPT_FILES}
                        form={null}
                        label={chooseDocumentText(doc.label)}
                        type="file"
                        disabled={uploadDocumentInProgress}
                        documentType={doc.key}
                      >
                        {fieldprops => {
                          const {
                            accept,
                            input,
                            label,
                            disabled: fieldDisabled,
                            documentType,
                          } = fieldprops;

                          // We need to remove the file input field when
                          // the document is already uploaded
                          if (documents?.find(d => d.type === documentType)) {
                            return null;
                          }

                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            form.change(`addDocument${index}`, file);
                            form.blur(`addDocument${index}`);
                            onDocumentUploadHandler(file, documentType);
                          };
                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div className={css.addDocumentWrapper}>
                              <div className={css.aspectRatioWrapper}>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addDocumentInput} />
                                )}
                                <label htmlFor={name} className={css.addDocument}>
                                  {label}
                                </label>
                                {this.state.uploadingDocumentType === documentType
                                  ? uploadingOverlay
                                  : null}
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                    );
                  })}
                </AddDocuments>

                <p className={css.tip}>
                  <FormattedMessage
                    id="EditProfessionalDocumentsForm.addDocumentsTip"
                    values={{ googleStorageLink }}
                  />
                </p>
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditProfessionalDocumentsFormComponent.defaultProps = { fetchErrors: null, documents: [] };

EditProfessionalDocumentsFormComponent.propTypes = {
  fetchErrors: shape({
    uploadDocumentError: propTypes.error,
    removeDocumentError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  documents: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(EditProfessionalDocumentsFormComponent);
