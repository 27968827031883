import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import gearImage from '../../assets/gear-image.png';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.leftContent}>
          <div className={css.iconAndTitle}>
            <img className={css.heroIcon} src={gearImage} alt="Equipped" />
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>
          </div>
          <p className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </p>
          <NamedLink name="NewCreateListingPage" className={css.projectButton}>
            <FormattedMessage id="SectionHero.createListingButton" />
          </NamedLink>
          <div className={css.heroButtons}>
            <NamedLink name="SearchProfessionalsPage" className={css.heroButton}>
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
            <NamedLink
              name="SearchPage"
              className={classNames(css.heroButton, css.heroBrowseButton)}
            >
              <FormattedMessage id="SectionHero.searchButton" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
