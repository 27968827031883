import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, InlineTextButton } from '../../components';

import css from './PostCommentForm.module.css';

const PostCommentFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,

        form,
        values,
        addCommentError,
        addCommentInProgress,
        commentDisplayName,
      } = fieldRenderProps;

      const commentPlaceholderMessage = intl.formatMessage({
        id: 'PostCommentForm.commentPlaceholder',
      });

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="PostCommentForm.postError" />
        </p>
      );

      const errorArea = addCommentError ? errorMessage : null;

      const postingAsDisplayName = intl.formatMessage(
        { id: 'PostCommentForm.commentAs' },
        { displayName: commentDisplayName }
      );
      const submitCommentMessage = intl.formatMessage({
        id: 'PostCommentForm.postSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = addCommentInProgress;
      const submitDisabled = disabled || submitInProgress || !values.comment;

      return (
        <Form
          className={classes}
          onSubmit={values => {
            handleSubmit(values);
            form.reset();
          }}
        >
          <span className={css.commentAs}>{postingAsDisplayName}</span>
          <FieldTextInput
            className={css.comment}
            inputRootClass={css.commentInput}
            type="textarea"
            id={formId ? `${formId}.comment` : 'comment'}
            name="comment"
            placeholder={commentPlaceholderMessage}
          />

          <div className={css.bottomWrapper}>
            <InlineTextButton
              className={css.resetButton}
              onClick={e => {
                form.reset();
                e.preventDefault();
              }}
            >
              Reset
            </InlineTextButton>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              spinnerClassName={css.spinnerIcon}
            >
              {submitCommentMessage}
            </Button>
          </div>
          {errorArea}
        </Form>
      );
    }}
  />
);

PostCommentFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  addCommentError: null,
};

const { bool, func, string } = PropTypes;

PostCommentFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  addCommentError: propTypes.error,
  addCommentInProgress: bool.isRequired,
};

const PostCommentForm = compose(injectIntl)(PostCommentFormComponent);
PostCommentForm.displayName = 'PostCommentForm';

export default PostCommentForm;
