import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionClientFeatures.module.css';

const GetJobsIcon = props => {
  const { className, children } = props;
  const classes = classNames(css.gradientWrapper, className);

  return <div className={classes}>{children}</div>;
};

GetJobsIcon.defaultProps = { className: null };

GetJobsIcon.propTypes = {
  className: string,
};

export default GetJobsIcon;
