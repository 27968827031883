import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EnquiryBreakdown.module.css';

const { Money } = sdkTypes;

const ItemSubtotal = props => {
  const { intl, transactionQuote } = props;
  const { amount, currency } = transactionQuote.price;

  const quote = transactionQuote.price ? new Money(amount, currency) : null;
  const formattedQuote = quote ? formatMoney(intl, quote) : null;

  return (
    <>
      <div className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="EnquiryBreakdown.subTotal" />
        </span>
        <span className={css.itemValue}>{formattedQuote}</span>
      </div>
    </>
  );
};

ItemSubtotal.propTypes = {
  intl: intlShape.isRequired,
  transactionQuote: object.isRequired,
};

export default ItemSubtotal;
