import React from 'react';

const DownloadIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g>
        <line
          x1="0.5"
          y1="13.5"
          x2="13.5"
          y2="0.5"
          fill="none"
          stroke="#4a4a4a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <polyline
          points="8.5 0.5 13.5 0.5 13.5 5.5"
          fill="none"
          stroke="#4a4a4a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
