
export const onUploadCloudinary = (file) => {
  const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
  const formData = new FormData();
  const fileType = file.type;
  formData.append('file', file);
  formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
  formData.append('resource_type', fileType);
  formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
  formData.append('folder', 'professionalProfile');

  return fetch(uploadURL, { method: 'POST', body: formData })
    .then(response => response.json())
    .catch(error => {
      console.error('Error uploading image:', error);
    });
};
