import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../EditListingPricingForm.module.css';

const PunctualityIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.sliderIcon, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        d="m3.5 13.5 3.14-7.33a.39.39 0 0 1 .72 0l3.14 7.33M4.91 10.21h4.18M.5 4A3.5 3.5 0 0 1 4 .5"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
      <path
        d="M.5 4A3.5 3.5 0 0 1 4 .5M3 4a1 1 0 0 1 1-1"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
      <path
        d="M3 4a1 1 0 0 1 1-1M10 .5A3.5 3.5 0 0 1 13.5 4"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
      <path
        d="M10 .5A3.5 3.5 0 0 1 13.5 4M10 3a1 1 0 0 1 1 1"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
      <path
        d="M10 3a1 1 0 0 1 1 1"
        style={{
          fill: 'none',
          stroke: '#b2b2b2',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="scale(1.71429)"
      />
    </svg>
  );
};

PunctualityIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

PunctualityIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default PunctualityIcon;
