/**
 * Functional component to help with uploading
 * multiple images/files
 *
 * See: https://react-dropzone.js.org
 */
import React, { useRef, useEffect } from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, IconClose } from '../../components';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import DownloadIcon from './DownloadIcon';
import pdfImage from './pdf-image.png';
import docxImage from './docx-image.png';
import css from './FieldDropzone.module.css';

const FileThumb = props => {
  const { file, removeFile, disabled } = props;

  const isPdf = file.path.includes('.pdf');
  const isDocx = file.path.includes('.docx') || file.path.includes('.doc');

  const isFileDocument = isPdf || isDocx;
  const fileLink = isFileDocument ? (isPdf ? pdfImage : docxImage) : file.preview;
  return (
    <li className={css.thumb} key={file.name}>
      <span
        className={css.removeContainer}
        onClick={() => {
          if (!disabled) {
            removeFile(file.index);
          }
        }}
      >
        <IconClose className={css.removeIcon} />
      </span>
      <div className={css.thumbInner}>
        <img src={fileLink} className={css.thumbImage} />
      </div>
    </li>
  );
};

const ImageThumb = props => {
  const { image, removeFile, disabled } = props;
  const isPdf = image.link.includes('.pdf');
  const isDocx = image.link.includes('.docx') || image.link.includes('.doc');

  const isFileDocument = isPdf || isDocx;
  const fileLink = isFileDocument ? (isPdf ? pdfImage : docxImage) : image.link;
  return (
    <li className={css.thumb} key={image.id} title={image.name}>
      <ExternalLink href={image.link} className={css.downloadContainer}>
        <DownloadIcon className={css.downloadIcon} />
      </ExternalLink>
      <span
        className={css.removeContainer}
        onClick={() => {
          if (!disabled) {
            removeFile(image.id);
          }
        }}
      >
        <IconClose className={css.removeIcon} />
      </span>

      <div className={css.thumbInner}>
        <img src={fileLink} className={css.thumbImage} />
      </div>
    </li>
  );
};

const FieldDropzoneComponent = props => {
  const isMounted = useRef(false);
  const { input, disabled, accept, files, setFiles, isFiles } = props;

  if (!files || !setFiles) {
    throw new Error('files and setFiles props are required');
  }

  useEffect(() => {
    // We need to avoid running onChange
    // function on first mount
    if (isMounted.current) {
      input.onChange(files);
    } else {
      isMounted.current = true;
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept
      ? accept
      : {
          ['image/*']: '',
        },
    disabled,
    onDrop: acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file, index) =>
          Object.assign(file, {
            index,
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const removeFile = fileIndex => {
    const newFiles = files.filter(f => (f.id || f.index) !== fileIndex);
    setFiles(newFiles);
  };

  const renderThumbs = files.map(file => {
    return file.id ? (
      <ImageThumb key={file.id} image={file} disbaled={disabled} removeFile={removeFile} />
    ) : (
      <FileThumb key={file.index} file={file} disabled={disabled} removeFile={removeFile} />
    );
  });

  const renderThumbsContainer =
    files.length > 0 ? <ul className={css.thumbsContainer}>{renderThumbs}</ul> : null;

  const browseMessage = (
    <span className={css.browseMessage}>
      <FormattedMessage id="FieldDropzone.browseMessage" />
    </span>
  );

  const addImagesWrapperClasses = classNames(css.addImagesWrapper, {
    [css.addImagesWrapperDisabled]: disabled,
  });

  return (
    <div className={css.addImages}>
      <div className={addImagesWrapperClasses} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className={css.addImagesMessage}>
          {isFiles ? (
            <FormattedMessage id="FieldDropzone.addFilesMessage" values={{ browseMessage }} />
          ) : (
            <FormattedMessage id="FieldDropzone.addImagesMessage" values={{ browseMessage }} />
          )}
        </p>
      </div>
      {renderThumbsContainer}
    </div>
  );
};

const FieldDropzone = props => {
  const { rootClassName, className, id, label, disabled, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const inputProps = { id, ...rest };
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <Field component={FieldDropzoneComponent} disabled={disabled} {...inputProps} />
    </div>
  );
};

FieldDropzone.defualtProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldDropzone.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
};

export default FieldDropzone;
