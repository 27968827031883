import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAccount.module.css';

const IconAccount = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.003 11a5.107 5.107 0 1 0 0-10.214 5.107 5.107 0 0 0 0 10.214ZM20.714 21.214a10.198 10.198 0 0 0-19.423 0h19.423Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconAccount.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAccount.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAccount;
