import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal, InviteSingleProfessional, Button } from '..';

import css from './InviteSingleProfessionalModal.module.css';

const InviteSingleProfessionalModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    inviteInProgress,
    inviteError,
    professionals,
    projectId,
    onRedirectToSearchPage,
    queryProfessionalsInProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'InviteSingleProfessionalModal.later' });

  const hasProfessionals = professionals?.length > 0;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {!queryProfessionalsInProgress && !hasProfessionals ? (
        <Fragment>
          <p className={css.modalTitle}>
            <FormattedMessage id="InviteSingleProfessionalModal.noProfessionalsTitle" />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="InviteSingleProfessionalModal.noProfessionalsDescription" />
          </p>
          <Button className={css.modalButton} onClick={onRedirectToSearchPage}>
            <FormattedMessage id="InviteSingleProfessional.noProfessionalsButton" />
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <p className={css.modalTitle}>
            <FormattedMessage id="InviteSingleProfessionalModal.title" />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="InviteSingleProfessionalModal.description" />
          </p>
          <InviteSingleProfessional
            onSubmit={onSubmit}
            inviteInProgress={inviteInProgress}
            inviteError={inviteError}
            queryProfessionalsInProgress={queryProfessionalsInProgress}
            professionals={professionals}
            projectId={projectId}
            onRedirectToSearchPage={onRedirectToSearchPage}
          />
        </Fragment>
      )}
    </Modal>
  );
};

const { bool, string } = PropTypes;

InviteSingleProfessionalModal.defaultProps = {
  className: null,
  rootClassName: null,
  inviteInProgress: false,
  inviteError: null,
};

InviteSingleProfessionalModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  inviteInProgress: bool,
  inviteError: propTypes.error,
};

export default injectIntl(InviteSingleProfessionalModal);
