import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

const SaleCounterOfferButtonMaybe = props => {
  const { className, rootClassName, showButton, onClick } = props;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButton ? (
    <div className={classes}>
      <div className={css.counterOfferSeparator}>
        <FormattedMessage id="TransactionPanel.counterOfferSeparator" />
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton className={css.actionButton} onClick={onClick}>
          <FormattedMessage id="TransactionPanel.counterOffer" />
        </SecondaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleCounterOfferButtonMaybe;
