import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLogs.module.css';

const IconLogs = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_908_395)">
        <path
          d="M6.28571 5.49999V0.785706"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 5.49999V0.785706"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7143 5.49999V0.785706"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6428 3.14282H2.35713C1.48926 3.14282 0.785706 3.84637 0.785706 4.71425V19.6428C0.785706 20.5107 1.48926 21.2143 2.35713 21.2143H19.6428C20.5107 21.2143 21.2143 20.5107 21.2143 19.6428V4.71425C21.2143 3.84637 20.5107 3.14282 19.6428 3.14282Z"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 11H16.5"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 15.7143H11.7857"
          stroke="#4a4a4a"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_908_395">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconLogs.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLogs.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLogs;
