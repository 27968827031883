import * as React from 'react';

const CopyIcon = props => {
  const { className, ...rest } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="none"
      viewBox="-0.285 -0.285 8 8"
      {...rest}
    >
      <g stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3">
        <path d="M2.123 7.165h4.51a.53.53 0 0 0 .532-.531V2.123a.53.53 0 0 0-.531-.53H2.123a.53.53 0 0 0-.53.53v4.51c0 .294.237.532.53.532Z" />
        <path d="M.265 5.572V.796a.53.53 0 0 1 .531-.53h4.776" />
      </g>
    </svg>
  );
};
export default CopyIcon;
