import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconError.module.css';

const IconError = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <g
        className={css.errorStrokeColor}
        fill="none"
        stroke="#ff0000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(.077) scale(1.57143)"
      >
        <path d="M9.12 4.88L4.88 9.12M4.88 4.88l4.24 4.24" />
        <circle cx={7} cy={7} r={6.5} />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconError.defaultProps = {
  className: null,
  rootClassName: null,
};

IconError.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconError;
