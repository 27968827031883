import React from 'react';
import { string } from 'prop-types';

const IconArrowRight = props => {
  const { className } = props;
  return (
    <svg className={className} width={10} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.563 4h6.875M5.625 1.188L8.438 4 5.624 6.813"
        stroke="#f15b26"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconArrowRight.defaultProps = {
  className: null,
};

IconArrowRight.propTypes = {
  className: string,
};

export default IconArrowRight;
