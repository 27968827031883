import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHelp.module.css';

const IconHelp = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <circle
          cx={7}
          cy={7}
          r={6.5}
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="M7 7v3.5"
        />
        <circle
          cx={7}
          cy={4.5}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '0.5',
          }}
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconHelp.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHelp.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHelp;
