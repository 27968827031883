import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUnderline.module.css';

const IconUnderline = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 100 2"
      height="2"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M -0.8641245052143232 2.922258438397829 Q 57.64230251352698 -8 107.22880531165416 4.475958824798307"
        strokeWidth="0.16em"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconUnderline.defaultProps = {
  className: null,
  rootClassName: null,
};

IconUnderline.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUnderline;
