import React from 'react';
import { string } from 'prop-types';

import css from './SectionClientFeatures.module.css';

const EnjoyIcon = props => {
  const { className } = props;
  return (
    <div className={css.iconWrapper}>
      <svg
        className={className}
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 0C7.835 0 0 7.835 0 17.5C0 27.165 7.835 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.835 27.165 0 17.5 0ZM23.3472 11.2682C24.8342 11.2682 26.0395 12.4735 26.0395 13.9605C26.0395 15.4474 24.8342 16.6528 23.3472 16.6528C21.8603 16.6528 20.6549 15.4474 20.6549 13.9605C20.6549 12.4735 21.8603 11.2682 23.3472 11.2682ZM11.6528 11.2682C13.1397 11.2682 14.3451 12.4735 14.3451 13.9605C14.3451 15.4474 13.1397 16.6528 11.6528 16.6528C10.1658 16.6528 8.96046 15.4474 8.96046 13.9605C8.96046 12.4735 10.1658 11.2682 11.6528 11.2682ZM17.5 28.4615C12.9292 28.4615 8.79454 25.5766 7.21138 21.2827L9.37662 20.4844C10.6269 23.8755 13.8915 26.1538 17.5 26.1538C21.1085 26.1538 24.3731 23.8755 25.6234 20.4844L27.7886 21.2827C26.2055 25.5766 22.0708 28.4615 17.5 28.4615Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

EnjoyIcon.defaultProps = { className: null };

EnjoyIcon.propTypes = {
  className: string,
};

export default EnjoyIcon;
