import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import PhoneIcon from '../../containers/ListingPage/PhoneIcon';
import CopyIcon from './CopyIcon';
import classNames from 'classnames';

// Import css
import css from './ShareLinks.module.css';

const iconSize = 19;

const ShareLinks = () => {
  const [isCopied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
  };

  return typeof window !== 'undefined' ? (
    <div className={css.shareDropdown}>
      <FacebookShareButton
        className={css.facebook}
        url={window.location.href}
        resetButtonStyle={false}
      >
        <FacebookIcon size={iconSize} round={true} />
        <FormattedMessage id="ShareLinks.shareFacebook" />
      </FacebookShareButton>
      <TwitterShareButton
        className={css.twitter}
        url={window.location.href}
        resetButtonStyle={false}
      >
        <TwitterIcon size={iconSize} round={true} />
        <FormattedMessage id="ShareLinks.shareTwitter" />
      </TwitterShareButton>
      <LinkedinShareButton
        className={css.linkedin}
        url={window.location.href}
        resetButtonStyle={false}
      >
        <LinkedinIcon size={iconSize} round={true} />
        <FormattedMessage id="ShareLinks.shareLinkedin" />
      </LinkedinShareButton>
      <WhatsappShareButton
        className={css.whatsapp}
        url={window.location.href}
        resetButtonStyle={false}
      >
        <WhatsappIcon size={iconSize} round={true} />
        <FormattedMessage id="ShareLinks.shareWhatsapp" />
      </WhatsappShareButton>
      <EmailShareButton className={css.email} url={window.location.href} resetButtonStyle={false}>
        <EmailIcon size={iconSize} round={true} />
        <FormattedMessage id="ShareLinks.shareEmail" />
      </EmailShareButton>
      <a
        className={classNames(css.localButton, css.phone)}
        href={`sms://;?&body=${window.location.href}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={css.localIconWrapper}>
          <PhoneIcon className={css.localIcon} />
        </span>
        <FormattedMessage id="ShareLinks.sharePhone" />
      </a>
      <PrimaryButton
        className={classNames(css.localButton, css.copy)}
        type="button"
        onClick={handleCopyLink}
      >
        <span className={css.localIconWrapper}>
          <CopyIcon className={css.localIcon} />
        </span>
        {isCopied ? (
          <FormattedMessage id="ShareLinks.linkCopied" />
        ) : (
          <FormattedMessage id="ShareLinks.copyLink" />
        )}
      </PrimaryButton>
    </div>
  ) : null;
};

export default ShareLinks;
