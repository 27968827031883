import React from 'react';
import { bool } from 'prop-types';
import SelectButtonFilterPlain from './SelectButtonFilterPlain';
import SelectButtonFilterPopup from './SelectButtonFilterPopup';

const SelectButtonFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectButtonFilterPopup {...rest} />
  ) : (
    <SelectButtonFilterPlain {...rest} />
  );
};

SelectButtonFilter.defaultProps = {
  showAsPopup: false,
};

SelectButtonFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectButtonFilter;
