import React from 'react';
import { bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, IconPhoto } from '../../components';
import moment from 'moment';

import css from './TransactionPanel.module.css';

const DailyLogs = props => {
  const {
    isCustomer,
    dailyLogs,
    fetchDailyLogsInProgress,
    onOpenDailyLogsModal,
    onViewLog,
  } = props;

  const showCreateLogButton = isCustomer;

  if (fetchDailyLogsInProgress) {
    return (
      <div className={css.dailyLogsWrapper}>
        <p className={css.dailyLogsLoading}>
          <FormattedMessage id="TransactionPanel.dailyLogsLoading" />
        </p>
      </div>
    );
  }

  return (
    <div className={css.dailyLogsWrapper}>
      {showCreateLogButton ? (
        <div className={css.dailyLogsSubmitWrapper}>
          <InlineTextButton className={css.dailyLogsButton} onClick={onOpenDailyLogsModal}>
            <FormattedMessage id="TransactionPanel.dailyLogsButton" />
          </InlineTextButton>
        </div>
      ) : null}
      <ul className={css.dailyLogs}>
        {dailyLogs.map(log => {
          const logDate = moment(log.date).format('ddd, MMM DD');
          return (
            <li key={log.id} className={css.log}>
              <div className={css.logDateWrapper}>
                <h2 className={css.logDate}>{logDate}</h2>
                <InlineTextButton className={css.logView} onClick={() => onViewLog(log.id)}>
                  <FormattedMessage id="TransactionPanel.viewDailyLogs" />
                </InlineTextButton>
              </div>
              <p className={css.logNotes}>{log.notes || 'N / A'}</p>
              <span className={css.logAttachments}>
                <IconPhoto className={css.logAttachmentsIcon} />
                {`${log.attachments?.length || 0} attachments`}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

DailyLogs.defaultProps = {
  isCustomer: false,
  dailyLogs: [],
  onOpenDailyLogsModal: null,
  onViewLog: null,
};

DailyLogs.propTypes = {
  isCustomer: bool.isRequired,
  dailyLogs: propTypes.dailyLogs,
  onOpenDailyLogsModal: func.isRequired,
  onViewLog: func.isRequired,
};

export default DailyLogs;
