import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { queryListingsAPI, queryTransactionsAPI } from '../../util/api';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
} from '../../util/transaction';
import config from '../../config';
import axios from 'axios';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/CalendarPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/CalendarPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/CalendarPage/FETCH_LISTINGS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/CalendarPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/CalendarPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/CalendarPage/FETCH_TRANSACTIONS_ERROR';

export const ADD_OWN_ENTITIES = 'app/CalendarPage/ADD_OWN_ENTITIES';

export const ALLOWED_TRANSITIONS = [
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
];

// ================ Reducer ================ //

const initialState = {
  queryListingsInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  queryTransactionsInProgress: false,
  queryTransactionsError: null,
  transactionRefs: [],
  ownEntities: {},
};

const resultIds = data => data.data.filter(l => l.attributes.publicData.dates).map(l => l.id);

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const CalendarPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        currentPageResultIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryListingsInProgress: false, queryListingsError: payload };

    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryTransactionsInProgress: true,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      const transactions = payload.data.data;
      return {
        ...state,
        transactionRefs: entityRefs(transactions),
        queryTransactionsInProgress: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, queryTransactionsInProgress: false, queryTransactionsError: payload };

    default:
      return state;
  }
};

export default CalendarPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryTransactionsRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
});

export const queryTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});

export const queryTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryOwnListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const params = {
    authorId: userId,
    pub_type: config.listingTypeProject,
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return axios
    .get(queryListingsAPI, { params })
    .then(async response => {
      const listings = response.data.data.data;

      const promises = await listings.map(async l => {
        return new Promise(async resolve => {
          const txResponse = await axios.get(queryTransactionsAPI, {
            params: { listingId: l.id.uuid },
          });
          const transaction = txResponse.data.data.data?.find(t =>
            ALLOWED_TRANSITIONS.includes(t.attributes.lastTransition)
          );
          const transactionMaybe = transaction ? { transaction } : {};
          resolve({
            ...l,
            ...transactionMaybe,
          });
        });
      });

      const listingsResponse = await Promise.all(promises);
      const finalResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            ...response.data.data,
            data: listingsResponse,
          },
        },
      };

      dispatch(addMarketplaceEntities(finalResponse.data));
      dispatch(queryListingsSuccess(finalResponse.data));

      return response.data.data;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const queryOwnTransactions = () => (dispatch, getState, sdk) => {
  dispatch(queryTransactionsRequest());

  return sdk.transactions
    .query({
      only: 'order',
      lastTransitions: ALLOWED_TRANSITIONS,
      include: ['listing', 'listing.images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryTransactionsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryTransactionsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  return sdk.currentUser.show().then(response => {
    const user = response.data.data;
    return Promise.all([
      dispatch(queryOwnListings(user.id.uuid)),
      dispatch(queryOwnTransactions()),
    ]);
  });
};
