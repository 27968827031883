import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, ArrowButton, FieldCurrencyInput } from '../../components';
import { propTypes } from '../../util/types';
import config from '../../config';
import classNames from 'classnames';

import css from './CounterOfferForm.module.css';

const CounterOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        counterOfferError,
        intl,
      } = fieldRenderProps;

      const offerLabel = intl.formatMessage({
        id: 'CounterOfferForm.offerLabel',
      });
      const offerPlaceholder = intl.formatMessage({
        id: 'CounterOfferForm.offerPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <FieldCurrencyInput
            id={formId ? `${formId}.offer` : 'offer'}
            name="offer"
            label={offerLabel}
            placeholder={offerPlaceholder}
            currencyConfig={config.currencyConfig}
          />

          {counterOfferError ? (
            <p className={css.errorPlaceholder}>
              <FormattedMessage id="CounterOfferForm.counterOfferError" />
            </p>
          ) : null}
          <ArrowButton
            type="submit"
            className={css.submitButton}
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            <FormattedMessage id="CounterOfferForm.submitButtonText" />
          </ArrowButton>
        </Form>
      );
    }}
  />
);

CounterOfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  counterOfferError: null,
};

CounterOfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  counterOfferError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CounterOfferForm = compose(injectIntl)(CounterOfferFormComponent);

CounterOfferForm.displayName = 'CounterOfferForm';

export default CounterOfferForm;
