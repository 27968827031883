import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { showUser } from '../../containers/ProfilePage/ProfilePage.duck';
import { addCommentAPI, deleteCommentAPI } from '../../util/api';
import axios from 'axios';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/PostPage/SET_INITIAL_STATE';

export const SHOW_POST_REQUEST = 'app/PostPage/SHOW_POST_REQUEST';
export const SHOW_POST_SUCCESS = 'app/PostPage/SHOW_POST_SUCCESS';
export const SHOW_POST_ERROR = 'app/PostPage/SHOW_POST_ERROR';

export const ADD_COMMENT_REQUEST = 'app/PostPage/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'app/PostPage/ADD_COMMENT_SUCCUESS';
export const ADD_COMMENT_ERROR = 'app/PostPage/ADD_COMMENT_ERROR';

export const DELETE_COMMENT_REQUEST = 'app/PostPage/DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'app/PostPage/DELETE_COMMENT_SUCCUESS';
export const DELETE_COMMENT_ERROR = 'app/PostPage/DELETE_COMMENT_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  postId: null,
  showPostInProgress: false,
  showPostError: null,
  addCommentInProgress: false,
  addCommentError: null,
  deletedCommentIds: [],
  currentDeleteCommentId: null,
  deleteCommentInProgress: false,
  deleteCommentError: null,
};

export default function postPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SHOW_POST_REQUEST:
      return {
        ...state,
        postId: payload.postId,
        showPostInProgress: true,
        showPostError: null,
      };
    case SHOW_POST_SUCCESS:
      return {
        ...state,
        showPostInProgress: false,
        showPostError: null,
      };
    case SHOW_POST_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showPostInProgress: false, showPostError: payload };

    case ADD_COMMENT_REQUEST:
      return {
        ...state,
        addCommentInProgress: true,
        addCommentError: null,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,

        addCommentInProgress: false,
        addCommentError: null,
      };
    case ADD_COMMENT_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, addCommentInProgress: false, addCommentError: payload };

    case DELETE_COMMENT_REQUEST:
      return {
        ...state,
        currentDeleteCommentId: payload.commentId,
        deleteCommentInProgress: true,
        deleteCommentError: null,
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        deletedCommentIds: [...state.deletedCommentIds, payload.commentId],
        deleteCommentInProgress: false,
        deleteCommentError: null,
      };
    case DELETE_COMMENT_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, deleteCommentInProgress: false, deleteCommentError: payload };

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: state.postId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.postId,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showPostRequest = postId => ({
  type: SHOW_POST_REQUEST,
  payload: { postId },
});
export const showPostSuccess = () => ({
  type: SHOW_POST_SUCCESS,
});
export const showPostError = e => ({
  type: SHOW_POST_ERROR,
  error: true,
  payload: e,
});

export const addCommentRequest = () => ({
  type: ADD_COMMENT_REQUEST,
});
export const addCommentSuccess = () => ({
  type: ADD_COMMENT_SUCCESS,
});
export const addCommentError = e => ({
  type: ADD_COMMENT_ERROR,
  error: true,
  payload: e,
});

export const deleteCommentRequest = commentId => ({
  type: DELETE_COMMENT_REQUEST,
  payload: { commentId },
});
export const deleteCommentSuccess = commentId => ({
  type: DELETE_COMMENT_SUCCESS,
  payload: { commentId },
});
export const deleteCommentError = e => ({
  type: DELETE_COMMENT_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showPost = postId => (dispatch, getState, sdk) => {
  dispatch(showPostRequest(postId));

  return sdk.listings
    .show({
      id: postId,
      include: ['images', 'author', 'author.profileImage'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(showPostSuccess());
      dispatch(addMarketplaceEntities(response));

      const comments = response.data.data.attributes.publicData.comments;

      if (comments) {
        const usersFromComments = [...new Set(comments.map(c => c.userId))];
        usersFromComments.map(id => dispatch(showUser(id)));
      }

      return postId;
    })
    .catch(e => dispatch(showPostError(storableError(e))));
};

export const addComment = params => (dispatch, getState, sdk) => {
  dispatch(addCommentRequest());

  return axios
    .get(addCommentAPI, { params })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(addCommentSuccess());
      dispatch(showUser(params.userId));

      return response;
    })
    .catch(e => {
      dispatch(addCommentError(storableError(e)));
    });
};

export const deleteComment = params => (dispatch, getState, sdk) => {
  dispatch(deleteCommentRequest(params.commentId));

  return axios
    .get(deleteCommentAPI, { params })
    .then(response => {
      dispatch(deleteCommentSuccess(params.commentId));

      return response;
    })
    .catch(e => {
      dispatch(deleteCommentError(storableError(e)));
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(closeListingError(storableError(e)));
    });
};

export const loadData = (params, search) => {
  const postId = new UUID(params.id);

  return showPost(postId);
};
