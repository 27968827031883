import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconFilledCheckbox } from '../../components';
import classNames from 'classnames';

import mainImage from './images/image.png';
import css from './SectionIntro.module.css';

const serviceItem = translationId => {
  return (
    <li className={css.service}>
      <IconFilledCheckbox className={css.serviceIcon} />
      <FormattedMessage id={translationId} />
    </li>
  );
};

const image = (src, alt, className) => {
  return <img className={css.mainImage} src={src} alt={alt} loading="lazy" />;
};

const SectionIntro = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.leftContent}>
        <div className={css.subTitle}>
          <FormattedMessage id="SectionIntro.subTitle" />
        </div>
        <div className={css.title}>
          <FormattedMessage id="SectionIntro.title" />
        </div>
        <p className={css.text}>
          <FormattedMessage id="SectionIntro.text" />
        </p>
        <ul className={css.services}>
          {serviceItem('SectionIntro.interiorPainting')}
          {serviceItem('SectionIntro.exteriorPainting')}
          {serviceItem('SectionIntro.handymanServices')}
          {serviceItem('SectionIntro.pressureWashing')}
          {serviceItem('SectionIntro.wallpaperRemoval')}
          {serviceItem('SectionIntro.drywallRepair')}
        </ul>
      </div>
      <div className={css.rightContent}>
        {image(
          mainImage,
          intl.formatMessage({ id: 'SectionIntro.interiorPainting' }),
          css.mainImage
        )}
        {/* <div className={css.smallImages}>
          {image(secondImage, intl.formatMessage({ id: 'SectionIntro.exteriorPainting' }))}
          {image(thirdImage, intl.formatMessage({ id: 'SectionIntro.handymanServices' }))}
        </div> */}
      </div>
    </div>
  );
};

SectionIntro.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionIntro.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionIntro;
