import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './NotificationBox.module.css';

const NotificationBox = props => {
  const { rootClassName, className, show, textComponent, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (!textComponent && !children) {
    throw new Error('You need to pass the textComponent or children props.');
  }
  return show ? (
    <div className={classes}>
      {textComponent ? <p className={css.notificationBoxText}>{textComponent}</p> : children}
    </div>
  ) : null;
};

const { string, node, object, oneOfType } = PropTypes;

NotificationBox.defaultProps = {
  rootClassName: null,
  className: null,
};

NotificationBox.propTypes = {
  rootClassName: string,
  className: string,
  children: node,
  textComponent: oneOfType([object, string]),
};

export default NotificationBox;
