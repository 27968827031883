import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { moveArrayElement, isUserPro } from '../../util/data';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const {
    className,
    rootClassName,
    selectedPageName,
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
    currentProfessionalUserProfile,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (isCurrentUserProfessional && !currentUser) {
    return null;
  }

  const renderInvitationsPageLink = {
    text: <FormattedMessage id="ManageListingsPage.invitations" />,
    selected: selectedPageName === 'InvitationsPage',
    hide: isCurrentUserClient,
    linkProps: {
      name: 'InvitationsPage',
    },
  };
  const renderProfessionalInboxPage = {
    text: <FormattedMessage id="ManageListingsPage.inbox" />,
    selected: selectedPageName === 'InboxPageProfessional',
    linkProps: {
      name: 'InboxPageProfessional',
    },
  };
  const renderEditProfessionalPageLink = {
    text: <FormattedMessage id="ManageListingsPage.editProfessionalProfile" />,
    selected: selectedPageName === 'EditProfessionalPage',
    linkProps: {
      name: 'EditProfessionalPage',
      params: {
        id: currentProfessionalUserProfile?.id,
        slug: currentProfessionalUserProfile?.slug || '',
        type: currentProfessionalUserProfile?.type || 'draft',
        tab: 'description',
      },
    },
  };
  const renderCreateProfessionalPageLink = {
    text: <FormattedMessage id="ManageListingsPage.createProfessionalProfile" />,
    selected: selectedPageName === 'NewProfessionalPage',
    linkProps: {
      name: 'NewProfessionalPage',
    },
  };
  const renderProfileSettingsLink = {
    text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
    selected: selectedPageName === 'ProfileSettingsPage',
    linkProps: {
      name: 'ProfileSettingsPage',
    },
  };
  const renderCalendarPageLink = {
    text: <FormattedMessage id="ManageListingsPage.calendar" />,
    selected: selectedPageName === 'CalendarPage',
    linkProps: {
      name: 'CalendarPage',
    },
  };
  const renderAccountSettingsPageLink = {
    text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  };

  const tabs = isCurrentUserProfessional
    ? currentUserHasProfile && currentProfessionalUserProfile
      ? [
          renderInvitationsPageLink,
          renderProfessionalInboxPage,
          renderCalendarPageLink,
          renderEditProfessionalPageLink,
          renderAccountSettingsPageLink,
        ]
      : [renderCalendarPageLink, renderCreateProfessionalPageLink, renderAccountSettingsPageLink]
    : [renderCalendarPageLink, renderProfileSettingsLink, renderAccountSettingsPageLink];

  return (
    <div>
      <LinkTabNavHorizontal
        className={classes}
        tabRootClassName={css.tab}
        tabs={tabs}
        skin="dark"
      />
    </div>
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
  } = state.user;

  const currentProfessionalUserProfile = currentUser?.attributes?.profile?.publicData?.profile;

  return {
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
    currentUserHasProfile,
    currentProfessionalUserProfile,
  };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
