import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPlus.module.css';

const IconPlus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7 .54v13M.5 7h13" transform="scale(1.71429)" />
    </svg>
  );
};

const { string } = PropTypes;

IconPlus.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPlus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlus;
