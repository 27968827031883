import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShield.module.css';

const IconShield = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="48"
      width="48"
    >
      <g transform="scale(3.42857)">
        <path d="M7.36 13.43h0a1 1 0 0 1-.72 0h0a9.67 9.67 0 0 1-6.14-9V1.5a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2.92a9.67 9.67 0 0 1-6.14 9.01Z" />
        <circle cx={7} cy={5.5} r={2.5} />
        <path d="M3.25 11.19a5 5 0 0 1 7.5 0" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconShield.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShield.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShield;
