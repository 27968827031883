import React from 'react';
import { string } from 'prop-types';

import css from './SectionClientFeatures.module.css';

const GetJobsIcon = props => {
  const { className } = props;
  return (
    <div className={css.iconWrapper}>
      <svg
        className={className}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="35"
        height="35"
      >
        <path d="M 5 4 C 3.895 4 3 4.895 3 6 L 3 9 L 3 11 L 22 11 L 27 11 L 27 8 C 27 6.895 26.105 6 25 6 L 12.199219 6 L 11.582031 4.9707031 C 11.221031 4.3687031 10.570187 4 9.8671875 4 L 5 4 z M 2.5019531 13 C 1.4929531 13 0.77040625 13.977406 1.0664062 14.941406 L 4.0351562 24.587891 C 4.2941563 25.426891 5.0692656 26 5.9472656 26 L 15 26 L 24.052734 26 C 24.930734 26 25.705844 25.426891 25.964844 24.587891 L 28.933594 14.941406 C 29.229594 13.977406 28.507047 13 27.498047 13 L 15 13 L 2.5019531 13 z" />
      </svg>
    </div>
  );
};

GetJobsIcon.defaultProps = { className: null };

GetJobsIcon.propTypes = {
  className: string,
};

export default GetJobsIcon;
