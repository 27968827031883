import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCheck, PrimaryButton, SecondaryButton, IconBadge, NamedLink } from '..';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import classNames from 'classnames';

import css from './PlanCard.module.css';

const { Money } = sdkTypes;

const PRO_PLAN_KEY = 'pro';
const BASIC_PLAN_KEY = 'basic';

// Generate unique feature keys
const generateFeatureKey = feature => feature.replaceAll(' ', '-').toLowerCase();

const buttonNoticeMessage = (isPlanActive, isProPlan, isAnnually, isMonthlySubscription) => {
  let translationId;
  if (isPlanActive) {
    if (isMonthlySubscription) {
      translationId = 'PlanCard.buttonActiveToAnnual';
    } else {
      translationId = 'PlanCard.buttonActiveNotice';
    }
  } else if (isProPlan) {
    if (isAnnually) {
      translationId = 'PlanCard.buttonAnnuallyNotice';
    } else {
      translationId = 'PlanCard.buttonMonthlyNotice';
    }
  } else {
    translationId = 'PlanCard.buttonNotice';
  }

  const contactLink = (
    <NamedLink name="ContactPage">
      <FormattedMessage id="PlanCard.contact" />
    </NamedLink>
  );

  return <FormattedMessage id={translationId} values={{ contactLink }} />;
};

const PlanCard = props => {
  const {
    rootClassName,
    className,
    intl,
    plan,
    isAnnually,
    currentUser,
    isCurrentUserClient,
    isCurrentUserProfessional,
    currentUserPlan,
    currentUserType,
    isPlanJustActivated,
    subscriptionType,
    planFor,
    handleSubscription,
    redirectToSignupPage,
    setCancelSubscriptionModalOpen,
    setUpgradeModalOpen,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = classNames(rootClassName || css.root, className);

  const isProPlan = plan.key === PRO_PLAN_KEY;
  const isBasicPlan = plan.key === BASIC_PLAN_KEY;
  const isPlanActive =
    currentUserType === planFor &&
    (currentUserPlan === plan.key || (isPlanJustActivated && isProPlan));
  const showDowngradeButtonMessage = isBasicPlan && !isPlanActive && currentUserType === planFor;
  const isMonthlySubscription =
    subscriptionType === 'monthly' && currentUserType === planFor && isAnnually;
  const hasStripeSubscription =
    currentUser?.attributes?.profile?.publicData?.stripeSubscriptionDetails;

  const savingPrice = plan?.price?.saving
    ? formatMoney(intl, new Money(plan.price.saving, config.currency))
    : null;
  const price = plan.price
    ? new Money(isAnnually ? plan.price.annual : plan.price.monthly, config.currency)
    : null;
  const formattedPrice = price ? formatMoney(intl, price) : null;

  const onHandleSubscription = async () => {
    if (currentUser?.id?.uuid) {
      setIsLoading(true);
      await handleSubscription(isAnnually ? plan.lookupKeys.annual : plan.lookupKeys.monthly);
      setIsLoading(false);
    } else {
      redirectToSignupPage();
    }
  };

  const submitInProgress = isLoading;
  const submitDisabled =
    !!(planFor === config.userTypeClient && isCurrentUserProfessional) ||
    !!(planFor === config.userTypeProfessional && isCurrentUserClient && isBasicPlan) ||
    !!(isBasicPlan && isPlanActive);

  return (
    <div className={classes}>
      <div className={css.topWrapper}>
        <div className={css.infoWrapper}>
          <div className={css.info}>
            <h3 className={css.name}>{plan.label}</h3>
            <h3 className={css.price}>
              {formattedPrice ? (
                isAnnually ? (
                  <FormattedMessage
                    id="PlanCard.price"
                    values={{
                      price: <span className={css.savingPriceDisabled}>{savingPrice}</span>,
                      savingPrice: <span className={css.savingPrice}>{formattedPrice}</span>,
                    }}
                  />
                ) : (
                  formattedPrice
                )
              ) : (
                <FormattedMessage id="PlanCard.freePrice" />
              )}
            </h3>
          </div>
          {isPlanActive ? <IconBadge className={css.badgeIcon} isFilled={isProPlan} /> : null}
        </div>
        <ul className={css.features}>
          {plan.features.map(f => {
            return (
              <li key={generateFeatureKey(f)} className={css.feature}>
                <IconCheck className={css.checkIcon} />
                <div className={css.featureName}>{f}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={css.bottomWrapper}>
        {plan.highlighted ? (
          <PrimaryButton
            className={css.submitButton}
            onClick={() => {
              if (!hasStripeSubscription) {
                return;
              }

              if (planFor === config.userTypeProfessional && isCurrentUserClient) {
                setUpgradeModalOpen(true);
              } else {
                onHandleSubscription();
              }
            }}
            inProgress={submitInProgress}
            disabled={submitDisabled || !hasStripeSubscription}
          >
            {isProPlan && isPlanActive ? (
              hasStripeSubscription ? (
                <FormattedMessage id="PlanCard.buttonTextManage" />
              ) : (
                <FormattedMessage id="PlanCard.buttonTextActive" />
              )
            ) : planFor === config.userTypeProfessional && isCurrentUserClient ? (
              <FormattedMessage id="PlanCard.buttonTextUpgrade" />
            ) : (
              <FormattedMessage id="PlanCard.buttonText" values={{ name: plan.label }} />
            )}
          </PrimaryButton>
        ) : (
          <SecondaryButton
            className={css.submitButton}
            onClick={() => {
              if (currentUser?.id?.uuid) {
                setCancelSubscriptionModalOpen(true);
              } else {
                redirectToSignupPage();
              }
            }}
            disabled={submitDisabled}
          >
            {showDowngradeButtonMessage ? (
              <FormattedMessage id="PlanCard.buttonTextDowngrade" values={{ name: plan.label }} />
            ) : (
              <FormattedMessage id="PlanCard.buttonText" values={{ name: plan.label }} />
            )}
          </SecondaryButton>
        )}
        <div className={css.submitButtonNotice}>
          {buttonNoticeMessage(isPlanActive, isProPlan, isAnnually, isMonthlySubscription)}
        </div>
      </div>
    </div>
  );
};

const { string, object } = PropTypes;

PlanCard.defaultProps = {
  rootClassName: null,
  className: null,
};

PlanCard.propTypes = {
  rootClassName: string,
  className: string,
  plan: object.isRequired,
};

export default PlanCard;
