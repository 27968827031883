import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, ArrowButton, ResponsiveImage } from '../../components';

import css from './InviteProfessional.module.css';

const InviteCard = props => {
  const {
    listing,
    inviteInProgress,
    inviteError,
    currentInviteId,
    userInvitations,
    onSubmit,
  } = props;

  const { title } = listing.attributes;
  const listingId = listing.id.uuid;

  // Listing fields
  const listingLocation = listing.attributes.publicData.listingLocation;
  const hasImages = listing?.images?.length > 0;
  const firstImage = hasImages ? listing?.images[0] : null;

  // User invitations
  const userInvitationIds = userInvitations?.map(l => l.id);
  const isAlreadyInvited = userInvitationIds?.includes(listingId);

  // Is invitation currently going on
  const isCurrentlyInviting = currentInviteId === listingId;

  const errorMessage =
    inviteError && isCurrentlyInviting ? (
      <p className={css.error}>
        <FormattedMessage id="InviteProfessional.errorMessage" />
      </p>
    ) : null;

  const buttonDisabled = !!inviteError || !!isAlreadyInvited;
  return (
    <div key={listingId} className={css.listingWrapper}>
      <div className={css.listingContentWrapper}>
        <div className={css.listingContent}>
          <ResponsiveImage
            rootClassName={css.listingImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
          <div className={css.listingInfo}>
            <h4 className={css.listingTitle}>{title}</h4>
            <span className={css.listingLocation}>{listingLocation}</span>
          </div>
        </div>
        {isAlreadyInvited ? (
          <Button className={css.arrowButton} disabled={buttonDisabled}>
            <FormattedMessage id="InviteProfessional.invitedButtonText" />
          </Button>
        ) : (
          <ArrowButton
            className={css.arrowButton}
            onClick={onSubmit}
            inProgress={inviteInProgress && isCurrentlyInviting}
            disabled={buttonDisabled}
          >
            <FormattedMessage id="InviteProfessional.inviteButtonText" />
          </ArrowButton>
        )}
      </div>
      {errorMessage}
    </div>
  );
};

InviteCard.defaultProps = {
  inviteError: null,
  inviteInProgress: false,
};

const { bool, func, array } = PropTypes;

InviteCard.propTypes = {
  userVerifications: array,
  onSubmit: func.isRequired,
  inviteError: propTypes.error,
  inviteInProgress: bool.isRequired,
};

export default InviteCard;
