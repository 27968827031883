import React from 'react';
import { string } from 'prop-types';
import { IconAccount, IconPhoto, IconReviews } from '../../components';
import classNames from 'classnames';

import TabNav from './TabNav';
import TabPanel from './TabPanel';
import css from './ProfileTabs.module.css';

export const ABOUT = 'about';
export const PHOTOS = 'photos';
export const REVIEWS = 'reviews';

const TABS = [ABOUT, PHOTOS, REVIEWS];

// Render TabNav label
const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === ABOUT) {
    key = 'ProfessionalPage.tabLabelAbout';
  } else if (tab === PHOTOS) {
    key = 'ProfessionalPage.tabLabelPhotos';
  } else if (tab === REVIEWS) {
    key = 'ProfessionalPage.tabLabelReviews';
  }

  return intl.formatMessage({ id: key });
};

// Render TabNav icon
const tabIcon = tab => {
  switch (tab) {
    case ABOUT:
      return <IconAccount className={classNames(css.tabNavIcon, css.tabNavAccountIcon)} />;
    case PHOTOS:
      return <IconPhoto className={classNames(css.tabNavIcon, css.tabNavPhotoIcon)} />;
    case REVIEWS:
      return <IconReviews className={classNames(css.tabNavIcon, css.tabNavReviewsIcon)} />;

    default:
      return null;
  }
};

const ProfileTabs = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <TabNav
        tabs={TABS}
        tabLabel={tab => tabLabel(intl, tab)}
        tabIcon={tab => tabIcon(tab)}
        {...props}
      />
      <TabPanel {...props} />
    </div>
  );
};

ProfileTabs.defaultProps = {
  className: null,
  rootClassName: null,
};

ProfileTabs.propTypes = {
  className: string,
  rootClassName: string,
};

export default ProfileTabs;
