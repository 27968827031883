import { storableError } from '../../util/errors';
import { queryOwnListings } from '../ManagementPage/ManagementPage.duck';

// ================ Action types ================ //

export const FETCH_COSTS_REQUEST = 'app/EstimatePage/FETCH_COSTS_REQUEST';
export const FETCH_COSTS_SUCCESS = 'app/EstimatePage/FETCH_COSTS_SUCCESS';
export const FETCH_COSTS_ERROR = 'app/EstimatePage/FETCH_COSTS_ERROR';

export const UPDATE_COSTS = 'app/EstimatePage/UDATE_COSTS';

// ================ Reducer ================ //

const initialState = {
  fetchCostsInProgress: false,
  fetchCostsError: null,
  costs: [],
};

const estimatePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COSTS_REQUEST:
      return {
        ...state,
        fetchCostsInProgress: true,
        fetchCostsError: null,
      };
    case FETCH_COSTS_SUCCESS:
      return {
        ...state,
        fetchCostsInProgress: false,
        fetchCostsError: null,
        costs: payload.costs,
      };
    case FETCH_COSTS_ERROR:
      return {
        ...state,
        fetchCostsInProgress: false,
        fetchCostsError: payload,
      };

    case UPDATE_COSTS:
      return {
        ...state,
        costs: payload.costs,
      };

    default:
      return state;
  }
};

export default estimatePageReducer;

// ================ Action creators ================ //

export const fetchCostsRequest = () => ({
  type: FETCH_COSTS_REQUEST,
});
export const fetchCostsSuccess = costs => ({
  type: FETCH_COSTS_SUCCESS,
  payload: { costs },
});
export const fetchCostsError = e => ({
  type: FETCH_COSTS_ERROR,
  error: true,
  payload: e,
});

export const updateCost = costs => ({
  type: UPDATE_COSTS,
  payload: { costs },
});

// ================ Thunks ================ //

export const fetchCosts = listing => (dispatch, getState, sdk) => {
  dispatch(fetchCostsRequest());

  try {
    const { publicData } = listing.attributes;
    const costsFromPublicData = publicData.costs || [];

    dispatch(fetchCostsSuccess(costsFromPublicData));
  } catch (e) {
    dispatch(fetchCostsError(storableError(e)));
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch(queryOwnListings()).then(response => {
    const listings = response.data.data;
    const selectedListingId = params.id || listings[0]?.id?.uuid;
    const selectedListing = listings.find(l => l.id.uuid === selectedListingId);

    return dispatch(fetchCosts(selectedListing));
  });
};
