import { queryOwnListings, findListingTransaction } from '../ManagementPage/ManagementPage.duck';

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch(queryOwnListings()).then(response => {
    const listings = response.data.data;
    const selectedListingId = listings[0]?.id?.uuid;

    const listingId = params.id || selectedListingId;
    const currentUser = getState().user.currentUser;

    return dispatch(findListingTransaction(listingId, currentUser.id.uuid));
  });
};
