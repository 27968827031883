import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconWarning } from '../../components';

import css from './TransactionPanel.module.css';

const AdditionalInformations = props => {
  const { isTxAccepted, isProvider, homeAddress, specialNotes } = props;

  if (!isTxAccepted && !isProvider) {
    return (
      <div className={css.infoContent}>
        <p className={css.dailyLogsMessage}>
          <IconWarning className={css.dailyLogsMessageIcon} />

          <FormattedMessage id="TransactionPanel.additionalInformationsDisallowed" />
        </p>
      </div>
    );
  } else {
    if (!homeAddress && !specialNotes) {
      return (
        <div className={css.infoContent}>
          <p className={css.infoContentMessage}>
            <FormattedMessage id="TransactionPanel.additionalInformationsNoInfo" />
          </p>
        </div>
      );
    }

    return (
      <div className={css.infoContent}>
        <ul className={css.infoContentList}>
          <li className={css.infoContentListItem}>
            <span>
              <FormattedMessage id="TransactionPanel.additionalInformationsAddress" />
            </span>
            {homeAddress}
          </li>
          <li className={css.infoContentListItem}>
            <span>
              <FormattedMessage id="TransactionPanel.additionalInformationsNotes" />
            </span>
            {specialNotes}
          </li>
        </ul>
      </div>
    );
  }
};

export default AdditionalInformations;
