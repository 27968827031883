import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  ArrowButton,
  FieldCurrencyInput,
  IconSpinner,
  IconHelp,
  EnquiryBreakdown,
} from '../../components';
import { validQuote } from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';
import ReactTooltip from 'react-tooltip';

import css from './QuoteForm.module.css';

const ACCEPT_FILES =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const USER_ROLE = 'customer';

const QuoteFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        values,
        formId,
        handleSubmit,
        inProgress,
        sendQuoteError,
        intl,
        formattedPrice,
        isOwnListing,
        form,
        isClosed,
        isCurrentUserClient,
        isProfessionalProfileSetup,
        currentUser,
      } = fieldRenderProps;

      const budgetLabel = intl.formatMessage({
        id: 'QuoteForm.budgetLabel',
      });
      const budgetPlaceholder = intl.formatMessage(
        {
          id: 'QuoteForm.budgetPlaceholder',
        },
        {
          budget: formattedPrice,
        }
      );

      const documentLabel = intl.formatMessage({ id: 'QuoteForm.uploadDocument' });

      const isQuoteValid = validQuote({
        currentUser,
        isCurrentUserClient,
        isProfessionalProfileSetup,
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled =
        submitInProgress || isOwnListing || isClosed || !values.budget || isQuoteValid;

      const uploadingOverlay =
        values.document && inProgress ? (
          <div className={css.thumbnailLoading}>
            <IconSpinner />
          </div>
        ) : null;

      const showBreakdown = false;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <FieldCurrencyInput
            id={formId ? `${formId}.budget` : 'budget'}
            name="budget"
            className={css.field}
            label={budgetLabel}
            placeholder={budgetPlaceholder}
            currencyConfig={config.currencyConfig}
          />

          <div className={css.documentWrapper}>
            <Field
              id={formId ? `${formId}.document` : 'document'}
              name="document"
              accept={ACCEPT_FILES}
              form={null}
              label={documentLabel}
              type="file"
              disabled={false}
            >
              {fieldprops => {
                const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const onChange = e => {
                  const file = e.target.files[0];
                  form.change(`document`, file);
                  form.blur(`document`);
                };
                const inputProps = { accept, id: name, name, onChange, type };
                return (
                  <div className={css.addDocumentWrapper}>
                    <div className={css.aspectRatioWrapper}>
                      {fieldDisabled ? null : (
                        <input {...inputProps} className={css.addDocumentInput} />
                      )}
                      <label htmlFor={name} className={css.addDocument}>
                        {values.document ? values.document.name : label}
                      </label>
                      {uploadingOverlay}
                    </div>
                  </div>
                );
              }}
            </Field>
            <span
              className={css.tooltip}
              data-tip={intl.formatMessage({ id: 'QuoteForm.toolTip' })}
            >
              <IconHelp className={css.tooltipIcon} />
              <ReactTooltip className={css.tip} />
            </span>
          </div>

          {showBreakdown && values.budget ? (
            <EnquiryBreakdown
              className={css.enquiryBreakdown}
              userRole={USER_ROLE}
              transactionQuote={{
                price: {
                  ...values.budget,
                },
              }}
              intl={intl}
            />
          ) : null}
          <div className={css.submitButtonContainer}>
            {sendQuoteError ? (
              <p className={css.error}>
                <FormattedMessage id="QuoteForm.sendQuoteError" />
              </p>
            ) : null}
            <ArrowButton
              type="submit"
              className={css.submitButton}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="QuoteForm.submitButtonText" />
            </ArrowButton>
          </div>
        </Form>
      );
    }}
  />
);

QuoteFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendQuoteError: null,
};

QuoteFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  sendQuoteError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const QuoteForm = compose(injectIntl)(QuoteFormComponent);

QuoteForm.displayName = 'QuoteForm';

export default QuoteForm;
