/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EditProfessionalPageLoader } from './EditProfessionalPage/EditProfessionalPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as InboxPageProfessionalLoader } from './InboxPageProfessional/InboxPageProfessional.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as ProfessionalPageLoader } from './ProfessionalPage/ProfessionalPage.duck';
import { loadData as SearchProfessionalsPageLoader } from './SearchProfessionalsPage/SearchProfessionalsPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as InvitationsPageLoader } from './InvitationsPage/InvitationsPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as CommunityPageLoader } from './CommunityPage/CommunityPage.duck';
import { loadData as PostPageLoader } from './PostPage/PostPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as CreateListingPageLoader } from './CreateListingPage/CreateListingPage.duck';
import { loadData as CalendarPageLoader } from './CalendarPage/CalendarPage.duck';
import { loadData as ManagementPageLoader } from './ManagementPage/ManagementPage.duck';
import { loadData as EstimatePageLoader } from './EstimatePage/EstimatePage.duck';
import { loadData as DailyLogsPageLoader } from './DailyLogsPage/DailyLogsPage.duck';
import { loadData as DatesPageLoader } from './DatesPage/DatesPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EditProfessionalPage: {
      loadData: EditProfessionalPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    InboxPageProfessional: {
      loadData: InboxPageProfessionalLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    ProfessionalPage: {
      loadData: ProfessionalPageLoader,
    },
    SearchProfessionalsPage: {
      loadData: SearchProfessionalsPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    InvitationsPage: {
      loadData: InvitationsPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    CommunityPage: {
      loadData: CommunityPageLoader,
    },
    PostPage: {
      loadData: PostPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    CreateListingPage: {
      loadData: CreateListingPageLoader,
    },
    CalendarPage: {
      loadData: CalendarPageLoader,
    },
    ManagementPage: {
      loadData: ManagementPageLoader,
    },
    EstimatePage: {
      loadData: EstimatePageLoader,
    },
    DailyLogsPage: {
      loadData: DailyLogsPageLoader,
    },
    DatesPage: {
      loadData: DatesPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
