import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './Invitation.module.css';

const DateIcon = props => {
  const { className } = props;
  const classes = classNames(className, css.dateIcon);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 16 16"
    >
      <g transform="scale(1.14286)">
        <path
          d="M1.5 2.5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1h-2M3.5.5v4M10.5.5v4M3.5 2.5h5"
          style={{
            fill: 'none',
            stroke: '#B2B2B2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={3.5}
          cy={7.5}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#B2B2B2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={7}
          cy={7.5}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#B2B2B2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={10.5}
          cy={7.5}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#B2B2B2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={3.5}
          cy={10.5}
          r={0.5}
          style={{
            fill: 'none',
            stroke: '#B2B2B2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
    </svg>
  );
};

DateIcon.defaultProps = {
  className: null,
};

DateIcon.propTypes = {
  className: string,
};

export default DateIcon;
