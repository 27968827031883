import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, ArrowButton, IconReviewStar, IconArrowRight } from '../../components';
import classNames from 'classnames';

import avatarImage from './avatar.jpg';
import avatarImage2 from './avatar-2.jpg';
import css from './SectionReviews.module.css';

const Card = ({ displayName, text, avatarSrc }) => {
  return (
    <div className={css.card}>
      <div className={css.reviewRating}>
        <IconReviewStar className={css.reviewStar} isFilled />
        <span className={css.reviewRatingCount}>5.0</span>
      </div>
      <div className={css.reviewUser}>
        <img className={css.avatarImage} src={avatarSrc} alt="Username" />
        <h3 className={css.avatarDisplayName}>{displayName}</h3>
      </div>
      <p className={css.reviewText}>{text}</p>
      <div className={css.cardInfo}>
        <NamedLink name="SignupPage" className={css.cardInfoText}>
          <FormattedMessage id="SectionReviews.cardInfo" />
          <IconArrowRight className={css.rightArrow} />
        </NamedLink>
      </div>
    </div>
  );
};

const SectionReviews = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.leftContent}>
        <div className={css.cards}>
          <Card
            displayName="Gary G."
            text="“Matt provided amazing work. Communication, punctuality and work was out-standing. Thanks!”"
            avatarSrc={avatarImage}
          />
          <Card
            displayName="Sanjay A."
            text="“I was skeptical using Equipped at first but it matched me with perfectly matching painter.”"
            avatarSrc={avatarImage2}
          />
        </div>
        <ArrowButton className={css.reviewsButtonMobile}>
          <FormattedMessage id="SectionReviews.buttonText" />
        </ArrowButton>
      </div>
      <div className={css.rightContent}>
        <div className={css.subTitle}>
          <FormattedMessage id="SectionReviews.subTitle" />
        </div>
        <div className={css.title}>
          <FormattedMessage id="SectionReviews.title" />
        </div>
        <p className={css.text}>
          <FormattedMessage id="SectionReviews.text" />
        </p>
        <ArrowButton className={css.reviewsButtonDesktop}>
          <FormattedMessage id="SectionReviews.buttonText" />
        </ArrowButton>
      </div>
    </div>
  );
};

SectionReviews.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionReviews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionReviews;
