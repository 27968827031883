import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconTime.module.css';

const IconTime = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.857 4.286 7.714 9.429l5.143 5.142" strokeWidth={1.71429} />
        <path
          d="M23.143 19.714v-3.428a6.857 6.857 0 0 0-6.857-6.857H7.714M6 4.286.857 9.429 6 14.57"
          strokeWidth={1.71429}
        />
      </g>
    </svg>
  );
};

IconTime.defaultProps = {
  rootClassName: null,
  className: null,
};

IconTime.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconTime;
