import React from 'react';
import propTypes from 'prop-types';
import Toggle from 'react-toggle';
import classNames from 'classnames';

import './react-toggle.css';
import css from './Toggle.module.css';

const COMPONENT_NAME = 'Toggle';

const ToggleField = props => {
  const {
    rootClassName,
    className,
    classNamePrefix,
    labelClassName,
    altLabelClassName,
    label,
    altLabel,
    defaultChecked,
    isChecked,
    onToggleChange,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const labelClasses = classNames(labelClassName, css.label);
  const altLabelClasses = classNames(altLabelClassName, css.altLabel);

  // Toggle component props
  const toggleProps = defaultChecked
    ? {
        className: classNamePrefix,
        defaultChecked,
        icons: false,
        onChange: onToggleChange,
      }
    : {
        className: classNamePrefix,
        checked: isChecked,
        icons: false,
        onChange: onToggleChange,
      };

  if (!onToggleChange) {
    throw new Error(`${COMPONENT_NAME} component expects onToggleChange function.`);
  }
  return (
    <label className={classes}>
      {label ? <span className={labelClasses}>{label}</span> : null}
      <Toggle {...toggleProps} />
      {altLabel ? <span className={altLabelClasses}>{altLabel}</span> : null}
    </label>
  );
};

ToggleField.defaultProps = {
  rootClassName: null,
  className: null,
  labelClassName: null,
  label: null,
};

const { string, bool, func } = propTypes;

ToggleField.propTypes = {
  rootClassName: string,
  className: string,
  labelClassName: string,
  label: string,
  defaultChecked: bool,
  onToggleChange: func.isRequired,
};

export default ToggleField;
