import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { IconClose } from '../../components';
import classNames from 'classnames';

import css from './TopbarNotice.module.css';

const TopbarNotice = props => {
  const { rootClassName, className, topbarMessage } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Declare when the TopbarNotice
  // should be visibile with useState
  const [visible, setInvisible] = useState(false);

  // Run the function when the component
  // gets mounted
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.topbarNoticeAccepted4 !== '1') {
      setInvisible(true);
    }
  }, []);

  // Save cookie consent and don't show
  // the TopbarNotice for one hour
  const saveCookieConsent = () => {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    // Save the cookie with expiration date
    document.cookie = 'topbarNoticeAccepted4=1; path=/; expires=' + expirationDate.toGMTString();
  };

  // Close the TopbarNotice and run the
  // saveCookieConsent function
  const onCloseNotice = () => {
    saveCookieConsent();
    setInvisible(false);
  };

  return visible ? (
    <div className={classes}>
      <p className={css.notice}>{topbarMessage}</p>
      <div
        className={css.closeContainer}
        onClick={() => {
          onCloseNotice();
        }}
      >
        <IconClose className={css.close} />
      </div>
    </div>
  ) : null;
};

TopbarNotice.defaultProps = {
  rootClassName: null,
  className: null,
  topbarMessage: null,
};

TopbarNotice.propTypes = {
  rootClassName: string,
  className: string,
  topbarMessage: string,
};

export default TopbarNotice;
