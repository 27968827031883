import React from 'react';
import { string } from 'prop-types';

import css from './SectionClientFeatures.module.css';

const GetMatchedIcon = props => {
  const { className } = props;
  return (
    <div className={css.iconWrapper}>
      <svg
        className={className}
        width="35"
        height="28"
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6349 14.3045C15.1525 14.3045 18.0042 11.4528 18.0042 7.93519C18.0042 4.41756 15.1525 1.56592 11.6349 1.56592C8.11726 1.56592 5.26562 4.41756 5.26562 7.93519C5.26562 11.4528 8.11726 14.3045 11.6349 14.3045Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1641 13.5916C15.7321 14.957 13.7827 15.7971 11.635 15.7971C9.48726 15.7971 7.53784 14.957 6.10581 13.5916C5.8592 13.5304 5.61259 13.47 5.37309 13.4394C5.13359 13.4089 4.9019 13.4089 4.68585 13.4423C4.47051 13.475 4.27152 13.5418 4.03913 13.625C3.80673 13.7074 3.54165 13.8069 3.20975 14.0059C2.87857 14.205 2.47988 14.5035 2.09895 15.0017C1.71731 15.4986 1.35273 16.1958 1.02084 17.0253C0.688946 17.8541 0.390458 18.8165 0.224867 19.7455C0.0592772 20.6737 0.0258748 21.57 0.00952903 22.2495C-0.00752747 22.9297 -0.00752747 23.3946 0.0592772 23.8424C0.125371 24.2902 0.257559 24.7209 0.556758 25.1523C0.855247 25.5838 1.31933 26.0152 1.86656 26.3465C2.41378 26.6784 3.04416 26.9108 3.90694 27.0267C4.769 27.1425 5.86346 27.1425 7.19032 27.1425H11.038H11.635H12.2319H16.0796C17.4064 27.1425 18.5009 27.1425 19.363 27.0267C20.2257 26.9108 20.8561 26.6784 21.4034 26.3465C21.9506 26.0152 22.4147 25.5838 22.7132 25.1523C23.0124 24.7209 23.1445 24.2902 23.2106 23.8424C23.2774 23.3946 23.2774 22.9297 23.2604 22.2495C23.244 21.57 23.2106 20.6737 23.045 19.7455C22.8795 18.8165 22.581 17.8541 22.2491 17.0253C21.9172 16.1958 21.5526 15.4986 21.171 15.0017C20.79 14.5035 20.3913 14.205 20.0602 14.0059C19.7283 13.8069 19.4632 13.7074 19.2308 13.625C18.9984 13.5418 18.7994 13.475 18.5841 13.4423C18.368 13.4089 18.1363 13.4089 17.8968 13.4394C17.6573 13.47 17.4107 13.5304 17.1641 13.5916Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4239 11.0739C19.5558 12.1074 21.063 12.7385 22.7166 12.7385C26.2333 12.7385 29.0843 9.88691 29.0843 6.36927C29.0843 2.85164 26.2333 0 22.7166 0C20.4583 0 18.4736 1.17634 17.3438 2.94973C18.6186 4.11398 19.4187 5.79141 19.4187 7.65436C19.4187 8.91243 19.0535 10.0866 18.4239 11.0739Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.8933 12.2609C26.5034 13.585 24.6271 14.4159 22.5547 14.4643L22.7836 14.7386C23.1654 15.2355 23.5301 15.9327 23.8621 16.7622C24.1942 17.591 24.4928 18.5534 24.6584 19.4824C24.8241 20.4106 24.8575 21.3069 24.8738 21.9864C24.8909 22.6666 24.8909 23.1315 24.8241 23.5793C24.758 24.0271 24.6257 24.4578 24.3264 24.8892C24.1039 25.2105 23.7896 25.5325 23.4178 25.8118H26.8084C28.1357 25.8118 29.2306 25.8118 30.093 25.696C30.9561 25.5801 31.5867 25.3477 32.1342 25.0158C32.6816 24.6845 33.1459 24.2531 33.4445 23.8217C33.7438 23.3902 33.876 22.9595 33.9421 22.5117C34.009 22.0639 34.009 21.5991 33.9919 20.9188C33.9755 20.2393 33.9421 19.343 33.7765 18.4148C33.6108 17.4858 33.3122 16.5234 32.9802 15.6946C32.6482 14.8652 32.2835 14.1679 31.9017 13.671C31.5206 13.1728 31.1218 12.8743 30.7904 12.6752C30.4584 12.4762 30.1932 12.3767 29.9608 12.2943C29.7283 12.2111 29.5292 12.1443 29.3138 12.1116C29.0976 12.0782 28.8659 12.0782 28.6263 12.1088C28.3867 12.1393 28.14 12.1997 27.8933 12.2609Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

GetMatchedIcon.defaultProps = { className: null };

GetMatchedIcon.propTypes = {
  className: string,
};

export default GetMatchedIcon;
