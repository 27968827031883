import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconError } from '../../components';

import css from './DiscountForm.module.css';

const DiscountFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        values,
        invalidDiscount,
      } = fieldRenderProps;

      const codePlaceholder = intl.formatMessage({
        id: 'DiscountForm.codePlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = !values.code;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.discountForm}>
            <div className={css.fieldWrapper}>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  inputRootClass={css.input}
                  type="text"
                  name="code"
                  id={formId ? `${formId}.code` : 'code'}
                  placeholder={codePlaceholder}
                />
                {invalidDiscount ? <IconError className={css.errorIcon} /> : null}
              </div>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="DiscountForm.submitButtonText" />
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

DiscountFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

DiscountFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DiscountForm = compose(injectIntl)(DiscountFormComponent);

DiscountForm.displayName = 'DiscountForm';

export default DiscountForm;
