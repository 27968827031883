import React from 'react';
import { array } from 'prop-types';
import { NamedLink, IconWarning } from '../../components';
import classNames from 'classnames';

import css from './TransactionPanel.module.css';

const PageTabs = props => {
  const { tabs } = props;
  return (
    <ul className={css.tabs}>
      {tabs.map(tab => {
        const tabLinkClasses = classNames(css.tabLink, {
          [css.tabLinkSelected]: tab.selected,
        });
        return (
          <li key={tab.key} className={css.tab}>
            <NamedLink className={tabLinkClasses} {...tab.linkProps}>
              {tab.icon}
              {tab.text}
              {tab.notification ? (
                <span className={css.tabLinkNotification}>
                  <IconWarning className={css.tabLinkNotificationIcon} />
                </span>
              ) : null}
            </NamedLink>
          </li>
        );
      })}
    </ul>
  );
};

PageTabs.defaultProps = {
  tabs: [],
};

PageTabs.defaultProps = {
  tabs: array.isRequired,
};

export default PageTabs;
