import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const IconShare = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <rect
          x="0.5"
          y="0.5"
          width="13"
          height="13"
          rx="3"
          style={{
            fill: 'none',
            stroke: '#7c7c7c',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '0.8',
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#7c7c7c',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '0.8',
          }}
          d="m6 10.5-2-2h6M8 3.5l2 2H4"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconShare.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShare.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShare;
